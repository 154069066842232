// api.js

const state = {
    // State específico del módulo API
  };
  
  const mutations = {
    // Mutations específicas del módulo API
  };
  
  const actions = {
    // Actions específicas del módulo API
    getSuministros({commit,state},val) {
      return new Promise((resolve, reject) => {
      fetch(this.state.app.config.apiURL+'suministros',
          {
              method: "GET",
                headers: {
                'Authorization': 'Basic ' + btoa(this.state.operador.operador + ':' + this.state.operador.clave), // Aquí incluye las credenciales codificadas en base64
                'Content-Type': 'application/json'
              },
              // body: JSON.stringify({
              //   operador      : val.operador,
              //   clave         : val.clave
              // })
          })
          .then((response) => response.json())
          .then((r) => {
              resolve(r);
          })
          .catch((error) => {
              reject(error);
          });
      },
      error=>{
          reject(error);
      })
    },
   
   
    createSuministro({commit,state},val) {
      return new Promise((resolve, reject) => {
      fetch(this.state.app.config.apiURL+'suministros',
          {
              method: "POST",
                headers: {
                'Authorization': 'Basic ' + btoa(this.state.operador.operador + ':' + this.state.operador.clave), // Aquí incluye las credenciales codificadas en base64
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                suministro     : val,
              })
          })
          .then((response) => response.json())
          .then((r) => {
              resolve(r);
          })
          .catch((error) => {
              reject(error);
          });
      },
      error=>{
          reject(error);
      })
    },
    updateSuministro({commit,state},val) {
      return new Promise((resolve, reject) => {
      fetch(this.state.app.config.apiURL+'suministros/'+ val.suministro_id,
          {
              method: "PUT",
                headers: {
                'Authorization': 'Basic ' + btoa(this.state.operador.operador + ':' + this.state.operador.clave), // Aquí incluye las credenciales codificadas en base64
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                suministro     : val,
              })
          })
          .then((response) => response.json())
          .then((r) => {
              resolve(r);
          })
          .catch((error) => {
              reject(error);
          });
      },
      error=>{
          reject(error);
      })
    },
    getSuministrosSolicitud({commit,state},val) {
      return new Promise((resolve, reject) => {
      fetch(this.state.app.config.apiURL+'solicitudes_suministros/'+val,
          {
              method: "GET",
                headers: {
                'Authorization': 'Basic ' + btoa(this.state.operador.operador + ':' + this.state.operador.clave), // Aquí incluye las credenciales codificadas en base64
                'Content-Type': 'application/json'
              },
              // body: JSON.stringify({
              //   operador      : val.operador,
              //   clave         : val.clave
              // })
          })
          .then((response) => response.json())
          .then((r) => {
              resolve(r);
          })
          .catch((error) => {
              reject(error);
          });
      },
      error=>{
          reject(error);
      })
    },
    
  };
  
  const getters = {
    // Getters específicas del módulo API
  };
  
  export default {
    state,
    mutations,
    actions,
    getters
  };