import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import productos from './modules/st_productos';
import suministros from './modules/st_suministros';
import pedidos from './modules/st_pedidos';
import remisiones from "./modules/st_remisiones";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    app:{
      config: {
        // env         : 'Pruebas',
        // apiURL      : 'http://127.0.0.1:8000/api/', //process.env.APIURL,
        env         : 'Produccion',
        apiURL      : 'https://api.alianzaconcretos.com/api/', //process.env.APIURL,
      },
      version:'02.12',
    },
    menu:{
      ruta:"/",
      tittle:"Alianza",
      quienllama:'',
    },
    operador:{isAuthenticated:false,entrada:false},
    modal:{tittle:'',
          message:'', 
          type:0},
    marcaciones:[],
    detenerTimer: false,
  },
  getters: {
    getOperador: state => {
      return state.operador
    },
    getMenu: state =>{
      return state.menu
    },
    getMarcaciones: state => {
      return state.marcaciones
    },
    getdetenerTimer: state => {
      return state.detenerTimer
    }
  },
  mutations: {
    updateOperador(state, payload){
      state.operador = payload
    },
    updatePermisos(state, payload){
      state.permisos = payload
    },
    updateMenu(state, payload){
      state.menu = payload
    },
    updateModal(state,payload){
      state.modal=payload
    },
    updateMarcaciones(state, payload){
      state.marcaciones=payload
    },
    updateDetenerTimer(state, payload){
      state.detenerTimer=payload
      console.log("se ha actualizado detenerTime a: " + state.detenerTimer)
    },
    closeSesion(state){
      //reiniciamos los valores del storage
      state.operador={isAuthenticated:false,entrada:false}
      state.menu={ruta:"/",tittle:"Alianza"}
      state.modal={tittle:'', message:'', type:0}
      
      state.app={
        config: {
          // env         : 'Pruebas',
          // apiURL      : 'http://127.0.0.1:8000/api/', //process.env.APIURL,
          env         : 'Produccion',
          apiURL      : 'https://api.alianzaconcretos.com/api/', //process.env.APIURL,
        },
        version:'02.12',
      }
     
    },
    
  },
  actions: {
    actupdateMenu({commit},obj){
      commit('updateMenu',obj)
    },
    actupdateOperador({commit}, obj){
      commit('updateOperador', obj)
    },
    actcloseSesion({commit}){
      localStorage.clear() // o localStorage.removeItem('token') para items específicos
      sessionStorage.clear()
      
      // // Resetea el estado de Vuex
      // commit('RESET_STATE')
      
      // Opcional: Recargar la página para limpiar cualquier caché en memoria
      window.location.reload()
      commit('closeSesion')
    },
    
    actupdateModal({commit},obj){
      commit('updateModal',obj)
    },
    actupdateMarcaciones({commit},obj){
      commit('updateMarcaciones',obj)
    },
    actupdateDetenerTimer({commit},obj){
      commit('updateDetenerTimer',obj)
    },
    // logout({ commit }) {
    //   // Limpia el localStorage/sessionStorage
    //   localStorage.clear() // o localStorage.removeItem('token') para items específicos
    //   sessionStorage.clear()
      
    //   // // Resetea el estado de Vuex
    //   // commit('RESET_STATE')
      
    //   // Opcional: Recargar la página para limpiar cualquier caché en memoria
    //   window.location.reload()
    // },


    isValidUser({commit,state},val) {
     
      return new Promise((resolve, reject) => {
      fetch(this.state.app.config.apiURL+'validaoperador?operador='+val.operador+'&clave='+val.clave,
          {
              method: "GET",
                headers: {
                'Authorization': 'Basic ' + btoa(':'), // Aquí incluye las credenciales codificadas en base64
                'Content-Type': 'application/json'
              },
              // body: JSON.stringify({
              //   operador      : val.operador,
              //   clave         : val.clave
              // })
          })
          .then((response) => response.json())
          .then((r) => {
              
              resolve(r);
          })
          .catch((error) => {
              
              reject(error);
          });
      },
      error=>{
          reject(error);
      })
      
    },
    getOperadores({commit,state},val) {
      return new Promise((resolve, reject) => {
      fetch(this.state.app.config.apiURL+'operadores',
          {
              method: "GET",
                headers: {
                'Authorization': 'Basic ' + btoa(this.state.operador.operador + ':' + this.state.operador.clave), // Aquí incluye las credenciales codificadas en base64
                'Content-Type': 'application/json'
              },
              // body: JSON.stringify({
              //   operador      : val.operador,
              //   clave         : val.clave
              // })
          })
          .then((response) => response.json())
          .then((r) => {
              
              resolve(r);
          })
          .catch((error) => {
              
              reject(error);
          });
      },
      error=>{
          reject(error);
      })
    },
    createOperador({commit,state},val) {
      return new Promise((resolve, reject) => {
      fetch(this.state.app.config.apiURL+'operadores',
          {
              method: "POST",
                headers: {
                'Authorization': 'Basic ' + btoa(this.state.operador.operador + ':' + this.state.operador.clave), // Aquí incluye las credenciales codificadas en base64
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                operador      : val,
              })
          })
          .then((response) => response.json())
          .then((r) => {
              resolve(r);
          })
          .catch((error) => {
              reject(error);
          });
      },
      error=>{
          reject(error);
      })
    },
    updateOperador({commit,state},val) {
      return new Promise((resolve, reject) => {
      fetch(this.state.app.config.apiURL+'operadores/'+ val.operador_id,
          {
              method: "PUT",
                headers: {
                'Authorization': 'Basic ' + btoa(this.state.operador.operador + ':' + this.state.operador.clave), // Aquí incluye las credenciales codificadas en base64
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                operador      : val,
              })
          })
          .then((response) => response.json())
          .then((r) => {
              resolve(r);
          })
          .catch((error) => {
              reject(error);
          });
      },
      error=>{
          reject(error);
      })
    },
    getCatalogos({commit,state},val) {
      return new Promise((resolve, reject) => {
      fetch(this.state.app.config.apiURL+'catalogos',
          {
              method: "GET",
                headers: {
                'Authorization': 'Basic ' + btoa(this.state.operador.operador + ':' + this.state.operador.clave), // Aquí incluye las credenciales codificadas en base64
                'Content-Type': 'application/json'
              },
              // body: JSON.stringify({
              //   operador      : val.operador,
              //   clave         : val.clave
              // })
          })
          .then((response) => response.json())
          .then((r) => {
              
              resolve(r);
          })
          .catch((error) => {
              
              reject(error);
          });
      },
      error=>{
          reject(error);
      })
    },
    getSolicitudes({commit,state},val) {
      return new Promise((resolve, reject) => {
      fetch(this.state.app.config.apiURL+'vista_solicitudes',
          {
              method: "POST",
                headers: {
                'Authorization': 'Basic ' + btoa(this.state.operador.operador + ':' + this.state.operador.clave), // Aquí incluye las credenciales codificadas en base64
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                fecha_ini      : val.fini,
                fecha_fin      : val.ffin,
                usarentodo    : val.usarentodo,
              })
          })
          .then((response) => response.json())
          .then((r) => {
              resolve(r);
          })
          .catch((error) => {
              reject(error);
          });
      },
      error=>{
          reject(error);
      })
    },
    createSolicitud({commit,state},val) {
      return new Promise((resolve, reject) => {
      fetch(this.state.app.config.apiURL+'solicitudes',
          {
              method: "POST",
                headers: {
                'Authorization': 'Basic ' + btoa(this.state.operador.operador + ':' + this.state.operador.clave), // Aquí incluye las credenciales codificadas en base64
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                solicitud      : val,
              })
          })
          .then((response) => response.json())
          .then((r) => {
              resolve(r);
          })
          .catch((error) => {
              reject(error);
          });
      },
      error=>{
          reject(error);
      })
    },
    updateSolicitud({commit,state},val) {
      return new Promise((resolve, reject) => {
      fetch(this.state.app.config.apiURL+'solicitudes/'+val.solicitud_id,
          {
              method: "PUT",
                headers: {
                'Authorization': 'Basic ' + btoa(this.state.operador.operador + ':' + this.state.operador.clave), // Aquí incluye las credenciales codificadas en base64
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                solicitud      : val,
              })
          })
          .then((response) => response.json())
          .then((r) => {
              resolve(r);
          })
          .catch((error) => {
              reject(error);
          });
      },
      error=>{
          reject(error);
      })
    },
  },
  modules: {
    productos: productos,
    suministros: suministros,
    pedidos: pedidos,
    remisiones: remisiones,
  },
  plugins:[
    new VuexPersistence({
      storage: window.localStorage
    }).plugin
  ]
})
