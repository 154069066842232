<template>
    <div  class="col-12 login d-flex align-items-center justify-content-center px-3 height-fixed font-opensans">
      <b-container class=" custom-rounded border shadow login-custom-container px-4 form-background ">
        <b-row class="justify-content-center">
          <b-col cols="8" >
            <b-img :src="require('@/assets/logo-alianza.png')" alt="logo-alianza.png" class="img-fluid mt-5 mb-4"></b-img>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h1 class="font-weight-bold text-center mb-3">Inicio de sesión</h1>
            <b-form @submit.prevent="login">
              <b-form-group class="font-weight-bold mt-4" label="Usuario/Email:" label-for="email">
                <template #label>
                  <b-icon icon="person-fill" class="mx-2"></b-icon>
                  Usuario/Email:
                </template>
                <b-form-input
                  id="email"
                  type="text"
                  placeholder="Ingresa tu Email o usuario"
                  v-model="email"
                  class="mt-2 custom-rounded-border"
                ></b-form-input>
              </b-form-group>
  
              <b-form-group class="font-weight-bold mt-4" label="Contraseña:" label-for="password" >
                <template #label>
                  <b-icon icon="shield-lock-fill" class="mx-2"></b-icon>
                  Contraseña:
                </template>
                <b-form-input
                  id="password"
                  type="password"
                  placeholder="Ingresa tu contraseña"
                  v-model="password"
                  class="mt-2"
                ></b-form-input>
              </b-form-group>

              <b-row class="justify-content-center">
                <b-col cols="12" sm="12" md="12" lg="12" xl="12" class="py-2 px-5 mt-4 mb-5" >
                  <b-button v-if="!cargando" type="submit" class="login-bttn bold-text w-100 w-sm-auto p-2 fs-5 mb-0">Iniciar sesión</b-button>
                  <div v-else class="w-100">
                      <b-spinner  class="text-center p-2 fs-5 mb-0" variant="warning" ></b-spinner>
                  </div>
                </b-col>
              </b-row>

              <b-alert v-if="error!=''" show variant="danger">{{ error }}</b-alert>
            
            </b-form>
            <div class="text-end">
              <label for="version"><strong class="label-col-green fs-10"> Versión {{ app.version }}</strong></label>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <Modals ref="mod"></Modals>
    </div>
  </template>
  
  <script>
  import Modals from '@/components/Modals.vue'
  import {mapState,mapActions} from 'vuex'
  export default {
    
    name: 'LoginPage',
    data() {
      return {
        cargando:false,
        email: '',
        password: '',
        error:'',
      
      };
    },
    components: {
        Modals,
        // Operadores,
    },
    computed: {
      ...mapState(['operador','modal','menu','app']),
      // name() {
      //   return this.data 
      // }
    },
    mounted () {
        //console.log("--------------EL mounted del LOGIN----------------")
       //console.info("operador: " + JSON.stringify(this.operador)) 
    },
    methods: {
      ...mapActions(['actupdateMenu','actupdateModal']),
      showmodal(tittle,message){
            this.modal.tittle = tittle
            this.modal.message = message
            this.modal.type = 0
            this.actupdateModal(this.modal)
            // console.log("ahora contiene:  " + JSON.stringify(this.modal))
            this.$refs.mod.showmodal()
      },
      login() {
        // this.$emit('login');
        // this.$store.state.operador.isAuthenticated=true
        // return ""
        // Aquí podrías agregar la lógica de autenticación real
        // if (this.email && this.password) {
        this.cargando=true
        const val={operador:this.email,clave:this.password}
         //console.log("el val eniado: " + JSON.stringify(val))
          this.$store.dispatch("isValidUser",val).then((resp)=>{
           //console.log("respuesta",resp)
            if(resp.estatus==0){
              this.error=resp.message 
              this.showAlert()
              this.cargando=false
            }else{
              const op=resp.operador
              op.isAuthenticated=true
              this.$store.dispatch("actupdateOperador",op)
              if (resp.operador.perfil=="Dosificador"){
                this.$store.dispatch('actupdateMenu',{ruta:'vpedidos', tittle:'vPedidos'})
              }else{
                this.$store.dispatch('actupdateMenu',{ruta:'vsolicitudes', tittle:'vSolicitudes'})
              }
              
              this.$emit('login');
           }
          }).catch((err)=>{
              this.showmodal(this.texto, err)
              this.cargando=false
          })
          
        // }
      },
       countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown
      },
      showAlert() {
          this.timer = setTimeout(() => {
          this.error=""
        }, 5000);
      }
    }
  };
  </script>
  
  <style scoped>
  
  </style>
  