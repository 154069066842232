import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import Login from '../components/Login.vue';
import Operadores from '../catalogos/Operadores.vue';
import Productos from '../catalogos/Productos.vue';
import Solicitudes from '../catalogos/Solicitudes.vue';
import Suministros from '../catalogos/Suministros.vue';
import Tipos_productos from '../catalogos/Tipos_productos.vue';

import vProductos from '../views/vProductos.vue';
import vSolicitudes from '../views/vSolicitudes.vue';
import vSuministros from '../views/vSuministros.vue';
import vTipos_productos from '../views/vTipos_productos.vue';
import vPedidos from '../views/vPedidos.vue';
import vRemisiones from '@/views/vRemisiones.vue';
import store from '@/store/index'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'HomeView',
  //   components: {
  //     default: HomeView,
  //     // sidebar: Sidebar,
  //   }
  // },
  
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  {
    path: '/vsolicitudes',
    name: 'vsolicitudes',
    component: vSolicitudes,
  },
  {
    path: '/vpedidos',
    name: 'vpedidos',
    component: vPedidos,
  },
  {
    path: '/vremisiones',
    name: 'vremisiones',
    component: vRemisiones,
  },
  {
    path: '/vSuministros',
    name: 'vsuministros',
    component: vSuministros,
  },
  {
    path: '/vOperadores',
    name: 'voperadores',
    component: () => import('../views/vOperadores.vue')
  },
  {
    path: '/vProductos',
    name: 'vproductos',
    component: vProductos
  },
  {
    path: '/vTipos_productos',
    name: 'vtipos_productos',
    component: vTipos_productos
  },
  {
    path: '/Operadores',
    name: 'operadores',
    component: Operadores,
  },
  {
    path: '/Productos',
    name: 'productos',
    component: Productos,
  },
  {
    path: '/Solicitudes',
    name: 'solicitudes',
    component: Solicitudes,
  },
  {
    path: '/Suministros',
    name: 'suministros',
    component: Suministros,
  },
  {
    path: '/Tipos_productos',
    name: 'tipos_productos',
    component: Tipos_productos,
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  // Actualizar el store cuando la ruta cambia
  store.commit('updateDetenerTimer', true) // o cualquier dato de la ruta que quieras guardar
  next()
})
export default router
