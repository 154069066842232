<template>
  <div class="font-opensans">
      <!-- <b-button v-b-toggle.sidebar-no-header class="d-flex flex-row">Toggle Sidebar</b-button> -->
      <b-row class="pt-2" style="background-color: #EAF2F8;">
        <b-col sm="2">
          <b-icon icon="list" font-scale="2.5" v-b-toggle.sidebar-no-header class="d-flex flex-row font-weight-bold custom-icon-color" style="margin-left:10px;"></b-icon>
        </b-col>
        <b-col sm="8" class="text-center">
          <b-img :src="require('@/assets/nombre_alianza.png')" alt="logo-alianza.png" class="bar-center-image"></b-img>
        </b-col>
        <b-col sm="2" class="text-end">
          <b-img :src="require('@/assets/logo_only.png')" alt="logo_only.png" class="bar-right-image" style="margin-right: 10px;"></b-img>
        </b-col>
      </b-row>
      <b-sidebar  id="sidebar-no-header" aria-labelledby="sidebar-no-header-title"   backdrop-variant="dark" backdrop shadow no-header title="Alianza Concretos">
        <template #default="{ hide }">
          <div class="p-3 text-start">
            <div class="row" >
              <b-col  cols="8" class="text-center">
                <b-img :src="require('@/assets/logo-alianza.png')" alt="logo-alianza.png" class=" mb-2 w-100"></b-img>
              </b-col>
              <!-- <b-col  cols="6">
                <h4 id="sidebar-no-header-title" class="label-col-blue pt-2 ">Alianza Concretos</h4>
              </b-col> -->
              <b-col class="text-end">
                 <b-button class="close-bttn mt-2 consultar-bttn" variant="outline-warning" size="sm" block @click="hide"><strong>X</strong></b-button>
              </b-col>
            </div>
            <div class="row ms-2" >
              <label for="" ><strong class="label-col-green fs-10"> {{operador.nombre}}</strong></label>
            </div>
            <hr class="custom-line">
            <nav  style="font-size:16px;">
              <b-nav vertical class="custom-color">
                <b-nav-item class="custom-color nav-item-special" :disabled="desactivar_sol"  @click="{enviarRuta('vsolicitudes','Solicitudes'),hide()}" ><b-icon icon="card-checklist" ></b-icon>  Solicitudes</b-nav-item>
                <b-nav-item class="custom-color nav-item-special" :disabled="desactivar_ped"  @click="{enviarRuta('vpedidos','Pedidos'),hide()}" ><b-icon icon="file-earmark-ppt" ></b-icon>  Pedidos</b-nav-item>
                <b-nav-item class="custom-color nav-item-special" :disabled="desactivar_rem"  @click="{enviarRuta('vremisiones','Remisiones'),hide()}" ><b-icon icon="bootstrap-reboot" ></b-icon>  Remisiones</b-nav-item>
                <b-nav-item class="custom-color nav-item-special" v-b-toggle.collapse-catalogos active ><b-icon icon="ui-checks"></b-icon>  Catálogos</b-nav-item>
                <b-collapse id="collapse-catalogos" class="mt-1 ms-3 mb-1">
                  <!-- <b-card> -->
                    <b-nav-item  class="custom-color nav-item-special" :disabled="desactivar_cat" active @click="{enviarRuta('voperadores','vOperadores'),hide()}"><b-icon icon="people" ></b-icon> Operadores</b-nav-item>
                    <b-nav-item  class="custom-color nav-item-special" :disabled="desactivar_cat" active @click="{enviarRuta('vproductos','vProductos'),hide()}"><b-icon icon="bag-check"></b-icon> Productos</b-nav-item>
                    <b-nav-item  class="custom-color nav-item-special" :disabled="desactivar_cat" active @click="{enviarRuta('vtipos_productos','vTipos_productos'),hide()}"><b-icon icon="tags" ></b-icon> Tipos de producto</b-nav-item>
                    <b-nav-item  class="custom-color nav-item-special" :disabled="desactivar_cat" active @click="{enviarRuta('vsuministros','vSuministros'),hide()}"><b-icon icon="box-seam" ></b-icon> Suministros</b-nav-item>
                    <!-- <b-nav-item class="bgcol" v-b-toggle.collapse-marcacion-rp active ><b-icon icon="phone-fill"></b-icon> Marcación</b-nav-item>
                        <b-collapse id="collapse-marcacion-rp" class="mt-2 ms-3 mb-2">
                          <b-nav-item :disabled="!tienepermiso(801)"  class="bgcol" active @click="{enviarRuta('marcador','Marcador'),hide()}">Marcador</b-nav-item>
                          <b-nav-item :disabled="!tienepermiso(1215)" class="bgcol" active @click="{enviarRuta('listadepuracion','Listas de Depuracion'),hide()}">  Listas de Depuración</b-nav-item>
                        </b-collapse>
                    <b-nav-item :disabled="!tienepermiso(1111)"  class="bgcol" active @click="{enviarRuta('guias','Guias'),hide()}">Guias</b-nav-item>
                    <b-nav-item :disabled="!tienepermiso(1207)"  class="bgcol" active @click="{enviarRuta('tickets','Tickets'),hide()}">Tickets</b-nav-item>
                    <hr> -->
                  <!-- </b-card> -->
                </b-collapse>
                <!--
                <b-row class="d-flex align-items-end"> 
                  <b-col class="d-flex align-items-end">
                    <b-img :src="require('@/assets/logo-alianza.png')" alt="logo-alianza.png" class="mt-5 mb-4 w-100"></b-img>
                  </b-col>
                  
                </b-row>-->
                
                <div class="custom-sidebar-footer sidebar-footer pb-3" >
                  <b-nav-item class="mt-3 custom-color nav-item-special bgcol sidebar-footer-text" active @click="logout()"><b-icon icon="door-open"></b-icon> Cerrar Sesión</b-nav-item>
                  <div class="text-start">
                    <label for="version"><strong class="label-col-green fs-10"> Versión {{ app.version }}</strong></label>
                  </div>
                </div>

              </b-nav>
            </nav>
            <!-- <nav > -->
              <b-nav vertical>
              </b-nav>
            <!-- </nav> -->
          </div>
        </template>
      </b-sidebar>
    </div>
</template>

<script>
import * as utils from "@/shared/util";
import {mapActions,mapState} from 'vuex'
export default {
  data() {
    return {
      hide: true,
      menuitems:{}
    }
  },
  computed: {
    ...mapState(['operador','app']),
    menu(){
        return this.$store.getters.getMenu;
    },
    desactivar_cat(){
      if(this.operador.perfil=='Vendedor' || this.operador.perfil=='Operador' || this.operador.perfil=='Dosificador'){
        return true
      }
      return false
    },
    desactivar_sol(){
      if(this.operador.perfil=='Operador' || this.operador.perfil=='Dosificador'){
        return true
      }
      return false
    },
    desactivar_ped(){
      if(this.operador.perfil=='Operador' || this.operador.perfil=='Vendedor'){
        return true
      }
      return false
    },
    desactivar_rem(){
      if(this.operador.perfil=='Operador' || this.operador.perfil=='Dosificador'){
        return true
      }
      return false
    }
  },
  
  methods: {
    ...mapActions(['actupdateOperador','actcloseSesion','actnotifications']),
    
    enviarRuta(rout, titulo) {
      
      let obj ={ruta:rout, tittle:titulo}
      console.info("enviando ruta: " + JSON.stringify(obj))
      this.$store.dispatch('actupdateMenu',obj)
      console.warn("la ruta: " + this.$route.name)
      if (this.$route.name !== rout) {
          this.$router.push({ name: rout });
      }
    },
    logout(){
      //marcamos la salida y si se logra hacer.. pues el cierre de la sesion.
      if (confirm("¿Salir y cerrar sesion?") == true) {
        
        this.actcloseSesion()
        
      }
    },
    tienepermiso(permiso){
      let lotiene= utils.permisoscontroles(permiso)
      return lotiene
    }
  }
  
}
</script>
<style src="@/assets/css/app.css">



</style>