import store from '@/store/index'
import * as XLSX from 'xlsx';

export function permisoscontroles(id){
    let permisos=store.state.permisos
    if(permisos.length > 0){
        let p= permisos.filter(item => item.ID_CONTROL == id)
        if (p.length > 0){
            return true
        }
    }
    return false
}

export function numberWithCommas(value) {
    let val = (value/1).toFixed(2).replace(',', '.')
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}
  
export function getDateTime(now,format=0){
    //console.log("ENTRO AL DATE")
    // let now = new Date(Date().toLocaleString());//Date().toLocaleString();
    // if (pDate != ''){now = new date(pDate);}
    // console.log("----------------------------------0-ANTES DE FORMATEAR EL NOW: " + now);
    now=new Date(now);
    //console.log("----------------------------------1-ANTES DE FORMATEAR EL NOW: " + now);
    // console.log("----------------------------------2- El format elegido: " + format);
    let day = now.getDate();
    if(day.toString().length==1){day='0' + day};
    let month = now.getMonth()+1;
    if(month.toString().length==1){month='0' + month};
    let year = now.getFullYear();

    let hour = now.getHours();
    if(hour.toString().length==1){hour='0' + hour};
    let minuts = now.getMinutes();
    if(minuts.toString().length==1){minuts='0' + minuts};
    let sec = now.getSeconds();
    if(sec.toString().length==1){sec='0' + sec};
    if(format==1) {
        
        return day+'-'+month+'-'+year+' '+hour+':'+minuts+':'+sec;
    }else if(format==2){

        return day+'-'+month+'-'+year
    } else if(format==3){
        return year+'-'+month+'-'+day+' '+hour+':'+minuts;
    }else if(format==4){
        // Convert to 12-hour format
        let period = 'AM';
        if (hour >= 12) {
            period = 'PM';
            if (hour > 12) {
                hour -= 12;
            }
        } else if (hour === 0) {
            hour = 12;
        }
        if (hour.toString().length == 1) { hour = '0' + hour; }
        return hour + ':' + minuts + ' ' + period;
    }else if(format==5){
        // Convert to 12-hour format
        let period = 'AM';
        if (hour >= 12) {
            period = 'PM';
            if (hour > 12) {
                hour -= 12;
            }
        } else if (hour === 0) {
            hour = 12;
        }
        if (hour.toString().length == 1) { hour = '0' + hour; }
        return day+'-'+month+'-'+year+' '+hour + ':' + minuts + ' ' + period;
    }else {
        return year+'-'+month+'-'+day;
    }
}

// export function exportar(obj,name){
    
//     let separator = ','; // Separador de campos del CSV
//     let data = obj; // Datos de la tabla

//     // Crear una cadena CSV con los encabezados de columna
//     let csv = Object.keys(data[0]).join(separator) + '\n';

//     // Agregar filas de datos al CSV
//     data.forEach(item => {
//         let row = Object.values(item).map(value => `"${value}"`).join(separator); // Encerrar los valores entre comillas para manejar comas dentro de ellos
//         csv += row + '\n';
//     });

//     // Crear un objeto Blob con el contenido del CSV
//     let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

//     // Crear un enlace para descargar el archivo CSV
//     let link = document.createElement('a');
//     if (link.download !== undefined) { // Verificar si el navegador soporta la propiedad download
//         let url = URL.createObjectURL(blob);
//         link.setAttribute('href', url);
//         link.setAttribute('download', name+'.csv');
//         link.style.visibility = 'hidden';
//         document.body.appendChild(link);
//         link.click();
//         document.body.removeChild(link);
//     } else {
//         console.error('¡Error! El navegador no soporta la descarga de archivos.');
//     }
    
// }

export function exportar(obj,name) {
    let ws = XLSX.utils.json_to_sheet(obj);
    let wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Datos");
    name=name+".xlsx"
    XLSX.writeFile(wb, name);
  }