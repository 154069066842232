<template>
    <b-container class="px-3 justify-content-center  height-fixed margin2sidebar font-opensans">
      <b-row>
        <b-col cols="10" sm="11" md="11" lg="11" xl="11">
          <h2><b-icon icon="tags" ></b-icon> Alta de Tipo de Producto</h2>
        </b-col>
        <b-col cols="2" sm="1" md="1" lg="1" xl="1">
          <b-button variant="primary" class="shadow-sm back-bttn bold-text px-3 py-2 mb-3 mt-2" @click="goBack()"><b-icon icon="arrow-left"></b-icon></b-button>
        </b-col>
      </b-row>
  
      <b-form @submit.prevent="guardarTipoProducto" class="shadow pb-4 pt-3 px-4 rounded form-background">
        <!-- Descripción del tipo de producto -->
        <b-row>
          <b-col cols="12" md="6">
            <b-form-group label="Descripción:" label-for="descripcion" class="pt-2 pb-3">
              <b-form-input
                id="descripcion"
                v-model="tipoProducto.descripcion"
                maxlength="75"
                required
                placeholder="Ingrese la descripción del tipo de producto"
                class="shadow-sm"
              />
            </b-form-group>
          </b-col>
        </b-row>
  
        <!-- Botones de acción -->
        <b-row>
          <b-col>
            <b-button @click="goBack()" variant="secondary" class="shadow-sm back-bttn bold-text px-3 py-1 mb-3 mt-2">Regresar</b-button>
            <b-button type="submit" variant="primary" class="shadow-sm save-bttn bold-text px-4 py-1 mb-3 mt-2 mx-3">Guardar</b-button>
            
          </b-col>
        </b-row>
      </b-form>
      <Modals ref="mod"></Modals>
    </b-container>
  </template>
  
  <script>
  import Modals from '@/components/Modals.vue'
  import {mapState,mapActions} from 'vuex'
  import * as utils from "@/shared/util"
  export default {
    data() {
      return {
        texto:"Tipo de producto",
        cargando:false,
        tipoProducto: {
          tipo_producto_id: null,
          descripcion: ''
        },
        nuevo:true
      };
    },
    components: {
        Modals,
        // Operadores,
    },
    computed: {
      ...mapState(['operador','modal','menu']),
      // name() {
      //   return this.data 
      // }
    },
    // mounted() {
    //   this.setFechaActual();
    // }
    created() {
     
      console.log("el parametro enviado es: " , this.$route.params); // Accede al valor '123'
      if(this.$route.params.tipo_producto_id != undefined){
        this.tipoProducto=this.$route.params
        this.nuevo=false
      }
      
      // this.form.estatus=1
      console.log("Es nuevo :" + this.nuevo)
    },
    methods: {
      ...mapActions(['actupdateMenu','actupdateModal']),
      showmodal(tittle,message){
          this.modal.tittle = tittle
          this.modal.message = message
          this.modal.type = 0
          this.actupdateModal(this.modal)
          console.log("ahora contiene:  " + JSON.stringify(this.modal))
          this.$refs.mod.showmodal()
      },
      guardarTipoProducto() {
        // Lógica para guardar el tipo de producto
        console.log('Tipo de Producto guardado:', this.tipoProducto);
        this.cargando=true
       
        // return ""
        if(this.nuevo){
          console.warn('enviando :' + JSON.stringify(this.tipoProducto));
          this.$store.dispatch("createTipoProducto",this.tipoProducto).then((res)=>{
            console.log("dispatch createTipoProducto trajo: "+JSON.stringify(res))
            if(res.estatus==1){
              this.goBack()
            }else{
              this.showmodal(this.texto, res.message)
            }
          }).catch((error)=>{
            this.showmodal(this.texto, error)
            this.cargando=false
          })
        }else{
          console.warn('enviando :' + JSON.stringify(this.tipoProducto));
          this.$store.dispatch("updateTipoProducto",this.tipoProducto).then((res)=>{
            console.log("dispatch updateTipoProducto trajo: "+JSON.stringify(res))
            if(res.estatus==1){
              this.goBack()
            }else{
              this.showmodal(this.texto, res.message)
            }
          }).catch((error)=>{
            this.showmodal(this.texto, error)
            this.cargando=false
          })
        }
        
        this.cargando=false
      },
      goBack(){
        this.$router.go(-1)
      },
      // regresar() {
      //   // Lógica para regresar a la vista anterior
      //   this.$router.push('/');
      // }
    }
  };
  </script>
  