<template>
  <div id="app" class="font-opensans">
    <!-- <router-view v-if="isAuthenticated"/> -->
    
    <!-- <div v-if="isAuthenticated" class="row pt-2" >
      <div class="">
        <Sidebar/>
      </div>
      
      <div class="" style="margin-top: -25px;" >
      
        <router-view></router-view>
        
       
      </div>
    </div>
    <Login v-else @login="handleLogin"/> -->
    <div v-if="isAuthenticated" class="app-content">
      <div class="sidebar-container">
        <Sidebar/>
      </div>
      <div class="main-content">
        <router-view></router-view>
      </div>
    </div>
    <Login v-else @login="handleLogin"/>
    
  </div>
</template>
<script>
import {mapState,mapActions} from 'vuex'
import Login from './components/Login.vue'
import Sidebar from './components/Sidebar.vue'
// import vOperadores from './views/vOperadores.vue'
// import Operadores from './catalogos/Operadores.vue'
// import Solicitudes from './views/vSolicitudes.vue'
export default {
  name: 'App',
  components: {
    Login,
    Sidebar,
    // vOperadores,
    // Operadores,
    // Solicitudes
  },
  
  data() {
    return {
      // isAuthenticated: false
    };
  },
  computed: {
    ...mapState(['operador','modal','menu']),

    operador(){
        return this.$store.getters.getOperador;
      },
    menu(){
        return this.$store.getters.getMenu;
    },
    isAuthenticated(){
      if(this.operador.isAuthenticated){
        return true
      }
      return false
    }
  },
  mounted () {
    if(this.isAuthenticated){
      this.handleLogin()
    }
  },
  methods: {
    handleLogin() {
      
      //this.$store.dispatch('actupdateMenu',{ruta:'vsolicitudes', tittle:'vSolicitudes'})
     //console.warn("la ruta: " + this.$route.name)
     //console.warn("El operador " + JSON.stringify(this.operador))
      if(this.isAuthenticated){
        if (this.$route.name !== this.menu.ruta) {
         //console.log("pusheando la ruta")
            this.$router.push({ name: this.menu.ruta });
        }  
      }
      
    }
  }
};
</script>
<style >
@import '@/assets/css/fonts.css';

:root {
    --font-family-base: 'OpenSans', sans-serif;
}

body {
    font-family: var(--font-family-base);
}
#app {

  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
   */
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.app-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.sidebar-container {
  position: sticky;
  top: 0;
  width: 100%;
  background-color: #f8f9fa;
  border-bottom: 1px solid #e9ecef;
  z-index: 1000;
}

.main-content {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  margin-top: 5px; /* Ajusta este valor según la altura de tu Sidebar */
}


</style>
