<template>
    <div  class="card shadow p-3 fs-12 text-center"> 
        <b-row>
            
            <b-table hover small :items="observaciones" :fields="fields" selectable responsive striped
                    caption-top select-mode="single"  label-sort-asc="" label-sort-desc="" label-sort-clear="" style="font-size:12px;"
                    table-class="custom-table"  thead-class="table-header"
                    tbody-class="table-body">
                <template #table-caption >
                    <b-row>
                        <strong>Observaciones totales: {{observaciones.length}}</strong>
                    </b-row>

                </template>
                <template #cell(fecha_hora_creacion)="data">
                    <span v-html="formatearFecha(data.value,5)"></span>
                </template> 
                <!-- <template #cell(operador)="data" >
                    <span class="label label-default">{{ data.item.operador_asignado.nombre}}</span>
                </template> 
                <template #cell(del)="data" >
                    <b-button class="btn-md" size="sm" :disabled="data.item.estatus!='ASIGNADO' ? true : false" variant="outline-danger" @click="actualizarRemision(data.item,true)" >
                        <i class="fas fa-ban"></i>
                    </b-button>
                </template>  -->
            </b-table>
        </b-row>
    </div>
</template>

<script>
import * as utils from "@/shared/util"
export default {
    data() {
        return {
            fields:[
                {key: 'observacion_id',label:'observacion_id',class:"d-none"},
                {key: 'solicitud_id',label:'solicitud_id',class:"d-none"},
                {key: 'observacion',label:'Observacion', sortable: true,class:"negritas text-start"},
                {key: 'operador',label:'Operador', sortable: true},
                {key: 'fecha_hora_creacion',label:'Creado', sortable: true},
            ],
        }
    },
    props: {
        observaciones: {
            type: Array,
            default: () => []
        },
    },
    methods: {
        formatearFecha(fecha,formato=0) {
            //console.log("formateando fecha: " + fecha + "formato: " + formato)
            let fechaformateada=utils.getDateTime(fecha,formato)
           // console.log("formateando fecha: " + fechaformateada)
            return fechaformateada
        },
    },
}
</script>

<style>

</style>