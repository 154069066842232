<template>
    <div class="container my-text height-fixed margin2sidebar font-opensans">
      <!-- <h3 class="warning">{{texto}}</h3> -->
      <div class="card shadow">
          <div class="container fluid">
              <div class="row p-2  rounded vOperadores-custom"> 
                    <b-col sm="9" class="text-start">
                        <h5 class="fcwhite pt-1"><b-icon icon="tags" ></b-icon><strong> Vista de Tipos de Producto</strong></h5>
                    </b-col>
                    <b-col sm="3" class="text-end">
                        <b-button v-if="!cargando" :hidden="ocultarnuevo" class="btn-sm p-2 vOperadores_vProductos-bttn" variant="outline-warning" @click="nuevoTipo()"><b-icon icon="plus-circle" font-scale="1"></b-icon> Nuevo</b-button>
                        <b-spinner v-else class="text-center" variant="warning" ></b-spinner>
                    </b-col>
              </div>
              <!-- <b-row >
               
                  <b-col md="5">
                     
                  </b-col>   
                  <b-col md="5">
                     
                  </b-col> 
                  <b-col md="2">
                        <b-row class="p-2">
                            <b-button v-if="!cargando" class="btn-sm" variant="warning" @click="getoperadores()"><b-icon icon="archive" font-scale="1"></b-icon> Consultar</b-button>
                            <b-spinner v-else variant="warning" ></b-spinner>
                        </b-row>  
                        <b-row class="p-2">
                            <b-button v-if="operadores.length > 0" class="btn-sm" variant="warning" @click="imprimir()"><b-icon icon="layer-forward" font-scale="1"></b-icon> Exportar</b-button>
                        </b-row>
                  </b-col>
              </b-row>     -->
          </div>
      </div>   
      <div class="card shadow mt-4 p-2">
  
          <b-container fluid >
              <b-row>
                  <b-form-group
                      label=""
                      label-for="filter-input"
                      label-cols-sm="3"
                      label-align-sm="right"
                      label-size="sm"
                      class="mb-0"
                      >
                      <b-input-group size="sm">
                          <b-form-input
                          id="filter-input"
                          v-model="filter"
                          type="search"
                          placeholder="Escribe para filtrar"
                          ></b-form-input>
  
                          <b-input-group-append>
                          <b-button :disabled="!filter" @click="filter = ''" class="limpiar-bttn">Limpiar</b-button>
                          </b-input-group-append>
                      </b-input-group>
                  </b-form-group>
              </b-row>
              <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="my-table"
                  size="sm"
                  pills 
                  align="center"
                  class="mt-2"
              ></b-pagination>
              <b-table v-if="!cargando" hover small :items="tipo_producto" :fields="fields" selectable responsive striped
                      caption-top :filter="filter"  select-mode="single" :per-page="perPage"
                      :current-page="currentPage" label-sort-asc="" label-sort-desc="" label-sort-clear="" style="font-size:12px;"
                      table-class="custom-table"  thead-class="table-header"
                      tbody-class="table-body">
                <template #table-caption ><strong class="fcgreen">tipos de producto: {{ tipo_producto.length }}</strong></template>
                <!-- <template #cell(IMPORTE)="data">
                    <span v-html="'$'+formatearNumero(data.value)"></span>
                </template> 
                <template #cell(amount)="data">
                    <span v-html="'$'+formatearNumero(data.value)"></span>
                </template>  -->
                <template #cell(edit)="data">
                    <b-button size="sm" variant="outline-dark" @click="editarTipoProducto(data.item)">Editar</b-button>
                </template>
              </b-table>
              <b-spinner v-else class="text-center" variant="warning" ></b-spinner>
          </b-container>
      </div>
      <Modals ref="mod"></Modals>
    </div>
</template>
  
<script>
import Modals from '@/components/Modals.vue'
// import Operadores from '@/catalogos/Operadores.vue'
import {mapState,mapActions} from 'vuex'
import * as utils from "@/shared/util"

export default {
    data() {
        return {
            texto: 'tipo_producto',
            currentPage:1,
            perPage:10,
            fields:[{key: 'tipo_producto_id', label: 'suministro_id', class:"d-none"},
                    {key: 'descripcion', label: 'Descripcion', sortable: true},
                    {key: 'edit', label: 'Acción', sortable: true},
                    // {key: 'DIFERENCIA', label: 'DIF', class:'negritas'},
                    ],
            tipo_producto:[],
            filter:'',
            cargando:false,
        }
    },
    components: {
        Modals,
        // Operadores,
    },
    computed: {
        ...mapState(['operador','modal','menu']),
        rows() {
            return this.tipo_producto.length
        },
        ocultarnuevo(){
            // if(this.tabselected==0 ){
            //     return true
            // }else{
                return false
            // }
        },
    },
    created () {
        // let f=Date();
        //  this.ffin= utils.getDateTime(f)
        //  this.fini=this.ffin
       //console.warn("la ruta: " + this.$route.name)
        this.gettipo_producto()
    },
    methods: {
        ...mapActions(['actupdateMenu','actupdateModal']),
        showmodal(tittle,message){
            this.modal.tittle = tittle
            this.modal.message = message
            this.modal.type = 0
            this.actupdateModal(this.modal)
            // console.log("ahora contiene:  " + JSON.stringify(this.modal))
            this.$refs.mod.showmodal()
        },
        gettipo_producto() {
            this.cargando=true
            this.$store.dispatch("getTiposProductos").then((resp)=>{

                // console.warn("La respuesta de getoperadores: " + JSON.stringify(resp))
                if(resp.estatus == 1){
                    // this.operadores=resp.data
                    this.tipo_producto=resp.tipo_producto
                    this.cargando=false
                }else{
                    this.showmodal(this.texto, resp.message)
                    this.cargando=false
                }
            }).catch((err)=>{
                this.showmodal(this.texto, err)
                this.cargando=false
            })
        },
       
        nuevoTipo(){
            // this.$router.push({ name: 'tipo_producto' });
           //console.warn("la ruta: " + this.$route.name)
            if (this.$route.name !== 'tipos_productos') {
                this.$router.push({ name: 'tipos_productos' });
            }
        },
        editarTipoProducto(item){
           //console.log("editando tipo de producto")
            this.$router.push({ name: 'tipos_productos', params: item});
        },
        // formatearNumero(numero) {
        //     numero = parseFloat(numero);

        //     if (isNaN(numero)) {
        //         return 'Invalido';
        //     }
        //     let partes = numero.toFixed(2).toString().split('.');
        //     // Agregar comas para separar los miles
        //     partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        //     // Unir las partes entera y decimal con un punto decimal
        //     return partes.join('.');
        // },
        imprimir(){
            //  console.log("EL tabselected: " + this.tabselected)
            //  if(this.tabselected===0){ 
            //      utils.exportar(this.renglonaje,'renglonaje')
            //  }else if(this.tabselected===1){
            utils.exportar(this.tipo_producto,'Tipos de producto')
            //  }
         },
    },
}
</script>

<style>

</style>