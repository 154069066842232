<template>
    <b-container class="px-3 justify-content-start mb-3 height-fixed margin2sidebar font-opensans" >
      <b-row class="d-flex justify justify-content-start">
        <b-col cols="10" sm="11" md="11" lg="11" xl="11">
          <h2><b-icon icon="file-earmark-text" ></b-icon> Solicitud de Servicio</h2>
        </b-col>
        <b-col cols="2" sm="1" md="1" lg="1" xl="1">
          <b-button variant="primary" class="shadow-sm back-bttn bold-text px-3 py-2 mb-3 mt-2" @click="goBack()"><b-icon icon="arrow-left"></b-icon></b-button>
        </b-col>
      </b-row>
  
      <b-form @submit.prevent="guardarSolicitud" class="shadow p-4 rounded form-background">
        <b-row>
          <!-- Cliente -->
          <b-col cols="12" md="6">
            <b-form-group label="Cliente:" class=" pb-3 label-col-blue negritas">
              <b-form-input v-model="solicitud.cliente" placeholder="Nombre del cliente" class="mt-1 shadow-sm" required :disabled="esVendedorEditando"/>
            </b-form-group>
          </b-col>
  
          <!-- Estatus -->
          <b-col cols="12" md="6"> 
            <b-row>
              <b-col md="6">
                <b-form-group label="Estatus:" label-for="estatus" class="pb-3 label-col-blue negritas" >
                  <b-form-select id="estatus" v-model="solicitud.estatus" :options="estatusOpciones" required 
                                  class="shadow-sm border rounded custom-border px-2 py-2 mt-1" :disabled="esVendedor"></b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="6" class="text-end">
                  <b-card-text class="label-col-blue" >Creado: {{muestraFechaHoraCreacion}}</b-card-text>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
           <!-- Tipo de Solicitud -->
           <b-form-group label="Tipo Solicitud:" label-for="tipo_solicitud" hidden>
            <b-form-select id="tipo_solicitud" v-model="solicitud.tipo" :options="tiposSolicitud" class="shadow-sm border rounded custom-border px-2 py-2 mt-1"></b-form-select>
          </b-form-group>
        </b-row>
        <b-row>
          <!-- Fecha Hora de la solicitud -->
          <b-col cols="12" md="6"> 
            <b-form-group label="Fecha Entrega:" label-for="fecha_hora_sol" class="pb-3 label-col-blue negritas">
              <b-form-datepicker
                id="fecha_hora_sol"
                v-model="solicitud.fecha_hora_sol"
                :state="true"
                required
              ></b-form-datepicker>
            </b-form-group>
          </b-col>
          <!-- Campo de Hora de Solicitud -->
          <b-col cols="12" md="6"> 
            <b-form-group label="Hora Entrega:" label-for="hora_solicitud" class="pb-3 label-col-blue negritas">
              <b-form-timepicker
                id="hora_solicitud"
                v-model="solicitud.hora"
                :state="true"
                locale="es"
                hour12
                required
                placeholder="Selecciona la hora"
              ></b-form-timepicker>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>

          <!-- Residente -->
          <b-col cols="12" md="6">
            <b-form-group label="Residente o encargado de obra:" class="label-col-blue negritas">
              <b-form-input v-model="solicitud.residente" required placeholder="Nombre del residente" class="mt-1 shadow-sm" :disabled="esVendedorEditando" />
            </b-form-group>
          </b-col>

          <!-- Obra -->
          <b-col cols="12" md="6">
            <b-form-group label="Obra:" class="pb-3 label-col-blue negritas">
              <b-form-input v-model="solicitud.obra" placeholder="Nombre de la obra" required class="mt-1 shadow-sm" :disabled="esVendedorEditando"/>
            </b-form-group>
          </b-col>

          <b-row class=" text-start">
            <!-- Operador ID con botón para agregar nuevo operador -->
            <b-col  xs="12" sm="12" md="6" lg="6" xl="3">
              <b-form-group label="Vendedor" class="pb-3 label-col-blue negritas" >
                <b-input-group>
                  <b-form-select v-model="solicitud.operador_id" :options="operadores"  :disabled="esVendedor" class="shadow-sm border rounded custom-border px-2 py-2 mt-1" />
                  <b-input-group-append>
                    <b-button @click="agregarOperador" variant="outline-primary" hidden  class="mt-1 py-2 shadow-sm"><b-icon icon="plus-circle"></b-icon></b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
    
            <!-- Producto con botón para agregar nuevo producto -->
            <b-col class=" text-start" xs="12" sm="12" md="6" lg="6" xl="4">
              <b-form-group label="Tipo de Producto" class="pb-3 label-col-blue negritas">
                <b-input-group>
                  <b-form-select v-model="solicitud.tipo_producto_id" :options="tiposProducto" required 
                                class="shadow-sm border rounded custom-border px-2 py-2 mt-1" :disabled="esVendedorEditando"/>
                  <b-input-group-append>
                    <b-button @click="agregarProducto" variant="outline-primary" hidden class="mt-1 py-2 shadow-sm"><b-icon icon="plus-circle"></b-icon></b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <!-- Producto con botón para agregar nuevo producto -->
            <b-col class=" text-start" xs="12" sm="12" md="6" lg="6" xl="3">
              <b-form-group label="Clave de Producto" class="pb-3 label-col-blue negritas" >
                <b-input-group>
                  <b-form-select v-model="solicitud.producto_id" :options="clavesproductos" required
                                class="shadow-sm border rounded custom-border px-2 py-2 mt-1" :disabled="esVendedorEditando"/>
                  <b-input-group-append>
                    <b-button @click="agregarProducto" variant="outline-primary"  hidden class="mt-1 py-2 shadow-sm"><b-icon icon="plus-circle"></b-icon></b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            
            <!-- Tipo de Servicio -->
            <b-col class="d-flex justify-content-start text-start" xs="6" sm="6" md="3" lg="3" xl="2">
              <b-form-group label="Tipo de Servicio:" class="pb-3 label-col-blue negritas">
                <b-form-select v-model="solicitud.tipo_servicio" :options="tiposServicio" required 
                              class="shadow-sm border rounded custom-border px-2 py-2 mt-1" :disabled="esVendedorEditando"/>
              </b-form-group>
            </b-col>
            
            <!--  Tipo Pago -->
            <b-col class="d-flex justify-content-start text-start" xs="6" sm="6" md="3" lg="3" xl="3">
              <b-form-group label="Tipo de Pago:" label-for="tipo_pago" class="pb-2 label-col-blue negritas">
                <b-form-select id="tipo_pago" v-model="solicitud.tipo_pago" :options="tipoPagoOpciones" required 
                              class="shadow-sm border rounded custom-border px-2 py-2 mt-1" :disabled="esVendedorEditando"></b-form-select>
              </b-form-group>
            </b-col>

            <!-- Ubicación con botón para Google Maps -->
            <b-col class="d-flex justify-content-start text-start" xs="12" sm="12" md="12" lg="6" xl="3">
              <b-form-group label="" class="pb-3 label-col-blue negritas" >
                <label for="ubicacion" class="label-col-blue negritas">Ubicacion: <small class="label-col-green" style="font-size: 10px"> {{solicitud.lat +', '+solicitud.lng}}</small></label>
                <b-input-group>
                  <b-form-input v-model="solicitud.ubicacion" placeholder="Ubicación de la obra" class="mt-1 shadow-sm" :disabled="esVendedorEditando" @blur="extraerCoordenadas"/>
                  <b-input-group-append>
                    <b-button @click="abrirMapa" variant="outline-primary" class="mt-1 py-2 shadow-sm" :disabled="esVendedorEditando"><b-icon icon="geo-alt"></b-icon></b-button>
                  </b-input-group-append>
                  
                </b-input-group>
              </b-form-group>
            </b-col>

            <!-- Elementos a colar (no editable pero no deshabilitado visualmente) -->
            <b-col xs="12" sm="6" md="6" lg="3" xl="3">
              <b-form-group label="Elementos a colar:" class="pb-3 label-col-blue negritas">
                <b-form-input v-model="solicitud.elementos" placeholder="# de elementos" required class="mt-1 shadow-sm" :disabled="esVendedorEditando"/>
              </b-form-group>
            </b-col>
            
            <!-- # de Resistencias -->
            <b-col xs="12" sm="6" md="6" lg="3" xl="3">
              <b-form-group label="# de Resistencias" class="pb-3 label-col-blue negritas">
                <b-form-input v-model="solicitud.resistencias" placeholder="Cantidad de resistencias" required class="mt-1 shadow-sm" :disabled="esVendedorEditando"/>
              </b-form-group>
            </b-col>


          </b-row>
         

          <!-- Tipo Pago -->
          <!-- <b-form-group label="Tipo Pago:" label-for="tipo_pago">
            <b-form-input id="tipo_pago" v-model="solicitud.tipo_pago" type="text" maxlength="25" required></b-form-input>
          </b-form-group> -->
           
          <b-row>
            <!-- Metros -->
            <b-col xs="12" sm="6" md="6" lg="3" xl="3">
              <b-form-group label="Metros" class="pb-3 label-col-blue negritas">
                <b-form-input v-model="solicitud.metros" placeholder="Cantidad de metros" required class="mt-1 shadow-sm" :disabled="esVendedorEditando"/>
              </b-form-group>
            </b-col>

            <!-- Metros Reales (no editable pero no deshabilitado visualmente) -->
            <b-col xs="12" sm="6" md="6" lg="3" xl="3">
              <b-form-group label="Metros Reales:" label-for="metros_reales" class="pb-3 label-col-blue negritas" :hidden="esVendedor">
                <b-form-input id="metros_reales" v-model="solicitud.metros_reales"  plain class="mt-1 shadow-sm"></b-form-input>
              </b-form-group>
            </b-col>
            
            <!-- Ajuste (float con default 0) -->
            <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="mr-1">
              <b-form-group label="Ajuste:" label-for="ajuste" class="pb-3 label-col-blue negritas" :hidden="esVendedor">
                <b-form-input id="ajuste" v-model="solicitud.ajuste"  placeholder="0" readonly required class="mt-1 shadow-sm"></b-form-input>
              </b-form-group>
            </b-col>
            
          </b-row>
            
            
            
          <b-row>
            <!-- Tubería Manguera (S/N) -->
            <b-col xs="12" sm="6" md="6" lg="4" xl="3">
              <b-form-group label="Necesita Tubería o Manguera:" label-for="tuberia_manguera" class="pb-3 label-col-blue negritas">
                <b-form-select id="tuberia_manguera" v-model="solicitud.tuberia_manguera" :options="tuberiaOpciones" required 
                              class="shadow-sm border rounded custom-border px-2 py-2 mt-1" :disabled="esVendedorEditando"></b-form-select>
              </b-form-group>
            </b-col>
            <!-- Tipo Manguera -->
            <b-col xs="12" sm="6" md="6" lg="4" xl="3">
              <b-form-group label="Tipo Manguera:" label-for="tipo_manguera" class="pb-3 label-col-blue negritas">
                <b-form-select id="tipo_manguera" v-model="solicitud.tipo_manguera" :options="tipoMangueraOpciones" required 
                            class="shadow-sm border rounded custom-border px-2 py-2 mt-1" :disabled="esVendedorEditando"></b-form-select>
              </b-form-group>
            </b-col>

            <!-- Tubos (solo números enteros) -->
            <b-col xs="12" sm="6" md="6" lg="4" xl="3">
              
              <b-form-group label="¿Cuantos Tubos?" label-for="tubos" class="pb-3 label-col-blue negritas">
                <b-form-input id="tubos" v-model="solicitud.tubos" type="number" step="1" min="0" 
                            required class="mt-1 shadow-sm" :disabled="esVendedorEditando"></b-form-input>
              </b-form-group>
            </b-col>
            
            <!-- Elemento -->
            <b-col xs="12" sm="6" md="6" lg="5" xl="3">
              <b-form-group label="Elemento:" label-for="elemento" class="pb-3 label-col-blue negritas">
                <b-form-input id="elemento" v-model="solicitud.elemento" type="text" maxlength="75" required 
                            class="mt-1 shadow-sm" :disabled="esVendedorEditando"></b-form-input>
              </b-form-group>
            </b-col>

            <!-- Intervalo -->
            <b-col xs="12" sm="6" md="4" lg="2" xl="3">
              <b-form-group label="Intervalo:" label-for="intervalo" class="pb-3 label-col-blue negritas">
                <b-form-select id="intervalo" v-model="solicitud.intervalo" :options="intervalos" required 
                            class="shadow-sm border rounded custom-border px-2 py-2 mt-1" :disabled="esVendedorEditando"></b-form-select>
              </b-form-group>
            </b-col>

            <!-- Distancia (Local/Foráneo) -->
            <b-col xs="12" sm="6" md="4" lg="2" xl="3">
              <b-form-group label="Distancia:" label-for="distancia" class="pb-3 label-col-blue negritas">
                <b-form-select id="distancia" v-model="solicitud.distancia" :options="distanciaOpciones" required 
                            class="shadow-sm border rounded custom-border px-2 py-2 mt-1" :disabled="esVendedorEditando"></b-form-select>
              </b-form-group>
            </b-col>

            <!-- Tiene Laboratorio -->
            <b-col xs="12" sm="6" md="4" lg="3" xl="6">
              <b-form-group label="Tiene laboratorio:" label-for="tiene_laboratorio" class="pb-3 label-col-blue negritas">
                <b-form-select id="tiene_laboratorio" v-model="solicitud.tiene_laboratorio" :options="laboratorioOpciones" required 
                        class="shadow-sm border rounded custom-border px-2 py-2 mt-1" :disabled="esVendedorEditando"></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Observaciones -->
          <div class="row">
            <label class="label-col-blue negritas" for="">Observaciones: 
              <small v-if="solicitud.sol_observaciones.length > 0" class="label-col-green" @click="abrirObservaciones" 
                    style="font-size: 12px"> Click aqui para ver las {{ solicitud.sol_observaciones.length }} observaciones {{ solicitud.sol_observaciones.length }}
              </small>
            </label>
           
          </div>  
           
          <b-form-group label="" label-for="observaciones" class="pb-3 label-col-blue negritas">
            <b-form-textarea id="observaciones" v-model="solicitud.observaciones" maxlength="300" required 
                          class="shadow-sm border rounded custom-border px-2 py-2 mt-1" ></b-form-textarea>
          </b-form-group>

          
          <!-- Fecha y Hora de Creación -->
          <!-- <b-form-group label="Fecha y Hora de Creación:" label-for="fecha_hora_creacion" class="pb-3">
              <b-card-text>Fecha y Hora de Creación: {{ solicitud.fecha_hora_creacion }}</b-card-text>
          </b-form-group> -->

          <!-- Fecha y Hora Reagendado -->
          <b-form-group label="Fecha y Hora Reagendado:" label-for="fecha_hora_reagendado" class="pb-5 label-col-blue negritas" :hidden="nuevo">
              <b-form-datepicker id="fecha_hora_reagendado" v-model="solicitud.fecha_hora_reagendado" 
                                class="shadow-sm border rounded custom-border px-2 py-2" :disabled="esVendedorEditando"></b-form-datepicker>
              <b-form-timepicker v-model="solicitud.hora_reagendado" :disabled="esVendedorEditando"></b-form-timepicker>
          </b-form-group>
          
          
          <b-row><!-- Sección de Suministros -->
            <b-col xs="12" sm="6" md="4" lg="3" xl="4">
              <b-form-group label="Suministros" class="pb-3 label-col-blue negritas">
                <b-form-checkbox-group v-model="solicitud.suministros" :options="suministros" plain inline :disabled="esVendedorEditando"/>
              </b-form-group>
            </b-col>
          </b-row>
          
          
        </b-row>
  
        <!-- Botones de acción -->
        <b-row>
          <b-col>
            <b-button variant="primary" class="shadow-sm back-bttn bold-text px-3 py-1 mb-3 mt-2" @click="goBack()">Regresar</b-button>
            <b-button v-if="!cargando" type="submit" variant="primary" class="shadow-sm save-bttn bold-text px-4 py-1 mb-3 mt-2 mx-3">Guardar</b-button>
            <b-spinner v-else class="text-center" variant="warning" ></b-spinner>
          </b-col>
        </b-row>
      </b-form>
      <b-modal ref="modObservaciones" hide-header-close scrollable button-size="sm" size="lg" title="Observaciones">
            <Observaciones :observaciones="solicitud.sol_observaciones"></Observaciones>
      </b-modal>   
      <Modals ref="mod"></Modals>
    </b-container>
  </template>
  
  <script>
  import Modals from '@/components/Modals.vue'
  import {mapState,mapActions} from 'vuex'
  import * as utils from "@/shared/util"
  import { ref } from 'vue'
  import Observaciones from '@/components/Observaciones.vue'
  export default {
    data() {
      return {
        texto: "Solicitud de servicio",
        solicitud: {
          cliente: '',
          fecha_hora_sol: '',
          hora: '',
          residente: '',
          operador_id: null,
          obra: '',
          producto_id: '',
          metros: 0,
          tipo_servicio: '',
          ubicacion: '',
          tipo: '',
          estatus: 'PENDIENTE',
          tipo_pago: '',
          ajuste: 0,
          metros_reales: 0.0,
          tuberia_manguera: '',
          tubos: 0,
          tipo_manguera: '',
          elemento: '',
          intervalo: '',
          distancia: '',
          tiene_laboratorio:'N',
          observaciones: null,
          fecha_hora_creacion: null,
          hora_creacion: null,
          fecha_hora_reagendado: null,
          hora_reagendado: null,
          suministros: [],
          sol_observaciones: [],
        },
        operadores: [
          { value: 1, text: 'Operador 1' },
          { value: 2, text: 'Operador 2' },
        ],
        clavesproductos: [
          { value: 'ClaveA', text: 'ClaveA' },
          { value: 'ClaveB', text: 'ClaveB' },
        ],
        tiposProducto: [
          { value: 'TIpoA', text: 'TIpoA' },
          { value: 'TIpoB', text: 'TIpoB' },
        ],
        tiposServicio: [
          { value: 'Bombeo', text: 'Bombeo' },
          { value: 'Directo', text: 'Directo' },
        ],
        suministros: [
          { value: 'Suministro 1', text: 'Suministro 1' },
          { value: 'Suministro 2', text: 'Suministro 2' },
        ],
        tiposSolicitud: [
          { value: 'CONFIRMADO', text: 'CONFIRMADO' },
          { value: 'EXTRAORDINARIO', text: 'EXTRAORDINARIO' },
          { value: 'CANCELADO', text: 'CANCELADO' }
        ],
        estatusOpciones: [
          { value: 'PENDIENTE', text: 'PENDIENTE' },
          { value: 'PROGRAMADO', text: 'PROGRAMADO' },
          { value: 'CARGANDO', text: 'CARGANDO' },
          { value: 'DESPACHADO', text: 'DESPACHADO' },
          { value: 'CANCELADO', text: 'CANCELADO' },
          { value: 'REPROGRAMADO', text: 'REPROGRAMADO' }
        ],
        tuberiaOpciones: [
          { value: 'S', text: 'Sí' },
          { value: 'N', text: 'No' }
        ],
        tipoPagoOpciones: [
          { value: 'Crédito', text: 'Crédito' },
          { value: 'Contado', text: 'Contado' }
        ],
        tipoMangueraOpciones: [
          { value: 'Doble casquillo', text: 'Doble casquillo' },
          { value: 'Anaconda', text: 'Anaconda' },
          { value: 'Ambas', text: 'Ambas' },
          { value: 'Ninguna', text: 'Ninguna' }
        ],
        distanciaOpciones: [
          { value: 'Local', text: 'Local' },
          { value: 'Foraneo', text: 'Foráneo' }
        ],
        laboratorioOpciones:[
          { value: 'S', text: 'SI' },
          { value: 'N', text: 'NO' }
        ],
        intervalos: this.generarIntervalos(),
        cargando: false,
        nuevo:true,
      };
    },
    components: {
        Modals,
        Observaciones,
        // Operadores,
    },
    watch: {
      // Watcher para detectar cambios en metros o metros_reales
      'solicitud.metros': 'calcularAjuste',
      'solicitud.metros_reales': 'calcularAjuste',
    },
    computed: {
      ...mapState(['operador','modal','menu']),
     
      
      esVendedor(){
        if(this.operador.perfil== 'Vendedor'){
          return true
        }
        return false
      },
      esVendedorEditando(){
        if(this.operador.perfil== 'Vendedor' && this.nuevo==false){
          return true
        }
        return false
      },

      muestraFechaHoraCreacion(){
        if(this.solicitud.fecha_hora_creacion){
          if(this.solicitud.hora_creacion){
             return this.solicitud.fecha_hora_creacion + ' ' + this.solicitud.hora_creacion
          }else{
            return this.solicitud.fecha_hora_creacion
          }
        } 
        return ''
      },
      // name() {
      //   return this.data 
      // }
    },
    // mounted() {
    //   this.setFechaActual();
    // }
    created() {
      //cargamos los catálogos que se requieren
     //console.log("el operador_ ",this.operador)
      this.$store.dispatch("getCatalogos").then((resp)=>{
        if(resp.estatus == 1){
          this.operadores=resp.operadores
          this.tiposProducto=resp.tipos_productos
          this.suministros=resp.suministros
          this.clavesproductos=resp.productos
        }
      }).catch((error)=>{
        this.showmodal(this.texto, error)
      })
     //console.log("el parametro enviado es: " , this.$route.params); // Accede al valor '123'
      if(this.$route.params.solicitud_id != undefined){
        this.solicitud=this.$route.params
        console.warn("la solicitud es: " + JSON.stringify(this.solicitud))
        if(this.solicitud.lat && this.solicitud.lng){
          this.solicitud.ubicacion = `${this.solicitud.lat}, ${this.solicitud.lng}`; 
        }
        this.nuevo=false
        this.solicitud.suministros=[]
        this.$store.dispatch("getSuministrosSolicitud",this.solicitud.solicitud_id).then((resp)=>{
         //console.log("los suministros son: "+JSON.stringify(resp))
          if(resp.estatus == 1){
            // let suministros=resp.suministros
            resp.suministros.forEach((suministro)=>{
              this.solicitud.suministros.push(suministro.suministro_id)  
            })
            
          }
        })
      }else{
        if(this.operador.perfil=="Vendedor"){
          this.solicitud.operador_id=this.operador.operador_id
          this.solicitud.estatus="PENDIENTE"
        }
      }
  
      let f=new Date();
      if(this.nuevo){
        this.solicitud.fecha_hora_sol= utils.getDateTime(f)
        // this.solicitud.hora= utils.getDateTime(f,4)
        const currentHour = f.getHours();
        const currentMinute = f.getMinutes();

        // Formatear la hora actual para que sea compatible con el v-model del timepicker
        this.solicitud.hora = `${currentHour}:${currentMinute < 10 ? '0' + currentMinute : currentMinute}`;

        this.solicitud.fecha_hora_creacion= utils.getDateTime(f)
        this.solicitud.hora_creacion = `${currentHour}:${currentMinute < 10 ? '0' + currentMinute : currentMinute}`;
        // this.solicitud.fecha_hora_modificacion= utils.getDateTime(f)

      }else{
        if(this.solicitud.fecha_hora_reagendado!=null){
          let fecha = new Date(this.solicitud.fecha_hora_reagendado);

          // Extraer la hora en formato HH:mm:ss
          let horas = fecha.getHours().toString().padStart(2, '0');
          let minutos = fecha.getMinutes().toString().padStart(2, '0');
          let segundos = fecha.getSeconds().toString().padStart(2, '0');

          // Formatear la hora como HH:mm:ss
          this.solicitud.hora_reagendado = `${horas}:${minutos}:${segundos}`;
        }
      }
      // this.form.estatus=1
     //console.log("Es nuevo :" + this.nuevo)
    },
    methods: {
      ...mapActions(['actupdateMenu','actupdateModal']),
      showmodal(tittle,message){
          this.modal.tittle = tittle
          this.modal.message = message
          this.modal.type = 0
          this.actupdateModal(this.modal)
         //console.log("ahora contiene:  " + JSON.stringify(this.modal))
          this.$refs.mod.showmodal()
      },
      abrirObservaciones(){
        this.$refs.modObservaciones.show()
      },
    
      async extraerCoordenadas() {
        // Expresión regular para validar las coordenadas con al menos 4 dígitos después del punto decimal
        // const coordinatePattern = /^(-?\d{2}\.\d{4,}),\s*(-\d{2,3}\.\d{4,})$/;

        // // Eliminar espacios y validar
        // const trimmedInput = this.solicitud.ubicacion.trim();
        // const match = trimmedInput.match(coordinatePattern);

        // if (match) {
        //     // Extraer latitud y longitud
        //     this.solicitud.lat = parseFloat(match[1]);
        //     this.solicitud.lng = parseFloat(match[2]);
        //     return true
        //     // Asignar coordenadas y limpiar mensaje de error
        // } else {
        //     // Mostrar error y limpiar coordenadas si el formato no es válido
        //     this.showmodal(this.texto, "El formato de la ubicación no es válido. Ejemplo de formato válido: 20.94283, -89.57831");
        //     this.solicitud.lat=null;
        //     this.solicitud.lng=null;
        //     return false
        // }
        // Expresión regular mejorada para coordenadas geográficas
        const coordinatePattern = /^(?!.*[(){}[\]])(-?(?:90(?:\.0+)?|[1-8]?\d(?:\.\d+)?)),\s*(-?(?:180(?:\.0+)?|(?:1[0-7]\d|[1-9]?\d)(?:\.\d+)?))$/;

    // Limpieza más exhaustiva de la entrada
        const trimmedInput = this.solicitud.ubicacion
            .trim()
            .replace(/\s+/g, ' ') // Normaliza espacios múltiples
            .replace(/[^\d.,\s-]/g, ''); // Solo permite números, punto, coma, espacio y signo negativo

        const match = trimmedInput.match(coordinatePattern);

        if (match) {
            const lat = parseFloat(match[1]);
            const lng = parseFloat(match[2]);

            // Validación adicional de rangos
            if (this.isValidLatitude(lat) && this.isValidLongitude(lng)) {
                this.solicitud.lat = lat;
                this.solicitud.lng = lng;
                this.solicitud.ubicacion = `${lat}, ${lng}`; 
                return true;
            }
        }

        // Mensaje de error más descriptivo
        this.showmodal(
            this.texto, 
            "El formato o valores de la ubicación no son válidos.\n" +
            "- La latitud debe estar entre -90 y 90 grados\n" +
            "- La longitud debe estar entre -180 y 180 grados\n" +
            "Ejemplo de formato válido: 20.94283, -89.57831"
        );
        this.solicitud.lat = null;
        this.solicitud.lng = null;
        return false;
      },
      isValidLatitude(lat) {
          return !isNaN(lat) && lat >= -90 && lat <= 90;
      },

      isValidLongitude(lng) {
          return !isNaN(lng) && lng >= -180 && lng <= 180;
      },
      generarIntervalos() {
        const intervalos = [];
        let horas = 0;
        let minutos = 5;
        while (horas < 4) {
          let h = horas < 10 ? '0' + horas : horas;
          let m = minutos < 10 ? '0' + minutos : minutos;
          intervalos.push({ value: `${h}:${m}`, text: `${h}:${m}` });
          minutos += 5;
          if (minutos === 60) {
            minutos = 0;
            horas++;
          }
        }
        return intervalos;
      },
      agregarOperador() {
        // Lógica para agregar un nuevo operador
        alert('Agregar nuevo operador');
      },
      agregarProducto() {
        // Lógica para agregar un nuevo producto
        alert('Agregar nuevo producto');
      },
      abrirMapa() {
        // Lógica para abrir Google Maps en la ubicación actual
        alert('Abrir Google Maps');
        if(this.solicitud.lat && this.solicitud.lng){
          window.open("https://www.google.com/maps?q="+this.solicitud.lat+","+this.solicitud.lng)
        }else{
          window.open("https://www.google.com/maps")  
        }
      },
      async guardarSolicitud() {
        // Lógica para guardar la solicitud
       //console.log('Solicitud guardada:' + JSON.stringify(this.solicitud)); // Imprime el objeto de solicitud this.solicitud);
        this.cargando=true
        //validamos lat y lng
        let pasar=await this.extraerCoordenadas()
        if(!pasar){
          this.cargando=false
          return
        }
        console.log("las coordenadas son : "+this.solicitud.lat+" "+this.solicitud.lng)
        //validamos que eligan suministros
        if(this.solicitud.suministros.length<=0){
          this.showmodal(this.texto, "Debe elegir al menos 1 suministro antes de guardar la sulicitud")
          this.cargando=false
        }
        if(this.nuevo){
         
          this.$store.dispatch("createSolicitud",this.solicitud).then((resp)=>{
          //console.log("dispatch createSolicitud trajo: "+JSON.stringify(resp))
            if(resp.estatus==1){
              this.goBack()
            }else{
              this.showmodal(this.texto, resp.message)
              this.cargando=false
            }
          }).catch((error)=>{
            this.showmodal(this.texto, error)
            this.cargando=false
          })
          
        }else{
          if(this.solicitud.fecha_hora_reagendado!=null){
            if(this.solicitud.hora_reagendado!=null){
              let fecha = this.solicitud.fecha_hora_reagendado;
              // Si la fecha no es una instancia de Date, la convertimos
              if (!(fecha instanceof Date)) {
                // Convertir el valor de la fecha a un objeto Date
                fecha = new Date(fecha);
              }
              // Formatear la fecha en formato YYYY-MM-DD
              const fechaFormateada = fecha.toISOString().split('T')[0];
              // Obtener la hora en formato HH:mm:ss
              const hora = this.solicitud.hora_reagendado;
              // Concatenar fecha y hora en el formato correcto
              const fechaHoraCompleta = `${fechaFormateada} ${hora}`;
              this.solicitud.fecha_hora_reagendado = fechaHoraCompleta;
              
            }else{
              this.showmodal(this.texto, "Es necesario ingresar la hora de reagendamiento")
              this.cargando=false
              return ""
            }
           //console.log("la fecha y hora reagendada es: " + this.solicitud.fecha_hora_reagendado)
            this.solicitud.estatus="REPROGRAMADO"
          }
          this.solicitud.perfil=this.operador.perfil
         
          this.$store.dispatch("updateSolicitud",this.solicitud).then((resp)=>{
           //console.log("dispatch updateSolicitud trajo: "+JSON.stringify(resp))
            if(resp.estatus==1){
              this.goBack()
            }else{
              this.showmodal(this.texto, resp.message)
              this.cargando=false
            }
          }).catch((error)=>{
            this.showmodal(this.texto, error)
            this.cargando=false
          })
        }
      },
      
      calcularAjuste() {
        // Calcula la diferencia
        if(this.solicitud.metros_reales > 0){
          this.solicitud.ajuste = this.solicitud.metros_reales - this.solicitud.metros;  
        }
      },
      goBack(){
        this.$router.go(-1)
      },
      // regresar() {
      //   // Lógica para regresar a la vista anterior
      //   this.$router.push('/');
      // },
    },
  };
  </script>
  
  <style scoped>
  /* Agrega estilos personalizados aquí si es necesario */
  </style>
  