<template>
    <div class="my-text height-fixed font-opensans">
      <!-- <h3 class="warning">{{texto}}</h3> -->
        <div class="card shadow p-2">
            <div class=" fluid mb-3">
                <div class="row m-2  p-2 rounded vOperadores-custom"> 
                    <b-col sm="9" class="text-start">
                        <h5 class="fcwhite pt-1"><b-icon  icon="file-earmark-text" font-scale="1" ></b-icon><strong> Vista de Pedidos</strong></h5>
                    </b-col>
                   
                    <b-col sm="3" class="text-end">
                        <b-button v-if="!cargando" :hidden="ocultarnuevo" class="btn-sm p-2 vOperadores_vProductos-bttn" variant="outline-warning" @click="imprimir()"><b-icon icon="plus-circle" font-scale="1"></b-icon> Exportar</b-button>
                        <b-spinner v-else class="text-center" variant="warning" ></b-spinner>
                    </b-col>
                </div>
               
                <b-row> 
                    <b-col md="6" class="py-1">
                        <label class="fs-14 ps-2" for="example-datepicker">Fechas a elegir</label>
                    </b-col>
                    <b-col md="6" class="text-end fs-14  py-2">
                        <!-- <b-form-checkbox
                            id="checkbox-1"
                            v-model="incluirPedidosSinRemision"
                            plain
                            inline
                            class="pe-3"> 
                            Incluir pedidos sin remisión
                        </b-form-checkbox> -->
                    </b-col>
                    <!-- <b-form-checkbox-group v-model="incluirPedidosSinRemision" plain inline text="Aplicar filtro de fechas en todos los ficheros"/> -->
                </b-row>   
                <b-row > 
                    <b-col md="5">
                        <div class="container">
                            <b-form-datepicker id="example-datepicker1" v-model="fini" class="mb-2"></b-form-datepicker>
                            <!-- <p class="fs-10 fcgreen">Fecha inicial: '{{ fini }}'</p> -->
                        </div> 
                    </b-col>   
                    <b-col md="5">
                        <div class="container">
                            <b-form-datepicker id="example-datepicker2" v-model="ffin" class="mb-2"></b-form-datepicker>
                            <!-- <p class="fs-10 fcgreen">Fecha final: '{{ ffin }}'</p> -->
                        </div>
                    </b-col> 
                    <b-col>  
                        <b-button v-if="!cargando" class="btn-md py-2 px-3 consultar-bttn" variant="warning" @click="getPedidos()"><b-icon icon="zoom-in" font-scale="1"></b-icon> Consultar</b-button>
                        <b-spinner v-else variant="warning" ></b-spinner>
                    </b-col>
                </b-row>   
               
            </div>
        </div>   
        <div class="card shadow mt-4 p-2" >
            <b-container fluid  v-if="pedidos.length > 0">
                <b-row>
                    <b-form-group
                        label=""
                        label-for="filter-input"
                        label-cols-sm="3"
                        label-align-sm="right"
                        label-size="sm"
                        class="mb-0"
                        >
                        <b-input-group size="sm">
                            <b-form-input
                            id="filter-input"
                            v-model="filter"
                            type="search"
                            placeholder="Escribe para filtrar"
                            ></b-form-input>
    
                            <b-input-group-append>
                            <b-button :disabled="!filter" @click="filter = ''" class="limpiar-bttn">Limpiar</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-row>
                <!-- <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    aria-controls="my-table"
                    size="sm"
                    pills 
                    align="center"
                    class="mt-2"
                ></b-pagination> -->
                <b-table v-if="!cargando" hover small :items="pedidos" :fields="fields" selectable responsive striped
                        caption-top :filter="filter"  select-mode="single" label-sort-asc="" label-sort-desc="" 
                        label-sort-clear="" style="font-size:12px;" @row-dblclicked="agregarRemision"  
                        :tbody-tr-class="rowClass" table-class="custom-table"  thead-class="table-header"
                        tbody-class="table-body">
                    <template #table-caption >
                        <b-row>
                            <strong>Pedidos totales: {{pedidos.length}}</strong>
                        </b-row>
                        
                    </template>
                    <template #cell(fecha_hora_sol)="data">
                        <span v-html="formatearFecha(data.value,2)"></span>
                    </template> 
                    <template #cell(hora)="data">
                        <span v-html="formatearFechaHora(data.value,4)"></span>
                    </template> 
                    <!--  @dblclick.stop="agregarRemision(data.item)" -->
                    <template #cell(remisiones)="data">
                        <div  style="width: 100%; min-height: 20px;">
                            <div v-if="data.value && data.value.length > 0">
                                <div v-for="remision in data.value" :key="remision.id">
                                    <!-- Mostrar el label con la información de pedidos -->
                                    <span class="label label-default">{{ remision.folio }},</span>
                                </div>
                            </div>
                            <div v-else>
                                <!-- Marcador de posición cuando no hay pedidos -->
                                <span class="text-muted">No hay Remisiones</span>
                            </div>
                        </div>
                    </template>
                </b-table>
                <b-spinner v-else class="text-center" variant="warning" ></b-spinner>
            </b-container>
        </div>
      
        <b-modal ref="modal-remisiones" hide-header-close scrollable button-size="sm" size="lg"  :title="'Remision(es) del pedido ' + pedSelected.folio " @ok="cerrarModal()" @shown="setFocusOnInput">
            <div  class="card shadow p-3 fs-12 text-center"> 
                <b-row>
                    <!-- <b-button @click="saveColor">Guardar Color</b-button> -->
                    <b-row>
                        <b-col md="4">
                            <b-form-group label="Folio remision" class="label-col-blue negritas text-start">
                                <b-form-input ref="inputFolio" v-model="folio" class="mt-1 shadow-sm" type="text"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col md="4">
                            <b-form-group label="Operador asignado" class="label-col-blue negritas text-start" >
                                <b-form-select style="width: 100%;" v-model="operador_aisg_id" :options="cat_operadores" class="shadow-sm border rounded custom-border px-2 py-2" />
                            </b-form-group>
                        </b-col>
                        <b-col md="4">
                            <b-form-group label="# de Unidad" class="label-col-blue negritas text-start">
                                <b-form-input ref="inputUnidad" v-model="unidad" class="mt-1 shadow-sm" type="text"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row class="mt-4">
                        <b-col md="4">
                            <b-form-group label="Metros" class="label-col-blue negritas text-start">
                                <b-form-input ref="inputUnidad" v-model="metros" class="mt-1 shadow-sm" type="number"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col md="4">
                          
                        </b-col>
                        <b-col md="4">
                            <div class="text-end mt-4">
                                <b-button v-if="!cargando" :disabled="bloquearAgregar" style="width: 120px;" class="btn-md py-2 px-3 consultar-bttn" 
                                            size="sm" variant="outline-dark" @click="guardarRemision(folio,operador_aisg_id,unidad,metros)">Agregar</b-button>
                                <b-spinner v-else class="text-center" variant="warning" ></b-spinner>
                            </div>
                        </b-col>
                       
                    </b-row>

                    <b-table hover small :items="remisiones" :fields="fields_remisiones" selectable responsive striped
                            caption-top select-mode="single"  label-sort-asc="" label-sort-desc="" label-sort-clear="" style="font-size:12px;"
                            table-class="custom-table"  thead-class="table-header"
                            tbody-class="table-body" @row-dblclicked="editarRemision">
                        <template #table-caption >
                            <b-row>
                                <strong>Remisiones totales: {{remisiones.length}}</strong>
                            </b-row>
                        </template>
                        <template #cell(operador)="data" >
                            <!-- <b-button class="btn-md" size="sm" variant="outline-danger" @click="eliminarRemision(data.item)">Eliminar</b-button> -->
                            <span class="label label-default">{{ data.item.operador_asignado.nombre}}</span>
                        </template> 
                        <template #cell(del)="data" >
                            <!-- <b-button class="btn-md" size="sm" :disabled="data.item.estatus!='ASIGNADO' ? true : false" variant="outline-danger" @click="actualizarRemision(data.item)"><i class="fas fa-ban"></i></b-button> -->
                            <b-button class="btn-md" size="sm" :disabled="data.item.estatus!='ASIGNADO' ? true : false" variant="outline-danger" @click="actualizarRemision(data.item,true)" >
                                <i class="fas fa-ban"></i>
                            </b-button>
                        </template> 
                    </b-table>
                </b-row>
               
            </div>
            <template #modal-footer="{ ok, cancel }">
                <!-- Emulate built in modal footer ok and cancel button actions -->
                <b-button size="sm" variant="success" @click="ok()">
                    Cerrar
                </b-button>
                <b-button size="sm" variant="secondary" @click="cancel()">
                    Cancel
                </b-button>
            </template>
        </b-modal>
        <Modals ref="mod"></Modals>
    </div>
</template>
  
<script>
import Modals from '@/components/Modals.vue'
// import solicitudes from '@/catalogos/solicitudes.vue'
import {mapState,mapActions} from 'vuex'
import * as utils from "@/shared/util"

export default {
    data() {
        return {
            texto: 'Pedidos',
            tabselected:0,
            currentPage:1,
            perPage:10,
            fields:[{key: 'solicitud_id', label: '#Sol', class:"negritas", sortable: true},
                    {key: 'pedido_id', label: 'Pedido_id', sortable: true, class:"d-none"},
                    {key: 'fecha_hora_sol', label: 'Fecha Sol.', sortable: true,class:'text-center'},
                    {key: 'hora', label: 'hora', sortable: true,class:'text-center'},
                    {key: 'folio', label: 'Folio', sortable: true, class:'negritas'},
                    {key: 'estatus', label: 'Estatus', sortable: true},
                    {key: 'cliente', label: 'Cliente', sortable: true, class:'negritas'},
                    {key: 'obra', label: 'Obra', sortable: true},
                    {key: 'clave_producto', label: 'Clave', sortable: true, class:'negritas'},
                    {key: 'producto_id', label: 'Producto_id', sortable: true, class:'negritas d-none'},
                    {key: 'tipo_producto', label: 'Tipo Prod.', sortable: true, class:'negritas'},
                    {key: 'metros_totales', label: 'M.Reales', sortable: true, class:'negritas'},
                    {key: 'remisiones', label: 'Remisiones', sortable: true},
                    {key: 'lat', label: 'lat', sortable: true},
                    {key: 'lng', label: 'lng', sortable: true},
                    // {key: 'fecha_hora_sol', label: 'Fecha solicitud', sortable: true},
                    // {key: 'hora', label: 'Hora solicitud', sortable: true},
                    // {key: 'residente', label: 'Residente', sortable: true},
                    // {key: 'operador_id', label: 'Operador_id', sortable: true, class:"d-none"},
                    // {key: 'vendedor', label: 'Vendedor', sortable: true},
                    
                    
                    // {key: 'metros', label: 'Metros', sortable: true, class:'negritas'},
                    // {key: 'ajuste', label: 'Ajuste', sortable: true, class:'negritas'},

                    // {key: 'tipo_servicio', label: 'Tipo servicio', sortable: true, class:'negritas'},
                    // {key: 'tuberia_manguera', label: 'Tuberia o Manguera', sortable: true},
                    // {key: 'tubos', label: 'Tubos', sortable: true},
                    // {key: 'tipo_manguera', label: 'Tipo manguera', sortable: true},
                    // {key: 'suministros', label: 'Suministros', sortable: true},
                    // {key: 'color', label: 'color', sortable: true, class:'d-none'},
                    // {key: 'elemento', label: 'Elemento', sortable: true},
                    // {key: 'intervalo', label: 'Intervalo', sortable: true},
                    // {key: 'distancia', label: 'Distancia', sortable: true},
                    // {key: 'ubicacion', label: 'Ubicación', sortable: true},
                    // {key: 'tiene_laboratorio', label: 'Lab', sortable: true},
                    // {key: 'observaciones', label: 'Observaciones', sortable: true},
                    // {key: 'solicitud', label: 'Solicitud', sortable: true, class:'d-none'},
                    // {key: 'pedidos', label: 'Pedidos', sortable: true},
                    // {key: 'fecha_hora_creacion', label: 'Creado', sortable: true},
                    // {key: 'fecha_hora_reagendado', label: 'Reagendado', sortable: true},
                    // {key: 'edit', label: 'Acción', sortable: true},
                    // {key: 'DIFERENCIA', label: 'DIF', class:'negritas'},
                    ],
            fields_remisiones:[
                {key: 'remision_id',label:'pedido_id',class:"d-none"},
                {key: 'pedido_id',label:'solicitud_id',class:"d-none"},
                {key: 'folio',label:'Folio', sortable: true,class:"negritas"},
                {key: 'fecha_hora_creacion',label:'Creado', sortable: true, class:"d-none"},
                {key: 'estatus',label:'Estatus', sortable: true,class:"negritas"},
                {key: 'operador_id_creo',label:'Creo', sortable: true, class:"d-none"},
                {key: 'operador_asig_id',label:'Operador', sortable: true, class:"d-none"},
                {key: 'fecha_hora_asig',label:'Asignado', sortable: true, class:"d-none"},
                {key: 'operador',label:'Operador', sortable: true},
                {key: 'metros',label:'Metros', sortable: true},
                {key: 'unidad',label:'# Eco', sortable: true},
                {key: 'del',label:'Acción', sortable: true},
            ],
            cat_operadores: [
                { value: 1, text: 'Operador 1' },
                { value: 2, text: 'Operador 2' },
            ],
            pedidos:[],
            remisiones:[],
            pedSelected:{},
            filter:'',
            folio:null,
            unidad:null,
            operador_aisg_id:null,
            metros:null,
            cargando:false,
            ffin:null,
            fini:null,
            incluirPedidosSinRemision:true,
            availableProperties: [],
            selectedProperties: [],
            filteredObject: null,
            arregloExportar:[],
            esEdicion:null,

        }
    },
    components: {
        Modals,
        // Operadores,
    },
    watch: {
        // Monitorea los cambios en 'selectedProperties' y muestra las propiedades seleccionadas dinámicamente
        selectedProperties(newSelected) {
            // console.log('Propiedades seleccionadas:', newSelected);
        }
    },
    computed: {
        ...mapState(['operador','modal','menu']),
        rows() {
            return this.pedidos.length
        },
        ocultarnuevo(){
            // if(this.tabselected==0 ){
            //     return true
            // }else{
                return false
            // }
        },
        bloquearAgregar(){
            if(this.folio == null || this.operador_aisg_id == null || this.unidad == null || this.metros == null){
                return true
            }
            return false
        }
       
    },
    created () {
        let f=Date();
         this.ffin= utils.getDateTime(f)
         this.fini=this.ffin
        // console.warn("la ruta: " + this.$route.name)
        this.getPedidos()
    },
    methods: {
        ...mapActions(['actupdateMenu','actupdateModal']),
        showmodal(tittle,message){
            this.modal.tittle = tittle
            this.modal.message = message
            this.modal.type = 0
            this.actupdateModal(this.modal)
            // console.log("ahora contiene:  " + JSON.stringify(this.modal))
            this.$refs.mod.showmodal()
        },
        getPedidos() {
//             console.log("el filtro de todos.:" + this.incluirPedidosSinRemision)
// return 
            this.cargando=true
            let val={
                fini:this.fini,
                ffin:this.ffin,
                incluirSinRemision: this.incluirPedidosSinRemision
            }
            this.$store.dispatch("getPedidos",val).then((resp)=>{

                console.warn("La respuesta de pedidos: " + JSON.stringify(resp))
                if(resp.estatus == 1){
                    // this.operadores=resp.data
                    this.pedidos=resp.pedidos
                    this.cat_operadores=resp.operadores
                    
                    
                    this.cargando=false
                }else{
                    this.showmodal(this.texto, resp.message)
                    this.cargando=false
                }
            }).catch((err)=>{
                this.showmodal(this.texto, err)
                this.cargando=false
            })
        },
        rowClass(item) {
            // Verifica si el estatus es 'CANCELADO' y aplica una clase específica
            console.log("los items son: " + item.estatus)
            return item.estatus === 'CANCELADO' ? 'row-cancelado' : '';
        },
        formatearFecha(fecha,formato=0) {
            //console.log("formateando fecha: " + fecha + "formato: " + formato)
            let fechaformateada=utils.getDateTime(fecha,formato)
           // console.log("formateando fecha: " + fechaformateada)
            return fechaformateada
        },
        formatearFechaHora(fecha,formato){
            let fechaActual = new Date();
            let fechaActualString = fechaActual.toISOString().split('T')[0]; // Obtiene la fecha en formato 'YYYY-MM-DD'
            //console.log("la fecha actual es: " + fechaActualString)
            // Concatenar la fecha actual con la hora de solicitud
            let fechaHoraConcatenada = `${fechaActualString}T${fecha}`;
            //console.log("la fecha y hora concatenada es: " + fechaHoraConcatenada)
            // Crear un nuevo objeto Date con la fecha y hora concatenadas
            let fechaConHora = new Date(fechaHoraConcatenada);
            let fechaformateada=utils.getDateTime(fechaConHora,formato)
            return fechaformateada
        },
        setFocusOnInput(){
            this.$refs.inputFolio.focus()
        },
        agregarRemision(item){
            this.esEdicion=null
            console.log("El item seleccionado: " + JSON.stringify(item))
            if(item.estatus=="CANCELADO"){
                this.showmodal(this.texto, "No se puede agregar una remisión a un pedido cancelado")
            }else{
                this.remisiones=item.remisiones
                this.pedSelected=item
                this.abrirModales("modal-remisiones")    
            }
        },
       

        guardarRemision(fol,op_asig_id,uni,mts){
            this.cargando=true
            console.log("guardando la remision con el folio: " + fol + " y el op_asig_id: " + op_asig_id)
            if(fol!="" && op_asig_id!=""){
                if(this.esEdicion!=null){
                    this.esEdicion.folio=fol
                    this.esEdicion.operador_asig_id=op_asig_id
                    this.esEdicion.unidad=uni
                    this.esEdicion.metros=mts
                    this.actualizarRemision(this.esEdicion,false)
                }else{
                    //buscamos el operador asignado en $operdores
                    let obj_op=this.cat_operadores.find((obj)=>{return obj.value==op_asig_id})
                    console.log("EL obj_op: " + JSON.stringify(obj_op))
                    let obj_operador={operador_id:  obj_op.value, 
                                    nombre:       obj_op.text}
                    //lanzamos el dispatch para guardar un pedido...
                    console.log("guardando el folio " + fol)
                    let remision={remision_id:null,
                                pedido_id:this.pedSelected.pedido_id,
                                folio:fol,
                                unidad:uni,
                                metros:mts,
                                fecha_hora_creacion: utils.getDateTime(new Date(),0),
                                estatus:'ASIGNADO',
                                operador_id_creo:this.operador.operador_id,
                                operador_asig_id: op_asig_id,
                                fecha_hora_asig: utils.getDateTime(new Date(),0),
                                operador_asignado: obj_operador
                    }
                    // this.folio=null
                    // this.operador_aisg_id=null
                    // this.cargando=false
                    // return 
                    console.log("la remision a guardar es: " + JSON.stringify(remision))
                    this.$store.dispatch("createRemision",remision).then((resp)=>{
                        console.log("el response de createRemision es: " + JSON.stringify(resp))
                        if(resp.estatus==1){
                            resp.remision.operador_asignado=obj_operador
                            this.remisiones.push(resp.remision)
                            this.folio=null
                            this.unidad=null
                            this.operador_aisg_id=null
                            this.metros=null
                            this.cargando=false
                        }else{
                            this.cargando=false
                            this.showmodal(this.texto,resp.message)
                        }
                    }).catch((err)=>{
                        this.showmodal(this.texto,err)
                        this.cargando=false
                    })
                }
            }
        },
    //     eliminarRemision(item){
    //         console.log("El item seleccionado para eliminar: " + JSON.stringify(item))
    //         //lanzamos el dispatch para eliminar un pedido...
    //         if(item){
    //             this.$store.dispatch("deleteRemision",item.remision_id).then((resp)=>{
    //                 console.log("el response de deletePedido es: " + JSON.stringify(resp))
    //                 if(resp.estatus==1){
    //                     // this.pedidos=this.pedidos.filter(x=>x.pedido_id!=item.pedido_id)
    //                     const index = this.remisiones.findIndex(remision => remision.remision_id === item.remision_id);
    //                     if (index !== -1) {
    //                         this.remisiones.splice(index, 1);
    //                     }
    //                 }else{
    //                     this.showmodal(this.texto,resp.message)
    //                 }
    //             }).catch((err)=>{
    //                 this.showmodal(this.texto,err)
    //             })
    //         }
    //    },
        editarRemision(item){
            if( item.estatus=="ASIGNADO"){
                this.operador_aisg_id=item.operador_asig_id
                this.folio=item.folio
                this.unidad=item.unidad    
                this.esEdicion=item
                this.metros=item.metros
            }else{
                this.operador_aisg_id=null
                this.folio=""
                this.unidad=""    
                this.metros=null
                this.esEdicion=null
            }
        },
        actualizarRemision(item,cancelar=true){
            if (cancelar){
                item.estatus="CANCELADO"
                item.folio="C" + item.folio
            }
            console.log("El item seleccionado para actualizar: " + JSON.stringify(item))
            //lanzamos el dispatch para eliminar un pedido...
            if(item){
                this.$store.dispatch("updateRemision",item).then((resp)=>{
                    console.log("el response de deletePedido es: " + JSON.stringify(resp))
                    if(resp.estatus==1){
                        // this.pedidos=this.pedidos.filter(x=>x.pedido_id!=item.pedido_id)
                        if (cancelar){
                            const index = this.remisiones.findIndex(remision => remision.remision_id === item.remision_id);
                            if (index !== -1) {
                                this.remisiones.splice(index, 1);
                            }
                        }else{
                            this.folio=""
                            this.operador_aisg_id=null
                            this.unidad=""
                            this.metros=null
                        }
                        this.esEdicion=null
                        this.cargando=false
                    }else{
                        this.showmodal(this.texto,resp.message)
                        this.cargando=false
                    }
                }).catch((err)=>{
                    this.showmodal(this.texto,err)
                    this.cargando=false
                })
            }
        },
        cerrarModal(){
            //this.$refs['modal-remisiones'].close()
            //this.obtenerSolicitudes()
        },
        // formatearNumero(numero) {
        //     numero = parseFloat(numero);

        //     if (isNaN(numero)) {
        //         return 'Invalido';
        //     }
        //     let partes = numero.toFixed(2).toString().split('.');
        //     // Agregar comas para separar los miles
        //     partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        //     // Unir las partes entera y decimal con un punto decimal
        //     return partes.join('.');
        // },
        imprimir(){
            utils.exportar(this.pedidos,'Pedidos del ' + this.fini +' al ' + this.ffin)
        },
        // createFilteredObject() {
        //     // Filtrar el objeto 'pendientes' basado en las propiedades seleccionadas
        //     this.filteredObject = this.selectedProperties.reduce((acc, prop) => {
        //         if (this.arregloExportar[prop] !== undefined) {
        //         acc[prop] = this.arregloExportar[prop];
        //         }
        //         return acc;
        //     }, {});
        //     console.log("Las columnas seleccionadas son: " + JSON.stringify(this.filteredObject))
        // },

      
        abrirModales(id){
          console.log("El id del modal a mosrar :" + id )
          this.$refs[id].show()
      },
    },
}
</script>

<style>

</style>