<template>
    <div class="my-text height-fixed font-opensans">
      <!-- <h3 class="warning">{{texto}}</h3> -->
        <div class="card shadow p-2">
            <div class=" fluid mb-3">
                <div class="row m-2  p-2 rounded vOperadores-custom"> 
                    <b-col sm="9" class="text-start">
                        <h5 class="fcwhite pt-1"><b-icon  icon="file-earmark-text" font-scale="1" ></b-icon><strong> Vista de Remisiones</strong></h5>
                    </b-col>
                   
                    <b-col sm="3" class="text-end">
                        <b-button v-if="!cargando" :hidden="ocultarnuevo" class="btn-sm p-2 vOperadores_vProductos-bttn" variant="outline-warning" @click="imprimir()"><b-icon icon="plus-circle" font-scale="1"></b-icon> Exportar</b-button>
                        <b-spinner v-else class="text-center" variant="warning" ></b-spinner>
                    </b-col>
                </div>
               
                <b-row v-if="!esVendedor" esVendedor> 
                    <b-col md="6" class="py-1">
                        <label class="fs-14 ps-2" for="example-datepicker">Fechas a elegir</label>
                    </b-col>
                    <b-col md="6" class="text-end fs-14  py-2">
                        <b-form-checkbox
                            id="checkbox-1"
                            v-model="incluirRemisionesAsignadas"
                            plain
                            inline
                            class="pe-3"> 
                            Incluir Remisiones Asignadas
                        </b-form-checkbox>
                    </b-col>
                    <!-- <b-form-checkbox-group v-model="incluirRemisionesAsignadas" plain inline text="Aplicar filtro de fechas en todos los ficheros"/> -->
                </b-row>   
                <b-row  v-if="!esVendedor"> 
                    <b-col md="5">
                        <div class="container">
                            <b-form-datepicker id="example-datepicker1" v-model="fini" class="mb-2"></b-form-datepicker>
                            <!-- <p class="fs-10 fcgreen">Fecha inicial: '{{ fini }}'</p> -->
                        </div> 
                    </b-col>   
                    <b-col md="5">
                        <div class="container">
                            <b-form-datepicker id="example-datepicker2" v-model="ffin" class="mb-2"></b-form-datepicker>
                            <!-- <p class="fs-10 fcgreen">Fecha final: '{{ ffin }}'</p> -->
                        </div>
                    </b-col> 
                    <b-col>  
                        <b-button v-if="!cargando" class="btn-md py-2 px-3 consultar-bttn" variant="warning" @click="getRemisiones()"><b-icon icon="zoom-in" font-scale="1"></b-icon> Consultar</b-button>
                        <b-spinner v-else variant="warning" ></b-spinner>
                    </b-col>
                </b-row>   
               
            </div>
        </div>   
        <div class="card shadow mt-4 p-2" >
            <b-tabs  v-model="tabselected" @input="handleTabChange" >
                <b-tab v-if="!esVendedor" :title="'Remisiones (' + remisiones.length + ')'" active  >
                    <b-container fluid  v-if="remisiones.length > 0">
                        <b-row>
                            <b-form-group
                                label=""
                                label-for="filter-input"
                                label-cols-sm="3"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                                >
                                <b-input-group size="sm">
                                    <b-form-input
                                    id="filter-input"
                                    v-model="filter"
                                    type="search"
                                    placeholder="Escribe para filtrar"
                                    ></b-form-input>
            
                                    <b-input-group-append>
                                        <b-button :disabled="!filter" @click="filter = ''" class="limpiar-bttn">Limpiar</b-button>
                                    </b-input-group-append>
                                    <b-input-group-append v-if="operador.perfil=='Administrador'">
                                        <b-button class="btn ms-2" variant="danger" @click="borrarRemisiones" >Borrar remisiones anteriores</b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </b-row>
                      
                        <b-table v-if="!cargando" hover small :items="remisiones" :fields="fields" selectable responsive striped
                                caption-top :filter="filter"  select-mode="single" label-sort-asc="" label-sort-desc="" 
                                label-sort-clear="" style="font-size:12px;" @row-dblclicked="agregarRemision"
                                 :tbody-tr-class="rowClass" table-class="custom-table"  thead-class="table-header"
                                 tbody-class="table-body">
                            <template #table-caption >
                                <b-row>
                                    <strong>Remisiones totales: {{remisiones.length}}</strong>
                                </b-row>
                            </template>
                            <template #cell(fecha_hora_asig)="data">
                                <span v-html="formatearFecha(data.value,5)"></span>
                            </template> 
                            <template #cell(fecha_hora_creacion)="data">
                                <span v-html="formatearFecha(data.value,5)"></span>
                            </template> 
                        </b-table>
                        <b-spinner v-else class="text-center" variant="warning" ></b-spinner>
                    </b-container>
                </b-tab>
                <b-tab  v-if="!esVendedor" :title="'Marcaciones Operador'" >
                    <b-container fluid > 
                        <b-row>
                            <b-form-group
                                label=""
                                label-for="filter-input"
                                label-cols-sm="3"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                                >
                                <b-input-group size="sm">
                                    <b-form-input
                                    id="filter-input"
                                    v-model="filterMarcacion"
                                    type="search"
                                    placeholder="Escribe para filtrar"
                                    ></b-form-input>
                                    <b-input-group-append>
                                    <b-button :disabled="!filter" @click="filter = ''" class="limpiar-bttn">Limpiar</b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </b-row>
                      
                        <b-table v-if="!cargando" hover small :items="marcaciones" :fields="fields_marcaciones" selectable responsive striped
                                caption-top :filter="filterMarcacion"  select-mode="single" label-sort-asc="" label-sort-desc="" 
                                label-sort-clear="" style="font-size:12px;" @row-dblclicked="agregarRemision"
                                 :tbody-tr-class="rowClass" table-class="custom-table"  thead-class="table-header"
                                 tbody-class="table-body">
                            <template #table-caption >
                                <b-row>
                                    <strong>Marcaciones totales: {{marcaciones.length}}</strong>
                                </b-row>
                            </template>
                            <template #cell(fecha_hora_asig)="data">
                                <span v-html="formatearFecha(data.value,5)"></span>
                            </template> 
                            <template #cell(fecha_hora_creacion)="data">
                                <span v-html="formatearFecha(data.value,5)"></span>
                            </template> 
                            <template #cell(fecha_hora_sp)="data">
                                <span v-if="!esFechaNula(data.value)" v-html="formatearFecha(data.value,5)"></span>
                                
                            </template> 
                            <template #cell(fecha_hora_lo)="data">
                                <span v-if="!esFechaNula(data.value)" v-html="formatearFecha(data.value,5)"></span>
                                <div v-else-if="tieneSalidaPlanta(data.item.fecha_hora_sp) && esAdministrador"  class="text-center">
                                    <b-icon class="hand" variant="warning" icon="calendar2-date" font-scale="2"></b-icon>
                                </div> 
                            </template>
                            <template #cell(fecha_hora_id)="data">
                                <span v-if="!esFechaNula(data.value)" v-html="formatearFecha(data.value,5)"></span>
                                <div v-else-if="tieneSalidaPlanta(data.item.fecha_hora_sp) && esAdministrador" class="text-center">
                                    <b-icon class="hand" variant="warning" icon="calendar2-date" font-scale="2" @click.stop="agregafecha(data.item,data.field)"></b-icon>
                                </div> 
                            </template>
                            <template #cell(fecha_hora_td)="data">
                                <span v-if="!esFechaNula(data.value)" v-html="formatearFecha(data.value,5)"></span>
                                <div v-else-if="tieneSalidaPlanta(data.item.fecha_hora_sp) && esAdministrador"  class="text-center">
                                    <b-icon class="hand" variant="warning" icon="calendar2-date" font-scale="2" @click.stop="agregafecha(data.item,data.field)"></b-icon>
                                </div> 
                            </template>
                            <template #cell(fecha_hora_so)="data">
                                <span v-if="!esFechaNula(data.value)" v-html="formatearFecha(data.value,5)"></span>
                                <div v-else-if="tieneSalidaPlanta(data.item.fecha_hora_sp) && esAdministrador"  class="text-center">
                                    <b-icon class="hand" variant="warning" icon="calendar2-date" font-scale="2" @click.stop="agregafecha(data.item,data.field)"></b-icon>
                                </div> 
                            </template>
                            <template #cell(fecha_hora_lp)="data">
                                <span v-if="!esFechaNula(data.value)" v-html="formatearFecha(data.value,5)"></span>
                                <div v-else-if="tieneSalidaPlanta(data.item.fecha_hora_sp) && esAdministrador"  class="text-center">
                                    <b-icon class="hand" variant="warning" icon="calendar2-date" font-scale="2" @click.stop="agregafecha(data.item,data.field)"></b-icon>
                                </div> 
                            </template>

                        </b-table>
                        <b-spinner v-else class="text-center" variant="warning" ></b-spinner>
                    </b-container>
                </b-tab>
                <b-tab  title="Barra de tiempo" >
                    <b-container fluid class="mt-2">
                        <div class="text-end me-4">
                            <b-form-checkbox
                                id="checkbox-1"
                                v-model="modoAutomatico"
                                plain
                                inline
                                class="pe-3"> 
                                    Refrescar Automáticamente
                            </b-form-checkbox>
                            <small v-if="isTimerActive" class="text-success ms-2 mb-2">
                                <i class="fas fa-sync fa-spin me-1"></i> <!-- Si usas FontAwesome -->
                                Actualizando cada minuto
                            </small>
                            
                            <!-- Mostrar última actualización -->
                            <small v-if="lastUpdate" class="text-muted ms-3">
                                {{ lastUpdateText }}
                            </small>
                        </div>
                        <TimelineProgress  />
                    </b-container>
                </b-tab>
            </b-tabs>
        </div>
      
        <b-modal
            id="modal-confirm"
            ref="modalConfirm"
            title="Confirmación"
            @ok="confirmarAccion"
            ok-title="Sí, continuar"
            cancel-title="Cancelar"
            ok-variant="danger"
            cancel-variant="secondary"
            hide-header-close
        >
            <p class="my-2">¿Estás seguro de que deseas eliminar las remisiones anteriores a hoy sin procesar?</p>
        </b-modal>
        
        <b-modal  ref="modal-fechas" hide-header-close size="md" scrollable button-size="sm"  
            :title="'Ingresa la fecha/hora de ' + columna"  @ok="ingresaRegistroMarcacion">
            <div  class="card shadow p-3 fs-12 text-center" style="height: 350px;"> 
                <b-row class="p-3 ">
                    <b-col sm="6" class="text-start">
                        <label class="label-col-blue negritas" for="">Fecha: </label>
                        <b-form-datepicker id="fecha_hora_evento" v-model="fecha_evento" 
                            class="shadow-sm border rounded custom-border px-2 py-2" ></b-form-datepicker>
                    </b-col>
                    <b-col sm="6" class="text-start">
                        <label class="label-col-blue negritas" for="">hora: </label>
                        <b-form-timepicker v-model="hora_evento"></b-form-timepicker>
                    </b-col>
                </b-row>
            </div>
            <template #modal-footer="{ ok, cancel }">
                
                <b-button class="hand" size="sm" variant="success" @click="ok()">
                    Guardar
                </b-button>
                <b-button class="hand" size="sm" variant="secondary" @click="cancel()">
                    Cancel
                </b-button>
            </template>
        </b-modal>
        <Modals ref="mod"></Modals>

    </div>
</template>
  
<script>
import Modals from '@/components/Modals.vue'
// import solicitudes from '@/catalogos/solicitudes.vue'
import {mapState,mapActions} from 'vuex'
import * as utils from "@/shared/util"
import TimelineProgress from '@/components/TimelineProgress.vue'

export default {
    data() {
        return {
            texto: 'Remisiones',
            tabselected:0,
            timer: null,
            isTimerActive: false,
            lastUpdate: null,
            updateInterval: 60000,
            currentPage:1,
            perPage:10,
            fields:[{key: 'solicitud_id', label: '#Sol', class:"negritas", sortable: true},
                    {key: 'pedido_id', label: 'Pedido_id',  class:"d-none"},
                    {key: 'remision_id', label: 'Remision_id', class:"d-none"},
                    {key: 'operador_asig_id', label: 'operador_asig_id', class:"d-none"},
                    {key: 'pedido', label: 'Pedido', sortable: true},
                    {key: 'folio', label: 'Remision', sortable: true, class:'negritas'},
                    {key: 'estatus', label: 'Estatus', sortable: true},
                    {key: 'fecha_hora_asig', label: 'Asignado', sortable: true},
                    {key: 'asignado', label: 'Operador', sortable: true, class:'negritas'},
                    {key: 'unidad', label: '# Eco', sortable: true, class:'negritas'},
                    {key: 'creo', label: 'Creo', sortable: true},
                    {key: 'fecha_hora_creacion', label: 'Creado', sortable: true},
                    {key: 'cliente', label: 'Cliente', sortable: true},
                    {key: 'obra', label: 'Obra', sortable: true},
                    {key: 'clave_producto', label: 'Clave', sortable: true},
                    {key: 'producto_id', label: 'Producto_id', sortable: true, class:'d-none'},
                    {key: 'tipo_producto', label: 'Tipo Prod.', sortable: true},
                    {key: 'metros_totales', label: 'M.Reales', sortable: true},
            ],
            fields_marcaciones:[
                {key: 'remision_id',label:'pedido_id',class:"d-none"},
                {key: 'app_id',label:'app_id',class:"d-none"},
                {key: 'folio',label:'Folio', sortable: true,class:"negritas"},
                {key: 'cliente',label:'Cliente', sortable: true},
                {key: 'operador_asig_id',label:'Operador', sortable: true, class:"d-none"},
                {key: 'operador',label:'Operador', sortable: true},
                {key: 'fecha_hora_asig',label:'Asignado', sortable: true},
                {key: 'fecha_hora_sp',label:'Inicio', sortable: true},
                {key: 'fecha_hora_lo',label:'Llegada', sortable: true},
                {key: 'fecha_hora_id',label:'Descarga', sortable: true},
                {key: 'fecha_hora_td',label:'Fin Descarga', sortable: true},
                {key: 'fecha_hora_so',label:'Salida', sortable: true},
                {key: 'fecha_hora_lp',label:'Retorno', sortable: true},
            ],
           
            marcaciones:[], //marcaciones 
            remisiones:[],
            filter:'',
            filterMarcacion:'',
            cargando:false,
            ffin:null,
            fini:null,
            incluirRemisionesAsignadas:true,
            modoAutomatico:false,
            fecha_evento:null,
            hora_evento:null,
            field_tipo_evento:null,
            registro_marcacion:null,
            columna:'',

        }
    },
    components: {
        Modals,
        TimelineProgress,
        // Operadores,
    },
   
    computed: {
        ...mapState(['operador','modal','menu']),
        rows() {
            return this.remisiones.length
        },
        ocultarnuevo(){
            // if(this.tabselected==0 ){
            //     return true
            // }else{
                return false
            // }
        },
        bloquearAgregar(){
            if(this.folio == null || this.operador_aisg_id == null){
                return true
            }
            return false
        },
        lastUpdateText() {
            if (!this.lastUpdate) return '';
            return `Última actualización: ${utils.getDateTime(this.lastUpdate,4)}`;
        },
        detenerTimer() {
            return this.$store.getters.getdetenerTimer; 
        },
        esVendedor(){
            if(this.operador.perfil=='Vendedor'){
                return true
            }
            return false
        },
        esAdministrador(){
            if(this.operador.perfil=='Administrador'){
                return true
            }
            return false
        } 
       
    },
    watch: {
        modoAutomatico(newValue) {
            if (newValue) {
                this.modoAutomatico = true;
                this.startTimer();
            } else {
                this.modoAutomatico = false;
                this.stopTimer();
            }
        }
    },
    created () {
        let f=Date();
         this.ffin= utils.getDateTime(f)
         this.fini=this.ffin
        // console.warn("la ruta: " + this.$route.name)
        this.getRemisiones()

        this.routeWatcher = this.$watch(
            () => this.$route.path,
            () => {
                this.stopTimer();
            }
        );
        if(this.esVendedor){
            this.handleTabChange(2)
        }
    },
    beforeRouteLeave(to, from, next) {
        this.cleanupComponent();
        next();
    },

    beforeUnmount() {
        this.cleanupComponent();
    },
    methods: {
        ...mapActions(['actupdateMenu','actupdateModal']),
        showmodal(tittle,message){
            this.modal.tittle = tittle
            this.modal.message = message
            this.modal.type = 0
            this.actupdateModal(this.modal)
            // console.log("ahora contiene:  " + JSON.stringify(this.modal))
            this.$refs.mod.showmodal()
        },
        getRemisiones() {
//             console.log("el filtro de todos.:" + this.incluirRemisionesAsignadas)
// return 
            this.cargando=true
            let val={
                fini:this.fini,
                ffin:this.ffin,
                incluirRemisionesAsignadas: this.incluirRemisionesAsignadas
            }
            this.$store.dispatch("getRemisiones",val).then((resp)=>{
                this.marcaciones=[]
                // console.warn("La respuesta de remisiones: " + JSON.stringify(resp))
                if(resp.estatus == 1){
                    // this.operadores=resp.data
                    this.remisiones=resp.remisiones
                    //ahora recorremos para verificar si hay eventos de marcacion por cada remision
                    this.remisiones.forEach(remision => {
                        console.info("remision: " + JSON.stringify(remision.eventos))
                        if(remision.eventos.length > 0){
                            let marcacion={
                                    remision_id:remision.remision_id,
                                    folio:remision.folio,
                                    cliente:remision.cliente,
                                    operador_asig_id:remision.operador_asig_id,
                                    operador:remision.asignado,
                                    fecha_hora_asig:remision.fecha_hora_asig,
                                    fecha_hora_sp:null,
                                    fecha_hora_lo:null,
                                    fecha_hora_id:null,
                                    fecha_hora_td:null,
                                    fecha_hora_so:null,
                                    fecha_hora_lp:null
                                }
                            remision.eventos.forEach(evento => {
                                if(evento.evento_id == 1){
                                    marcacion.fecha_hora_sp=evento.fecha_hora_evento
                                }else if(evento.evento_id == 2){
                                    marcacion.fecha_hora_lo=evento.fecha_hora_evento
                                }else if(evento.evento_id == 3){
                                    marcacion.fecha_hora_id=evento.fecha_hora_evento
                                }else if(evento.evento_id == 4){
                                    marcacion.fecha_hora_td=evento.fecha_hora_evento
                                }else if(evento.evento_id == 5){
                                    marcacion.fecha_hora_so=evento.fecha_hora_evento
                                }else if(evento.evento_id == 6){
                                    marcacion.fecha_hora_lp=evento.fecha_hora_evento
                                }
                            })
                            // console.warn("Lo que se pushea de marcacion es: " +JSON.stringify(marcacion))
                            this.marcaciones.push(marcacion)
                        }else{
                            // quiere decri que no tiene marcaciones asi que le anexamos lo default..
                            let marcacion={
                                    remision_id:remision.remision_id,
                                    folio:remision.folio,
                                    cliente:remision.cliente,
                                    operador_asig_id:remision.operador_asig_id,
                                    operador:remision.asignado,
                                    fecha_hora_asig:remision.fecha_hora_asig,
                                    fecha_hora_sp:null,
                                    fecha_hora_lo:null,
                                    fecha_hora_id:null,
                                    fecha_hora_td:null,
                                    fecha_hora_so:null,
                                    fecha_hora_lp:null
                            }
                            this.marcaciones.push(marcacion)
                        }
                    })
                    
                    // console.log("las marcaciones son: " + JSON.stringify(this.marcaciones))
                    // this.cat_operadores=resp.operadores
                    this.cargando=false
                }else{
                    this.showmodal(this.texto, resp.message)
                    this.cargando=false
                }
                this.$store.dispatch("actupdateMarcaciones",this.marcaciones)
            }).catch((err)=>{
                this.showmodal(this.texto, err)
                this.cargando=false
            })
        },
        async getRemisionesAuto(){
             //console.warn("1.00")
            let f=Date();
            let fechaDeHoy=  utils.getDateTime(f)
            let val={
                fini:fechaDeHoy,
                ffin:fechaDeHoy,
                incluirRemisionesAsignadas: false
            }
           // console.warn("2.00")
            let resp=await this.$store.dispatch("getRemisiones",val)
           // console.warn("3.00")
            this.marcaciones=[]
           // console.warn("La respuesta de remisiones: " + JSON.stringify(resp.remisiones[0].eventos))
           // console.log("Nuemrero de eventos directos del getRemisiones: " + resp.remisiones[0].eventos.length)
            if(resp.estatus == 1){
                //console.warn("4.00")
                // this.operadores=resp.data
                // this.remisiones=resp.remisiones
                //ahora recorremos para verificar si hay eventos de marcacion por cada remision
                let contador=0;
                resp.remisiones.forEach( remi  => { 
                    // console.info("remi: " + JSON.stringify(remi.eventos))
                    contador=contador+1
                    //console.warn("-5."+contador)
                    if(remi.eventos.length > 0){
                        //console.warn("-5.1."+contador)
                        let marcacion={
                                remision_id:remi.remision_id,
                                folio:remi.folio,
                                cliente:remi.cliente,
                                operador_asig_id:remi.operador_asig_id,
                                operador:remi.asignado,
                                fecha_hora_asig:remi.fecha_hora_asig,
                                fecha_hora_sp:null,
                                fecha_hora_lo:null,
                                fecha_hora_id:null,
                                fecha_hora_td:null,
                                fecha_hora_so:null,
                                fecha_hora_lp:null
                            }
                        let subCont =0;
                        //console.log("Eventos por recorrer: "+remi.eventos.length)
                        remi.eventos.forEach(evento => { 
                            subCont=subCont+1
                            //console.warn("-5.2."+subCont)
                            if(evento.evento_id == 1){
                                marcacion.fecha_hora_sp=evento.fecha_hora_evento
                            }else if(evento.evento_id == 2){
                                marcacion.fecha_hora_lo=evento.fecha_hora_evento
                            }else if(evento.evento_id == 3){
                                marcacion.fecha_hora_id=evento.fecha_hora_evento
                            }else if(evento.evento_id == 4){
                                marcacion.fecha_hora_td=evento.fecha_hora_evento
                            }else if(evento.evento_id == 5){
                                marcacion.fecha_hora_so=evento.fecha_hora_evento
                            }else if(evento.evento_id == 6){
                                marcacion.fecha_hora_lp=evento.fecha_hora_evento
                            }
                        })
                        // console.warn("Lo que se pushea de marcacion es: " +JSON.stringify(marcacion))
                        this.marcaciones.push(marcacion)
                        //console.warn("-5.3")
                        //console.warn("5.3.1 enviando marcaciones: " + JSON.stringify(this.marcaciones))
                        this.$store.dispatch("actupdateMarcaciones",this.marcaciones)
                       // console.warn("-6")
                    }else{
                        //console.warn("7")
                        // quiere decri que no tiene marcaciones asi que le anexamos lo default..
                        let marcacion={
                                remision_id:remi.remision_id,
                                folio:remi.folio,
                                cliente:remi.cliente,
                                operador_asig_id:remi.operador_asig_id,
                                operador:remi.asignado,
                                fecha_hora_asig:remi.fecha_hora_asig,
                                fecha_hora_sp:null,
                                fecha_hora_lo:null,
                                fecha_hora_id:null,
                                fecha_hora_td:null,
                                fecha_hora_so:null,
                                fecha_hora_lp:null
                        }
                       // console.warn("8")
                        this.marcaciones.push(marcacion)
                        //console.warn("8.1 enviando marcaciones: " + JSON.stringify(this.marcaciones))
                        this.$store.dispatch("actupdateMarcaciones",this.marcaciones)
                       // console.warn("9")

                    }
                })
                //console.warn("10")
                // console.warn("Obteniendo maracaiones automaticas")
                // this.cat_operadores=resp.operadores
                // this.cargando=false
            }else{
               // console.warn("11")
                this.showmodal(this.texto, resp.message)
                // this.cargando=false
                //console.warn("11.1 enviando marcaciones: " + JSON.stringify(this.marcaciones))
                this.$store.dispatch("actupdateMarcaciones",this.marcaciones)
            }
            // console.log("Enviando actualizar marcaciones: " + JSON.stringify(this.marcaciones))
            //console.warn("12")
            // }).catch((err)=>{
            //     this.showmodal(this.texto, err)
            //     // this.cargando=false
            // })
        },
        handleTabChange(tabIndex) {
            this.tabselected = tabIndex;
                 // Si estamos en la tab que necesita el timer (por ejemplo, tab 0)
            if (tabIndex === 2) {
                // console.log("Iniciando el timer")
                this.$store.dispatch("actupdateDetenerTimer",false);
                this.modoAutomatico = true;
                // this.startTimer();
            } else {
                this.modoAutomatico = false;
                // console.log("Deteniendo el timer")
                // this.stopTimer();
            }
        },
        startTimer() {
            if (!this.isTimerActive) {
                if(this.detenerTimer){
                    this.stopTimer();
                }else{
                    this.isTimerActive = true;
                    // Ejecutar inmediatamente
                    this.executeUpdate();
                    
                    this.timer = setInterval(() => {
                        this.executeUpdate();
                    }, this.updateInterval);
                }
            }
        },
        stopTimer() {
            // console.log("DETENIENDO EL TIEMPO")
            if (this.timer) {
                clearInterval(this.timer);
                this.timer = null;
                this.isTimerActive = false;
            }
        },
        cleanupComponent() {
            this.stopTimer();
            this.modoAutomatico = false;
        },
        async executeUpdate() {
            try {
                await this.getRemisionesAuto();
                this.lastUpdate = new Date();
            } catch (error) {
                console.error('Error en la actualización automática:', error);
            }
        },
        // beforeDestroy() {
        //     this.stopTimer();
        // },    
        rowClass(item) {
            // Verifica si el estatus es 'CANCELADO' y aplica una clase específica
            // console.log("los items son: " + item.estatus)
            return item.estatus === 'CANCELADO' ? 'row-cancelado' : '';
        },
        formatearFecha(fecha,formato=0) {
            //console.log("formateando fecha: " + fecha + "formato: " + formato)
            let fechaformateada=utils.getDateTime(fecha,formato)
           // console.log("formateando fecha: " + fechaformateada)
            return fechaformateada
        },
        formatearFechaHora(fecha,formato){
            let fechaActual = new Date();
            let fechaActualString = fechaActual.toISOString().split('T')[0]; // Obtiene la fecha en formato 'YYYY-MM-DD'
            //console.log("la fecha actual es: " + fechaActualString)
            // Concatenar la fecha actual con la hora de solicitud
            let fechaHoraConcatenada = `${fechaActualString}T${fecha}`;
            //console.log("la fecha y hora concatenada es: " + fechaHoraConcatenada)
            // Crear un nuevo objeto Date con la fecha y hora concatenadas
            let fechaConHora = new Date(fechaHoraConcatenada);
            let fechaformateada=utils.getDateTime(fechaConHora,formato)
            return fechaformateada
        },
        setFocusOnInput(){
            this.$refs.inputFolio.focus()
        },
        agregarRemision(item){
            // console.log("El item seleccionado: " + JSON.stringify(item))
            // this.remisiones=item.remisiones
            // this.pedSelected=item
            // this.abrirModales("modal-remisiones")    
        },


        guardarRemision(fol,op_asig_id){
            this.cargando=true
            // console.log("guardando la remision con el folio: " + fol + " y el op_asig_id: " + op_asig_id)
            if(fol!="" && op_asig_id!=""){
                //buscamos el operador asignado en $operdores
                let obj_op=this.cat_operadores.find((obj)=>{return obj.value==op_asig_id})
                // console.log("EL obj_op: " + JSON.stringify(obj_op))
                let obj_operador={operador_id:  obj_op.value, 
                                  nombre:       obj_op.text}
                //lanzamos el dispatch para guardar un pedido...
                // console.log("guardando el folio " + fol)
                let remision={remision_id:null,
                            pedido_id:this.pedSelected.pedido_id,
                            folio:fol,
                            fecha_hora_creacion: utils.getDateTime(new Date(),0),
                            estatus:'ASIGNADO',
                            operador_id_creo:this.operador.operador_id,
                            operador_asig_id: op_asig_id,
                            fecha_hora_asig: utils.getDateTime(new Date(),0),
                            operador_asignado: obj_operador
                }
                // this.folio=null
                // this.operador_aisg_id=null
                // this.cargando=false
                // return 
                // console.log("la remision a guardar es: " + JSON.stringify(remision))
                this.$store.dispatch("createRemision",remision).then((resp)=>{
                    // console.log("el response de createRemision es: " + JSON.stringify(resp))
                    if(resp.estatus==1){
                        resp.remision.operador_asignado=obj_operador
                        this.remisiones.push(resp.remision)
                        this.folio=null
                        this.operador_aisg_id=null
                        this.cargando=false
                    }else{
                        this.cargando=false
                        this.showmodal(this.texto,resp.message)
                    }
                }).catch((err)=>{
                    this.showmodal(this.texto,err)
                    this.cargando=false
                })
            }
        },
        eliminarRemision(item){
            // console.log("El item seleccionado para eliminar: " + JSON.stringify(item))
            //lanzamos el dispatch para eliminar un pedido...
            if(item){
                this.$store.dispatch("deletePedido",item.remision_id).then((resp)=>{
                    // console.log("el response de deletePedido es: " + JSON.stringify(resp))
                    if(resp.estatus==1){
                        // this.remisiones=this.remisiones.filter(x=>x.pedido_id!=item.pedido_id)
                        const index = this.remisiones.findIndex(remision => remision.remision_id === item.remision_id);
                        if (index !== -1) {
                            this.remisiones.splice(index, 1);
                        }
                    }else{
                        this.showmodal(this.texto,resp.message)
                    }
                }).catch((err)=>{
                    this.showmodal(this.texto,err)
                })
            }

        },
        esFechaNula(fecha){
            if(fecha==null || fecha==""){
                return true
            }
            return false
        },
       
        tieneSalidaPlanta(fecha){
            if(fecha!=null && fecha!=""){
                return true
            }
            return false
        },
        agregafecha(item,column){
            // console.log("El item seleccionado para agregar fecha: " + JSON.stringify(item))
            // console.log("La columna a la que se le dio click es: " + JSON.stringify(column))
            this.field_tipo_evento=column
            this.columna=this.field_tipo_evento.label
            this.registro_marcacion=item
            this.$refs['modal-fechas'].show()
        },
        ingresaRegistroMarcacion(){
            // console.log("guardando...")
            // fecha_evento:null,
            // hora_evento:null,
            
            // console.log("la fecha a guardar es: " + this.fecha_evento)
            // console.log("la hora a guardar es: " + this.hora_evento)
            // console.log("El tipo evento es: " + JSON.stringify(this.field_tipo_evento))
            //validamos que fehca_evento y hora_evento no sean nulas
            if(this.esFechaNula(this.fecha_evento) || this.esFechaNula(this.hora_evento)){
                this.showmodal(this.texto,"Debe ingresar la fecha y la hora del evento")
                return
            }
            //obtenemos el evento
            let evento_id=0
            if(this.field_tipo_evento.key=="fecha_hora_lo"){
                evento_id=2
            }else if(this.field_tipo_evento.key=="fecha_hora_id"){
                evento_id=3
            }else if(this.field_tipo_evento.key=="fecha_hora_td"){
                evento_id=4
            }else if(this.field_tipo_evento.key=="fecha_hora_so"){
                evento_id=5
            }else if(this.field_tipo_evento.key=="fecha_hora_lp"){
                evento_id=6
            }
            //armamos el objeto a guardar...
            let obj ={
                registro_id:    0,
                remision_id:    this.registro_marcacion.remision_id,
                evento_id:      evento_id,
                operador_id:    this.registro_marcacion.operador_asig_id,
                lat:            '00.000000',
                lng:            '-00.000000',
                observacion:    'Marcación ingreasada por el administrador',
                fecha_hora_evento: this.fecha_evento + " " + this.hora_evento,
                fecha_hora_sinc: utils.getDateTime(new Date(),3),
                app_id:         0
            }
            console.log("El obj a guardar es: " + JSON.stringify(obj))
            
            //enviamos el evento a guardar...
            this.$store.dispatch("set_Evento",obj).then((resp)=>{
                // console.log("el response de set_Evento es: " + JSON.stringify(resp))
                if(resp.estatus==1){
                    let indx=this.marcaciones.findIndex(marcacion => marcacion.remision_id === this.registro_marcacion.remision_id)
                    // console.log("El indice de la remision es: " + indx)
                    if(indx>=0){
                        if(this.field_tipo_evento.key=="fecha_hora_lo"){
                            this.marcaciones[indx].fecha_hora_lo = obj.fecha_hora_evento
                        }else if(this.field_tipo_evento.key=="fecha_hora_id"){
                            this.marcaciones[indx].fecha_hora_id = obj.fecha_hora_evento
                        }else if(this.field_tipo_evento.key=="fecha_hora_td"){
                            this.marcaciones[indx].fecha_hora_td = obj.fecha_hora_evento
                        }else if(this.field_tipo_evento.key=="fecha_hora_so"){
                            this.marcaciones[indx].fecha_hora_so = obj.fecha_hora_evento
                        }else if(this.field_tipo_evento.key=="fecha_hora_lp"){
                            this.marcaciones[indx].fecha_hora_lp = obj.fecha_hora_evento
                        }
                    }
                    
                    this.showmodal(this.texto,resp.message)
                    
                    this.$refs['modal-fechas'].hide()
                    this.field_tipo_evento=null
                    this.registro_marcacion=null
                    this.fecha_evento=null
                    this.hora_evento=null
                }else{
                    this.showmodal(this.texto,resp.message)
                }
            }).catch((err)=>{
                this.showmodal(this.texto,err)
            })

        },
        cerrarModal(){
            //this.$refs['modal-remisiones'].close()
            //this.obtenerSolicitudes()
        },
     
        imprimir(){
            utils.exportar(this.remisiones,'remisiones del ' + this.fini +' al ' + this.ffin)
        },
      
        abrirModales(id){
        //   console.log("El id del modal a mosrar :" + id )
          this.$refs[id].show()
        },
        borrarRemisiones(){
            this.$refs.modalConfirm.show();
        },
        confirmarAccion() {
            // console.log("Acción confirmada");
            // Agregar lógica para la acción
            this.$store.dispatch("deleteRemisionesSinProcesar").then((resp)=>{
                // console.log("el response de deleteRemisionesSinProcesar es: " + JSON.stringify(resp))
                if(resp.estatus==1){
                    this.getRemisiones()
                }else{
                    this.showmodal(this.texto,resp.message)
                }
            })
        },
    },
}
</script>

<style>

</style>