// api.js

const state = {
    // State específico del módulo API
  };
  
  const mutations = {
    // Mutations específicas del módulo API
  };
  
  const actions = {
    // Actions específicas del módulo API
    getPedidos({commit,state},val) {
      return new Promise((resolve, reject) => {
      fetch(this.state.app.config.apiURL+'vista_pedidos',
          {
            method: "POST",
            headers: {
                'Authorization': 'Basic ' + btoa(this.state.operador.operador + ':' + this.state.operador.clave), // Aquí incluye las credenciales codificadas en base64
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                fecha_ini      : val.fini,
                fecha_fin      : val.ffin,
                incluirSinRemision : val.incluirSinRemision,
            })
          })
          .then((response) => response.json())
          .then((r) => {
              resolve(r);
          })
          .catch((error) => {
              reject(error);
          });
      },
      error=>{
          reject(error);
      })
    },
   
    createPedido({commit,state},val) {
      return new Promise((resolve, reject) => {
      fetch(this.state.app.config.apiURL+'pedidos',
          {
              method: "POST",
                headers: {
                'Authorization': 'Basic ' + btoa(this.state.operador.operador + ':' + this.state.operador.clave), // Aquí incluye las credenciales codificadas en base64
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                pedido     : val,
              })
          })
          .then((response) => response.json())
          .then((r) => {
              resolve(r);
          })
          .catch((error) => {
              reject(error);
          });
      },
      error=>{
          reject(error);
      })
    },


    deletePedido({commit,state},pedido_id) {
        return new Promise((resolve, reject) => {
        fetch(this.state.app.config.apiURL+'pedidos/'+ pedido_id,
            {
                method: "DELETE",
                  headers: {
                  'Authorization': 'Basic ' + btoa(this.state.operador.operador + ':' + this.state.operador.clave), // Aquí incluye las credenciales codificadas en base64
                  'Content-Type': 'application/json'
                },
                // body: JSON.stringify({
                //   suministro     : val,
                // })
            })
            .then((response) => response.json())
            .then((r) => {
                resolve(r);
            })
            .catch((error) => {
                reject(error);
            });
        },
        error=>{
            reject(error);
        })
    },

    updatePedido({commit,state},val) {
      return new Promise((resolve, reject) => {
      fetch(this.state.app.config.apiURL+'pedidos/'+ val.pedido_id,
          {
              method: "PUT",
                headers: {
                'Authorization': 'Basic ' + btoa(this.state.operador.operador + ':' + this.state.operador.clave), // Aquí incluye las credenciales codificadas en base64
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                pedido     : val,
              })
          })
          .then((response) => response.json())
          .then((r) => {
              resolve(r);
          })
          .catch((error) => {
              reject(error);
          });
      },
      error=>{
          reject(error);
      })
    },
    
    
  };
  
  const getters = {
    // Getters específicas del módulo API
  };
  
  export default {
    state,
    mutations,
    actions,
    getters
  };