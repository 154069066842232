<template>
    <b-container class="px-3 justify-content-center  height-fixed margin2sidebar font-opensans">
      <b-row>
        <b-col cols="10" sm="11" md="11" lg="11" xl="11">
          <h2><b-icon icon="people" ></b-icon> Nuevo Operador</h2>
        </b-col>
        <b-col cols="2" sm="1" md="1" lg="1" xl="1">
          <b-button variant="primary" class="shadow-sm back-bttn bold-text px-3 py-2 mb-3 mt-2" @click="goBack()"><b-icon icon="arrow-left"></b-icon></b-button>
        </b-col>
        
        
        <b-form @submit.prevent="submitForm" class="shadow p-4 rounded form-background">
          <b-row>
            <b-col>
              <b-form-group label="Operador:" label-for="operador" class="pt-2 pb-3" >
                <b-form-input
                  id="operador"
                  v-model="form.operador"
                  type="text"
                  maxlength="20"
                  required
                  class="mt-1 shadow-sm"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group label="Nombre:" label-for="nombre" class="pt-2 pb-3" >
                <b-form-input
                  id="nombre"
                  v-model="form.nombre"
                  type="text"
                  maxlength="150"
                  required
                  class="mt-1 shadow-sm"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group label="Email:" label-for="email" class="pb-3">
                <b-form-input
                  id="email"
                  v-model="form.email"
                  type="email"
                  maxlength="150"
                  required
                  class="mt-1 shadow-sm"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col>
               <b-form-group label="Clave:" label-for="clave" class="pb-3" >
                <b-form-input
                  id="clave"
                  v-model="form.clave"
                  type="password"
                  maxlength="15"
                  placeholder="Máximo 15 caracteres"
                  required
                  class="mt-1 shadow-sm"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col >
              <b-form-group label="Id del dispositivo:" label-for="imei" class="pt-2 pb-3" >
                  <b-form-input
                    id="imei"
                    v-model="form.imei"
                    type="text"
                    maxlength="30"
                    class="mt-1 shadow-sm"
                  ></b-form-input>
                </b-form-group>
            </b-col>  
          </b-row>

          <b-row>
            <b-col class="cols-4" xs="12" s="6" md="6" lg="4" xl="2">
                <b-form-group label="Perfil:" label-for="perfil" class="pb-3">
                <b-form-select id="perfil" v-model="form.perfil" required class="shadow-sm border rounded custom-border px-2 py-1 mt-1">
                  <b-form-select-option value="Administrador"  >Administrador</b-form-select-option>
                  <b-form-select-option value="Vendedor">Vendedor</b-form-select-option>
                  <b-form-select-option value="Operador">Operador</b-form-select-option>
                  <b-form-select-option value="Dosificador">Dosificador</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>

            <b-col class="cols-4" xs="12" s="6" md="6" lg="8" xl="10">
              <b-form-group label="Estatus:" label-for="estatus" class="pb-3">
                <b-form-select id="estatus" :disabled="nuevo" v-model="form.estatus" required class="shadow-sm border rounded custom-border px-2 py-1 mt-1">
                  <b-form-select-option value="A">Activo</b-form-select-option>
                  <b-form-select-option value="B">Baja</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>

           


          </b-row>
         
          
    
          <b-form-group label="Fecha de Creación:" label-for="fecha_creacion" class="mb-3">
            <b-form-datepicker
              id="fecha_creacion"
              v-model="form.fecha_creacion"
              disabled
              :state="true"
              class="mt-1 shadow-sm"
            ></b-form-datepicker>
          </b-form-group>
    
          <b-form-group label="Fecha de Modificación:" label-for="fecha_modificacion" class="pb-3">
            <b-form-datepicker
              id="fecha_modificacion"
              v-model="form.fecha_modificacion"
              disabled
              :state="true"
            ></b-form-datepicker>
          </b-form-group>
    
          <b-form-group v-if="!nuevo && form.estatus=='B'" label="Fecha de Baja:" label-for="fecha_baja">
            <b-form-datepicker
              id="fecha_baja"
              v-model="form.fecha_baja"
              :state="true"
              class="mt-1 shadow-sm"
            ></b-form-datepicker>
          </b-form-group>
          <b-button variant="primary" class="shadow-sm back-bttn bold-text px-3 py-1 mb-3 mt-2" @click="goBack()">Regresar</b-button>
          <b-button v-if="!cargando" type="submit" variant="primary" class="shadow-sm save-bttn bold-text px-4 py-1 mb-3 mt-2 mx-3">Guardar</b-button>
          <b-spinner v-else class="text-center" variant="warning" ></b-spinner>
        </b-form>
      </b-row>
      
  
        
      <Modals ref="mod"></Modals>
        
    </b-container>
  </template>
  
  <script>
  import Modals from '@/components/Modals.vue'
  import {mapState,mapActions} from 'vuex'
  import * as utils from "@/shared/util"
  
  export default {
    data() {
      return {
        texto: "Operador",
        cargando:false,
        form: {
          operador_id:null,
          nombre: '',
          email: '',
          clave: '',
          perfil: 'Administrador',
          estatus: 'A',
          fecha_creacion: '',
          fecha_modificacion: '',
          fecha_baja: null
        },
        nuevo:true
      };
    },
    components: {
        Modals,
        // Operadores,
    },
    computed: {
      ...mapState(['operador','modal','menu']),
      // name() {
      //   return this.data 
      // }
    },
    // mounted() {
    //   this.setFechaActual();
    // }
    created() {
      let f=Date();
      this.form.fecha_creacion= utils.getDateTime(f)
      this.form.fecha_modificacion=utils.getDateTime(f)
     //console.log("el parametro enviado es: " , this.$route.params); // Accede al valor '123'
      if(this.$route.params.operador_id != undefined){

        this.form=this.$route.params
        this.nuevo=false
      }
      
      // this.form.estatus=1
     //console.log("Es nuevo :" + this.nuevo)
    },
    methods: {
      ...mapActions(['actupdateMenu','actupdateModal']),
      showmodal(tittle,message){
          this.modal.tittle = tittle
          this.modal.message = message
          this.modal.type = 0
          this.actupdateModal(this.modal)
         //console.log("ahora contiene:  " + JSON.stringify(this.modal))
          this.$refs.mod.showmodal()
      },
      submitForm() {
        // Aquí puedes manejar el envío del formulario, como enviar los datos a un servidor
        // console.log('Formulario enviado:', this.form);
        this.cargando=true
        if(this.validaOperador()){
          this.cargando=false
          // return ""
          if(this.nuevo){
           //console.warn('enviando :' + JSON.stringify(this.form));
            this.$store.dispatch("createOperador",this.form).then((res)=>{
             //console.log("dispatch createOperador trajo: "+JSON.stringify(res))
              if(res.estatus==1){
                this.goBack()
              }else{
                this.showmodal(this.texto, res.message)
              }
            }).catch((error)=>{
              this.showmodal(this.texto, error)
              this.cargando=false
            })
          }else{
           //console.warn('enviando :' + JSON.stringify(this.form));
            this.$store.dispatch("updateOperador",this.form).then((res)=>{
             //console.log("dispatch updateOperador trajo: "+JSON.stringify(res))
              if(res.estatus==1){
                this.goBack()
              }else{
                this.showmodal(this.texto, res.message)
              }
            }).catch((error)=>{
              this.showmodal(this.texto, error)
              this.cargando=false
            })
          }
        }
        this.cargando=false
      },
      validaOperador(){
       //console.log("en trando a validar...")
        // this.showmodal(this.texto,"Solo se permiten letras y números, y debe tener al menos 8 caracteres.")
        const regex = /^[a-zA-Z0-9]{8,}$/;
        if(this.form.operador ==""){
          this.showmodal(this.texto,"Es necesario ingresar una clave de operador")
          return false
        }else if(this.form.nombre == ""){
          this.showmodal(this.texto, "Es necesario ingresar el nombre del operador")
          return false
        }else if(this.form.email ==""){
          this.showmodal(this.texto,"Es necesario ingresar una dirección de correo electrónico para el operador")
          return false
        }else if (!regex.test(this.form.clave)) {
          this.showmodal(this.texto,"Solo se permiten letras y números, y debe tener al menos 8 caracteres.")
          return false
        }else if(this.form.estatus=='B' && this.form.fecha_baja ==null){
          this.showmodal(this.texto,"Es necesario ingresar una fecha de baja")
          return false
        }
        return true
      },
      // setFechaActual() {
      //   const ahora = new Date().toISOString().slice(0, 16);
      //   this.form.fecha_creacion = ahora;
      //   this.form.fecha_modificacion = ahora;
      // },
      goBack(){
        this.$router.go(-1)
      },
    },
    
  };
  </script>
  
  <style scoped>
  .operador-form {
    max-width: 600px;
    margin: auto;
  }
  </style>
  