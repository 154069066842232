<template>
    <div id="mod" class="font-opensans">
        <div>
            <b-modal id="modal-center" centered ok-only button-size="sm" hide-header-close no-close-on-backdrop :title="propiedades.tittle">
                <p class="my-4">{{propiedades.message}}</p>
            </b-modal>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    computed: {
        ...mapState(['modal']),
    },
    data() {
        return {
            propiedades:{tittle:'',message:'',type:0}
            
        }
    },
    mounted () {
        //console.log("--------------EL mounted del MODAL----------------")
        this.propiedades=this.modal;
    },
    methods: {
        showmodal(){
            // this.tittleModal=this.modal.tittle
            // this.messageModal=this.modal.message
            this.$bvModal.show("modal-center");
        },
    },
}
</script>

<style>

</style>