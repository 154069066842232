<template>
    <div class=" my-text height-fixed font-opensans">
      <!-- <h3 class="warning">{{texto}}</h3> -->
        <div class="card shadow p-2">
            <div class=" fluid mb-3">
                <div class="row m-2  p-2 rounded vOperadores-custom"> 
                    <b-col sm="8" class="text-start">
                        <h5 class="fcwhite pt-1"><b-icon  icon="file-earmark-text" font-scale="1" ></b-icon><strong> Vista de Solicitudes</strong></h5>
                    </b-col>
                   
                    <b-col sm="2" class="text-end">
                        <b-button v-if="!cargando" :hidden="ocultarnuevo" class="btn-sm p-2 vOperadores_vProductos-bttn" variant="outline-warning" @click="imprimir()"><b-icon icon="plus-circle" font-scale="1"></b-icon> Exportar</b-button>
                        <b-spinner v-else class="text-center" variant="warning" ></b-spinner>
                    </b-col>
                    <b-col sm="2" class="text-end">
                        <b-button v-if="!cargando" :hidden="ocultarnuevo" class="btn-sm p-2 vOperadores_vProductos-bttn" variant="outline-warning" @click="nuevaSolicitud()"><b-icon icon="plus-circle" font-scale="1"></b-icon> Nuevo</b-button>
                        <b-spinner v-else class="text-center" variant="warning" ></b-spinner>
                    </b-col>
                </div>
               
                <b-row> 
                    <b-col md="6" class="py-1">
                        <label class="fs-14 ps-2" for="example-datepicker">Fechas a elegir</label>
                    </b-col>
                    <b-col md="6" class="text-end fs-14  py-2">
                        <b-form-checkbox
                            id="checkbox-1"
                            v-model="usarFechasEnFicheros"
                            plain
                            inline
                            class="pe-3"> 
                            Aplicar filtro de fechas en todos los ficheros
                        </b-form-checkbox>
                    </b-col>
                    <!-- <b-form-checkbox-group v-model="usarFechasEnFicheros" plain inline text="Aplicar filtro de fechas en todos los ficheros"/> -->
                </b-row>   
                <b-row > 
                    <b-col md="5">
                        <div class="container">
                            <b-form-datepicker id="example-datepicker1" v-model="fini" class="mb-2"></b-form-datepicker>
                            <!-- <p class="fs-10 fcgreen">Fecha inicial: '{{ fini }}'</p> -->
                        </div> 
                    </b-col>   
                    <b-col md="5">
                        <div class="container">
                            <b-form-datepicker id="example-datepicker2" v-model="ffin" class="mb-2"></b-form-datepicker>
                            <!-- <p class="fs-10 fcgreen">Fecha final: '{{ ffin }}'</p> -->
                        </div>
                    </b-col> 
                    <b-col>  
                        <b-button v-if="!cargando" class="btn-md py-2 px-3 consultar-bttn" variant="warning" @click="getSolicitudes()"><b-icon icon="zoom-in" font-scale="1"></b-icon> Consultar</b-button>
                        <b-spinner v-else variant="warning" ></b-spinner>
                    </b-col>
                </b-row>   
               
            </div>
        </div>   
        <div class="card shadow mt-4 p-2" >
            <b-tabs  v-model="tabselected">
                <b-tab  :title="'Todas (' + solicitudes.length + ')'" active >
                    <b-container fluid  v-if="solicitudes.length > 0">
                        <b-row>
                            <b-form-group
                                label=""
                                label-for="filter-input"
                                label-cols-sm="3"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                                >
                                <b-input-group size="sm">
                                    <b-form-input
                                    id="filter-input"
                                    v-model="filter"
                                    type="search"
                                    placeholder="Escribe para filtrar"
                                    ></b-form-input>
            
                                    <b-input-group-append>
                                    <b-button :disabled="!filter" @click="filter = ''" class="limpiar-bttn">Limpiar</b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </b-row>
                        <!-- <b-pagination
                            v-model="currentPage"
                            :total-rows="rows"
                            :per-page="perPage"
                            aria-controls="my-table"
                            size="sm"
                            pills 
                            align="center"
                            class="mt-2"
                        ></b-pagination> -->
                        <b-table v-if="!cargando" hover small :items="solicitudes" :fields="fields" selectable responsive striped
                                caption-top :filter="filter"  select-mode="single"  label-sort-asc="" label-sort-desc="" label-sort-clear="" style="font-size:12px;"
                                @row-dblclicked="onRowDblClick" class="font-opensans" table-class="custom-table"  thead-class="table-header"
                                tbody-class="table-body">
                            <template #table-caption >
                                <b-row>
                                    <strong>Solicitudes totales: {{solicitudes.length}}</strong>
                                </b-row>
                                <b-row class="mt-1">
                                    <b-col md="2" class="text-start">
                                        <b-row>
                                            <strong class="label-col-blue">M. Solicitados: {{calculaMetros('todos',solicitudes)}} m<sup>3</sup></strong>
                                        </b-row>
                                     
                                    </b-col>
                                    <b-col md="2" class="text-start">
                                        <b-row>
                                            <strong class="label-col-green">M. Reales Programados: {{calculaMetros('programados',solicitudes)}}m<sup>3</sup></strong>
                                        </b-row>
                                    </b-col>
                                    <b-col md="2" class="text-start">
                                        <b-row>
                                            <strong class="label-col-red">M. Cancelados: {{calculaMetros('cancelados',solicitudes)}}m<sup>3</sup></strong>
                                        </b-row>
                                    </b-col>
                                </b-row>
                            </template>
                            <template #cell(fecha_hora_sol)="data">
                                <span v-html="formatearFecha(data.value,2)"></span>
                            </template> 
                            <template #cell(hora)="data">
                                <span v-html="formatearFechaHora(data.value,4)"></span>
                            </template> 
                           
                            <template #cell(suministros)="data">
                                <td class="text-center" style="width: 80px; height: 25px" :style="{ backgroundColor: data.item.color }">
                                    {{ data.value }}
                                </td>
                            </template>
                            <template #cell(ubicacion)="data">
                                    <!-- Enlace directo al archivo en Google Drive -->

                                <a v-if="data.item.lat==null || data.item.lat==''" :href="data.value" target="_blank">{{ data.value }}</a>
                                <a v-else :href="'https://www.google.com/maps?q='+data.item.lat+','+data.item.lng" target="_blank">{{ 'https://www.google.com/maps?q='+data.item.lat+','+data.item.lng }}</a>
                            </template>
                            <template #cell(fecha_hora_creacion)="data">
                                <span v-html="formatearFecha(data.value,5)"></span>
                            </template> 
                            <template #cell(edit)="data">
                                <b-button size="sm" variant="outline-dark" @click="editarSolicitud(data.item)">Editar</b-button>
                            </template> 
                            <template #cell(observaciones)="data">
                                <div @dblclick.stop="openModalObservaciones(data.item)" style="width: 100%; min-height: 20px;">
                                    <span class="label label-default">{{ data.value }},</span>
                                </div>
                            </template>
                            <template #cell(pedidos_activos)="data">
                                <div style="width: 100%; min-height: 20px;">
                                    <div v-if="data.value && data.value.length > 0">
                                        <div v-for="pedido in data.value" :key="pedido.id">
                                            <!-- Mostrar el label con la información de pedidos -->
                                            <span class="label label-default">{{ pedido.folio }},</span>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <!-- Marcador de posición cuando no hay pedidos -->
                                        <span class="text-muted">No hay pedidos</span>
                                    </div>
                                </div>
                            </template>
                        </b-table>
                        <b-spinner v-else class="text-center" variant="warning" ></b-spinner>
                    </b-container>
                </b-tab>
                <b-tab :title="'Pendientes (' + pendientes.length + ')'" >
                    <b-container fluid  v-if="pendientes.length > 0">
                        <b-row>
                            <b-form-group
                                label=""
                                label-for="filter-input"
                                label-cols-sm="3"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                                >
                                <b-input-group size="sm">
                                    <b-form-input
                                    id="filter-input"
                                    v-model="filter_pendientes"
                                    type="search"
                                    placeholder="Escribe para filtrar"
                                    ></b-form-input>
            
                                    <b-input-group-append>
                                    <b-button :disabled="!filter_pendientes" @click="filter_pendientes = ''" class="limpiar-bttn">Limpiar</b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </b-row>
                        <b-table v-if="!cargando" hover small :items="pendientes" :fields="fields_pendientes" selectable responsive striped
                                caption-top :filter="filter_pendientes"  select-mode="single" label-sort-asc="" label-sort-desc="" label-sort-clear="" 
                                style="font-size:12px;"  @row-dblclicked="onRowDblClick" table-class="custom-table"  thead-class="table-header"
                                tbody-class="table-body">
                            <template #table-caption ><strong class="fcgreen">Solicitudes Pendientes: {{ pendientes.length }}</strong></template>
                            <template #cell(fecha_hora_sol)="data">
                                <span v-html="formatearFecha(data.value,2)"></span>
                            </template> 
                            <template #cell(hora)="data">
                                <span v-html="formatearFechaHora(data.value,4)"></span>
                            </template> 
                           
                            <template #cell(suministros)="data">
                                <td class="text-center" style="width: 80px; height: 25px" :style="{ backgroundColor: data.item.color }">
                                    {{ data.value }}
                                </td>
                            </template>
                            <template #cell(ubicacion)="data">
                                    <!-- Enlace directo al archivo en Google Drive -->
                                <a v-if="data.item.lat==null || data.item.lat==''" :href="data.value" target="_blank">{{ data.value }}</a>
                                <a v-else :href="'https://www.google.com/maps?q='+data.item.lat+','+data.item.lng" target="_blank">{{ 'https://www.google.com/maps?q='+data.item.lat+','+data.item.lng }}</a>
                            </template>
                            <template #cell(fecha_hora_creacion)="data">
                                <span v-html="formatearFecha(data.value,5)"></span>
                            </template> 
                            <template #cell(edit)="data">
                                <b-button size="sm" variant="outline-dark" @click="editarSolicitud(data.item)">Editar</b-button>
                            </template>
                            <template #cell(observaciones)="data">
                                <div @dblclick.stop="openModalObservaciones(data.item)" style="width: 100%; min-height: 20px;">
                                    <span class="label label-default">{{ data.value }},</span>
                                </div>
                            </template>
                        </b-table>
                        <b-spinner v-else class="text-center" variant="warning" ></b-spinner>
                    </b-container>
                </b-tab>
                <b-tab :title="'Programado (' + programados.length + ')'">
                    <b-container fluid  v-if="programados.length > 0">
                        <b-row>
                            <b-form-group
                                label=""
                                label-for="filter-input"
                                label-cols-sm="3"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                                >
                                <b-input-group size="sm">
                                    <b-form-input
                                    id="filter-input"
                                    v-model="filter_programados"
                                    type="search"
                                    placeholder="Escribe para filtrar"
                                    ></b-form-input>
            
                                    <b-input-group-append>
                                    <b-button :disabled="!filter_programados" @click="filter_programados = ''" class="limpiar-bttn">Limpiar</b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </b-row>
                        <b-table v-if="!cargando" hover small :items="programados" :fields="fields_programados" selectable responsive striped
                                caption-top :filter="filter_programados"  select-mode="single"  label-sort-asc="" label-sort-desc="" label-sort-clear=""
                                 style="font-size:12px;"  @row-dblclicked="onRowDblClick" table-class="custom-table"  thead-class="table-header"
                                 tbody-class="table-body">
                            <!-- <template #table-caption ><strong class="fcgreen">Solicitudes Programadas: {{ programados.length }}</strong></template> -->
                            <template #table-caption >
                                <b-row>
                                    <strong>Solicitudes Programadas: {{programados.length}}</strong>
                                </b-row>
                                <b-row class="mt-1">
                                    <b-col md="2" class="text-start">
                                        <b-row>
                                            <strong class="label-col-green">M. Reales Programados: {{calculaMetros('programados',programados)}}m<sup>3</sup></strong>
                                        </b-row>
                                    </b-col>
                                    <b-col md="2" class="text-start">
                                        <b-row>
                                            <strong class="label-col-red">M. Cancelados: {{calculaMetros('cancelados',solicitudes)}}m<sup>3</sup></strong>
                                        </b-row>
                                    </b-col>
                                </b-row>
                            </template>
                            <template #cell(fecha_hora_sol)="data">
                                <span v-html="formatearFecha(data.value,2)"></span>
                            </template> 
                            <template #cell(hora)="data">
                                <span v-html="formatearFechaHora(data.value,4)"></span>
                            </template> 
                           
                            <template #cell(suministros)="data">
                                <td class="text-center" style="width: 80px; height: 25px" :style="{ backgroundColor: data.item.color }">
                                    {{ data.value }}
                                </td>
                            </template>
                            <template #cell(ubicacion)="data">
                                    <!-- Enlace directo al archivo en Google Drive -->
                                <a v-if="data.item.lat==null || data.item.lat==''" :href="data.value" target="_blank">{{ data.value }}</a>
                                <a v-else :href="'https://www.google.com/maps?q='+data.item.lat+','+data.item.lng" target="_blank">{{ 'https://www.google.com/maps?q='+data.item.lat+','+data.item.lng }}</a>
                            </template>
                            <template #cell(fecha_hora_creacion)="data">
                                <span v-html="formatearFecha(data.value,5)"></span>
                            </template> 
                            <template #cell(edit)="data">
                                <b-button size="sm" variant="outline-dark" @click="editarSolicitud(data.item)">Editar</b-button>
                            </template>
                            <template #cell(observaciones)="data">
                                <div @dblclick.stop="openModalObservaciones(data.item)" style="width: 100%; min-height: 20px;">
                                    <span class="label label-default">{{ data.value }},</span>
                                </div>
                            </template>

                            <template #cell(pedidos_activos)="data">
                                <div @dblclick.stop="agregarPedido(data.item)" style="width: 100%; min-height: 20px;">
                                    <div v-if="data.value && data.value.length > 0">
                                        <div v-for="pedido in data.value" :key="pedido.id">
                                            <!-- Mostrar el label con la información de pedidos -->
                                            <span class="label label-default">{{ pedido.folio }},</span>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <!-- Marcador de posición cuando no hay pedidos -->
                                        <span class="text-muted">No hay pedidos</span>
                                    </div>
                                </div>
                            </template>
                        </b-table>
                        <b-spinner v-else class="text-center" variant="warning" ></b-spinner>
                    </b-container>
                </b-tab>
                <b-tab :title="'Cargando (' + cargadas.length + ')'" >
                    <b-container fluid  v-if="cargadas.length > 0">
                        <b-row>
                            <b-form-group
                                label=""
                                label-for="filter-input"
                                label-cols-sm="3"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                                >
                                <b-input-group size="sm">
                                    <b-form-input
                                    id="filter-input"
                                    v-model="filter_cargadas"
                                    type="search"
                                    placeholder="Escribe para filtrar"
                                    ></b-form-input>
            
                                    <b-input-group-append>
                                    <b-button :disabled="!filter_cargadas" @click="filter_cargadas = ''" class="limpiar-bttn">Limpiar</b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </b-row>
                        <b-table v-if="!cargando" hover small :items="cargadas" :fields="fields_cargadas" selectable responsive striped
                                caption-top :filter="filter_cargadas"  select-mode="single" label-sort-asc="" label-sort-desc="" label-sort-clear=""
                                 style="font-size:12px;"  @row-dblclicked="onRowDblClick" table-class="custom-table"  thead-class="table-header"
                                 tbody-class="table-body">
                            <template #table-caption ><strong class="fcgreen">Solicitudes Cargando: {{ cargadas.length }}</strong></template>
                            <template #cell(fecha_hora_sol)="data">
                                <span v-html="formatearFecha(data.value,2)"></span>
                            </template> 
                            <template #cell(hora)="data">
                                <span v-html="formatearFechaHora(data.value,4)"></span>
                            </template> 
                           
                            <template #cell(suministros)="data">
                                <td class="text-center" style="width: 80px; height: 25px" :style="{ backgroundColor: data.item.color }">
                                    {{ data.value }}
                                </td>
                            </template>
                            <template #cell(ubicacion)="data">
                                    <!-- Enlace directo al archivo en Google Drive -->
                                <a v-if="data.item.lat==null || data.item.lat==''" :href="data.value" target="_blank">{{ data.value }}</a>
                                <a v-else :href="'https://www.google.com/maps?q='+data.item.lat+','+data.item.lng" target="_blank">{{ 'https://www.google.com/maps?q='+data.item.lat+','+data.item.lng }}</a>
                            </template>
                            <template #cell(fecha_hora_creacion)="data">
                                <span v-html="formatearFecha(data.value,5)"></span>
                            </template> 
                            <template #cell(edit)="data">
                                <b-button size="sm" variant="outline-dark" @click="editarSolicitud(data.item)">Editar</b-button>
                            </template>
                            <template #cell(observaciones)="data">
                                <div @dblclick.stop="openModalObservaciones(data.item)" style="width: 100%; min-height: 20px;">
                                    <span class="label label-default">{{ data.value }},</span>
                                </div>
                            </template>
                        </b-table>
                        <b-spinner v-else class="text-center" variant="warning" ></b-spinner>
                    </b-container>
                </b-tab>
                <b-tab :title="'Reprogramados (' + reprogramados.length + ')'">
                    <b-container fluid  v-if="reprogramados.length > 0">
                        <b-row>
                            <b-form-group
                                label=""
                                label-for="filter-input"
                                label-cols-sm="3"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                                >
                                <b-input-group size="sm">
                                    <b-form-input
                                    id="filter-input"
                                    v-model="filter_reprogramados"
                                    type="search"
                                    placeholder="Escribe para filtrar"
                                    ></b-form-input>
            
                                    <b-input-group-append>
                                    <b-button :disabled="!filter_reprogramados" @click="filter_reprogramados = ''" class="limpiar-bttn">Limpiar</b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </b-row>
                        <b-table v-if="!cargando" hover small :items="reprogramados" :fields="fields_reprogramados" selectable responsive striped
                                caption-top :filter="filter_reprogramados"  select-mode="single" 
                                 label-sort-asc="" label-sort-desc="" label-sort-clear="" 
                                 style="font-size:12px;"  @row-dblclicked="onRowDblClick" table-class="custom-table"  thead-class="table-header"
                                 tbody-class="table-body">
                            <template #table-caption ><strong class="fcgreen">Solicitudes Cargando: {{ reprogramados.length }}</strong></template>
                            <template #cell(fecha_hora_sol)="data">
                                <span v-html="formatearFecha(data.value,2)"></span>
                            </template> 
                            <template #cell(hora)="data">
                                <span v-html="formatearFechaHora(data.value,4)"></span>
                            </template> 
                           
                            <template #cell(suministros)="data">
                                <td class="text-center" style="width: 80px; height: 25px" :style="{ backgroundColor: data.item.color }">
                                    {{ data.value }}
                                </td>
                            </template>
                            <template #cell(ubicacion)="data">
                                    <!-- Enlace directo al archivo en Google Drive -->
                                <a v-if="data.item.lat==null || data.item.lat==''" :href="data.value" target="_blank">{{ data.value }}</a>
                                <a v-else :href="'https://www.google.com/maps?q='+data.item.lat+','+data.item.lng" target="_blank">{{ 'https://www.google.com/maps?q='+data.item.lat+','+data.item.lng }}</a>
                            </template>
                            <template #cell(fecha_hora_creacion)="data">
                                <span v-html="formatearFecha(data.value,5)"></span>
                            </template> 
                            <template #cell(edit)="data">
                                <b-button size="sm" variant="outline-dark" @click="editarSolicitud(data.item)">Editar</b-button>
                            </template>
                            <template #cell(observaciones)="data">
                                <div @dblclick.stop="openModalObservaciones(data.item)" style="width: 100%; min-height: 20px;">
                                    <span class="label label-default">{{ data.value }},</span>
                                </div>
                            </template>
                        </b-table>
                        <b-spinner v-else class="text-center" variant="warning" ></b-spinner>
                    </b-container>
                </b-tab>
                <b-tab :title="'Despachado (' + despachados.length + ')'">
                    <b-container fluid  v-if="despachados.length > 0">
                        <b-row>
                            <b-form-group
                                label=""
                                label-for="filter-input"
                                label-cols-sm="3"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                                >
                                <b-input-group size="sm">
                                    <b-form-input
                                    id="filter-input"
                                    v-model="filter_despachados"
                                    type="search"
                                    placeholder="Escribe para filtrar"
                                    ></b-form-input>
            
                                    <b-input-group-append>
                                    <b-button :disabled="!filter_despachados" @click="filter_despachados = ''" class="limpiar-bttn">Limpiar</b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </b-row>
                        <b-table v-if="!cargando" hover small :items="despachados" :fields="fields_despachados" selectable responsive striped
                                caption-top :filter="filter_despachados"  select-mode="single" 
                                 label-sort-asc="" label-sort-desc="" label-sort-clear="" 
                                 style="font-size:12px;"  @row-dblclicked="onRowDblClick" table-class="custom-table"  thead-class="table-header"
                                 tbody-class="table-body">
                            <!-- <template #table-caption ><strong class="fcgreen">Solicitudes despachando: {{ despachados.length }}</strong></template> -->
                            <template #table-caption >
                                <b-row>
                                    <strong>Solicitudes despachadas: {{despachados.length}}</strong>
                                </b-row>
                                <b-row class="mt-1">
                                    <b-col md="2" class="text-start">
                                        <b-row>
                                            <strong class="label-col-green">M. Reales Despachados: {{calculaMetros('despachados',despachados)}}m<sup>3</sup></strong>
                                        </b-row>
                                    </b-col>
                                </b-row>
                            </template>
                            <template #cell(fecha_hora_sol)="data">
                                <span v-html="formatearFecha(data.value,2)"></span>
                            </template> 
                            <template #cell(hora)="data">
                                <span v-html="formatearFechaHora(data.value,4)"></span>
                            </template> 
                           
                            <template #cell(suministros)="data">
                                <td class="text-center" style="width: 80px; height: 25px" :style="{ backgroundColor: data.item.color }">
                                    {{ data.value }}
                                </td>
                            </template>
                            <template #cell(ubicacion)="data">
                                    <!-- Enlace directo al archivo en Google Drive -->
                                <a v-if="data.item.lat==null || data.item.lat==''" :href="data.value" target="_blank">{{ data.value }}</a>
                                <a v-else :href="'https://www.google.com/maps?q='+data.item.lat+','+data.item.lng" target="_blank">{{ 'https://www.google.com/maps?q='+data.item.lat+','+data.item.lng }}</a>
                            </template>
                            <template #cell(fecha_hora_creacion)="data">
                                <span v-html="formatearFecha(data.value,5)"></span>
                            </template> 
                            <template #cell(edit)="data">
                                <b-button size="sm" variant="outline-dark" @click="editarSolicitud(data.item)">Editar</b-button>
                            </template>
                            <template #cell(observaciones)="data">
                                <div @dblclick.stop="openModalObservaciones(data.item)" style="width: 100%; min-height: 20px;">
                                    <span class="label label-default">{{ data.value }},</span>
                                </div>
                            </template>
                            <template #cell(pedidos_activos)="data">
                                <div style="width: 100%; min-height: 20px;">
                                    <div v-if="data.value && data.value.length > 0">
                                        <div v-for="pedido in data.value" :key="pedido.id">
                                            <!-- Mostrar el label con la información de pedidos -->
                                            <span class="label label-default">{{ pedido.folio }},</span>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <!-- Marcador de posición cuando no hay pedidos -->
                                        <span class="text-muted">No hay pedidos</span>
                                    </div>
                                </div>
                            </template>
                        </b-table>
                        <b-spinner v-else class="text-center" variant="warning" ></b-spinner>
                    </b-container>
                </b-tab>
                <b-tab :title="'Cancelados (' + cancelados.length + ')'">
                    <b-container fluid  v-if="cancelados.length > 0">
                        <b-row>
                            <b-form-group
                                label=""
                                label-for="filter-input"
                                label-cols-sm="3"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                                >
                                <b-input-group size="sm">
                                    <b-form-input
                                    id="filter-input"
                                    v-model="filter_cancelados"
                                    type="search"
                                    placeholder="Escribe para filtrar"
                                    ></b-form-input>
            
                                    <b-input-group-append>
                                    <b-button :disabled="!filter_cancelados" @click="filter_cancelados = ''" class="limpiar-bttn">Limpiar</b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </b-row>
                        <b-table v-if="!cargando" hover small :items="cancelados" :fields="fields_cancelados" selectable responsive striped
                                caption-top :filter="filter_cancelados"  select-mode="single" 
                                 label-sort-asc="" label-sort-desc="" label-sort-clear="" 
                                 style="font-size:12px;"  @row-dblclicked="onRowDblClick" table-class="custom-table"  thead-class="table-header"
                                 tbody-class="table-body">
                            <!-- <template #table-caption ><strong class="fcgreen">Solicitudes Canceladas: {{ cancelados.length }}</strong></template> -->
                            <template #table-caption >
                                <b-row>
                                    <strong>Solicitudes Canceladas: {{cancelados.length}}</strong>
                                </b-row>
                                <b-row class="mt-1">
                                    <b-col md="2" class="text-start">
                                        <b-row>
                                            <strong class="label-col-red">M. Cancelados: {{calculaMetros('cancelados',cancelados)}}m<sup>3</sup></strong>
                                        </b-row>
                                    </b-col>
                                </b-row>
                            </template>
                            <template #cell(fecha_hora_sol)="data">
                                <span v-html="formatearFecha(data.value,2)"></span>
                            </template> 
                            <template #cell(hora)="data">
                                <span v-html="formatearFechaHora(data.value,4)"></span>
                            </template> 
                           
                            <template #cell(suministros)="data">
                                <td class="text-center" style="width: 80px; height: 25px" :style="{ backgroundColor: data.item.color }">
                                    {{ data.value }}
                                </td>
                            </template>
                            <template #cell(ubicacion)="data">
                                    <!-- Enlace directo al archivo en Google Drive -->
                                <a v-if="data.item.lat==null || data.item.lat==''" :href="data.value" target="_blank">{{ data.value }}</a>
                                <a v-else :href="'https://www.google.com/maps?q='+data.item.lat+','+data.item.lng" target="_blank">{{ 'https://www.google.com/maps?q='+data.item.lat+','+data.item.lng }}</a>
                            </template>
                            <template #cell(fecha_hora_creacion)="data">
                                <span v-html="formatearFecha(data.value,5)"></span>
                            </template> 
                            <template #cell(edit)="data">
                                <b-button size="sm" variant="outline-dark" @click="editarSolicitud(data.item)">Editar</b-button>
                            </template>
                            <template #cell(observaciones)="data">
                                <div @dblclick.stop="openModalObservaciones(data.item)" style="width: 100%; min-height: 20px;">
                                    <span class="label label-default">{{ data.value }},</span>
                                </div>
                            </template>
                        </b-table>
                        <b-spinner v-else class="text-center" variant="warning" ></b-spinner>
                    </b-container>
                </b-tab>
            </b-tabs> 
        </div>
        <b-modal ref="modal-export" hide-header-close scrollable button-size="sm"  title="Elige las columnas a exportar" @ok="exportarExcel()">
            <div  class="card shadow p-3 fs-12 text-center"> 
                <b-row>
                    <b-col md="6" class="text-start"> 
                        <!-- <b-button @click="saveColor">Guardar Color</b-button> -->
                        <b-form-group label="Selecciona las columnas que deseas exportar" class="label-col-blue negritas">
                            <b-form-checkbox-group v-model="selectedProperties" inline class="text-start">
                                <b-form-checkbox v-for="(prop, index) in availableProperties" :key="index" :value="prop">
                                {{ prop }}
                                </b-form-checkbox>
                            </b-form-checkbox-group>
                        </b-form-group>
                    </b-col>
                    <b-col md="6" class="text-end ">
                        <b-card-text class="label-col-blue negritas"> Orden de columnas elegidas</b-card-text>
                        <ul class="label-col-green">
                            <li v-for="(prop, index) in selectedProperties" :key="index">{{ prop }}</li>
                        </ul>
                        
                    </b-col>
                </b-row>
               
            </div>
            <template #modal-footer="{ ok, cancel }">
                <!-- Emulate built in modal footer ok and cancel button actions -->
                <b-button size="sm" variant="success" @click="ok()">
                    Exportar
                </b-button>
                <b-button size="sm" variant="secondary" @click="cancel()">
                    Cancel
                </b-button>
            </template>
        </b-modal>
        <b-modal ref="modal-pedidos" hide-header-close scrollable button-size="sm"  :title="'Pedido(s) de la solicitud: ' + solSelected.solicitud_id" @ok="cerrarModal()" @shown="setFocusOnInput">
            <div  class="card shadow p-3 fs-12 text-center"> 
                <b-row>
                    <!-- <b-button @click="saveColor">Guardar Color</b-button> -->
                    <b-form-group :label="'Ingresa el(los) folio(s) de pedido ligado a la solicitud: ' + solSelected.solicitud_id" class="label-col-blue negritas text-start">
                        <b-row>
                            <b-col md="8">
                                <b-form-input class="mt-1 shadow-sm" ref="inputFolio" v-model="folio" type="text"></b-form-input>
                            </b-col>
                            <b-col md="4">
                                <b-button v-if="!cargando" class="btn-md py-2 px-3 consultar-bttn" size="sm" variant="outline-dark" @click="guardarPedido(folio)">Agregar</b-button>
                                <b-spinner v-else class="text-center" variant="warning" ></b-spinner>
                            </b-col>
                        </b-row>
                    </b-form-group>
                    <b-table class="mt-3" hover small :items="pedidos" :fields="fields_pedidos" selectable responsive striped
                            caption-top select-mode="single"  label-sort-asc="" label-sort-desc="" label-sort-clear="" style="font-size:12px;" 
                            table-class="custom-table"  thead-class="table-header" tbody-class="table-body">
                        <template #table-caption >
                            <b-row>
                                <strong>Pedidos totales: {{pedidos.length}}</strong>
                            </b-row>
                        </template>
                        <template #cell(del)="data" >
                            <b-button class="btn-md" size="sm" variant="outline-danger" @click="ActualizaPedido(data.item)"> <i class="fas fa-ban"></i></b-button>
                        </template> 
                    </b-table>
                </b-row>
               
            </div>
            <template #modal-footer="{ ok, cancel }">
                <!-- Emulate built in modal footer ok and cancel button actions -->
                <b-button size="sm" variant="success" @click="ok()">
                    Cerrar
                </b-button>
                <b-button size="sm" variant="secondary" @click="cancel()">
                    Cancel
                </b-button>
            </template>
        </b-modal>
        <b-modal ref="modal-observaciones" hide-header-close scrollable button-size="sm" size="lg" title="Observaciones">
            <Observaciones :observaciones="solSelected.sol_observaciones"></Observaciones>
        </b-modal>    
        <Modals ref="mod"></Modals>
    </div>
</template>
  
<script>
import Modals from '@/components/Modals.vue'
import Observaciones from '@/components/Observaciones.vue'
// import solicitudes from '@/catalogos/solicitudes.vue'
import {mapState,mapActions} from 'vuex'
import * as utils from "@/shared/util"

export default {
    data() {
        return {
            texto: 'Solicitudes de servicio',
            tabselected:0,
            currentPage:1,
            perPage:10,
            fields:[{key: 'solicitud_id', label: '#Sol', class:"negritas", sortable: true},
                    {key: 'estatus', label: 'Estatus', sortable: true},
                    {key: 'cliente', label: 'Cliente', sortable: true, class:'negritas'},
                    {key: 'fecha_hora_sol', label: 'Fecha solicitud', sortable: true},
                    {key: 'hora', label: 'Hora solicitud', sortable: true},
                    {key: 'residente', label: 'Residente', sortable: true},
                    {key: 'operador_id', label: 'Operador_id', sortable: true, class:"d-none"},
                    {key: 'vendedor', label: 'Vendedor', sortable: true},
                    {key: 'obra', label: 'Obra', sortable: true},
                    {key: 'producto_id', label: 'Producto_id', sortable: true, class:'d-none'},
                    {key: 'clave_producto', label: 'Clave', sortable: true, class:'negritas'},
                    {key: 'tipo_producto', label: 'Tipo Prod.', sortable: true, class:'negritas'},
                    {key: 'metros', label: 'Metros', sortable: true, class:'negritas'},
                    {key: 'ajuste', label: 'Ajuste', sortable: true, class:'negritas'},
                    {key: 'metros_totales', label: 'M.Reales', sortable: true, class:'negritas'},
                    {key: 'tipo_servicio', label: 'Tipo servicio', sortable: true, class:'negritas'},
                    {key: 'tuberia_manguera', label: 'Tuberia o Manguera', sortable: true},
                    {key: 'tubos', label: 'Tubos', sortable: true},
                    {key: 'tipo_manguera', label: 'Tipo manguera', sortable: true},
                    {key: 'suministros', label: 'Suministros', sortable: true},
                    {key: 'color', label: 'color', sortable: true, class:'d-none'},
                    {key: 'elemento', label: 'Elemento', sortable: true},
                    {key: 'elementos', label: '# Ele.', sortable: true},
                    {key: 'resistencias', label: '# Res.', sortable: true},
                    {key: 'intervalo', label: 'Intervalo', sortable: true},
                    {key: 'distancia', label: 'Distancia', sortable: true},
                    {key: 'ubicacion', label: 'Ubicación', sortable: true},
                    {key: 'tiene_laboratorio', label: 'Lab', sortable: true},
                    {key: 'observaciones', label: 'Observaciones', sortable: true},
                    {key: 'solicitud', label: 'Solicitud', sortable: true, class:'d-none'},
                    {key: 'pedidos_activos', label: 'Pedidos', sortable: true},
                    {key: 'fecha_hora_creacion', label: 'Creado', sortable: true},
                    {key: 'fecha_hora_reagendado', label: 'Reagendado', sortable: true},
                    {key: 'edit', label: 'Acción', sortable: true},
                    {key: 'lat', label: 'LAT', sortable: true, class:'d-none'},
                    {key: 'lng', label: 'LNG', sortable: true, class:'d-none'},
                    // {key: 'DIFERENCIA', label: 'DIF', class:'negritas'},
                    ],
            fields_pendientes:[{key: 'solicitud_id', label: '#Sol', class:"negritas", sortable: true},
                    {key: 'cliente', label: 'Cliente', sortable: true, class:'negritas'},
                    {key: 'fecha_hora_sol', label: 'Fecha solicitud', sortable: true},
                    {key: 'hora', label: 'Hora solicitud', sortable: true},
                    {key: 'residente', label: 'Residente', sortable: true},
                    {key: 'operador_id', label: 'Operador_id', sortable: true, class:"d-none"},
                    {key: 'vendedor', label: 'Vendedor', sortable: true},
                    {key: 'obra', label: 'Obra', sortable: true},
                    {key: 'producto_id', label: 'Producto_id', sortable: true, class:'negritas d-none'},
                    {key: 'clave_producto', label: 'Clave', sortable: true, class:'negritas'},
                    {key: 'tipo_producto', label: 'Tipo Prod.', sortable: true, class:'negritas'},
                    {key: 'metros', label: 'Metros', sortable: true, class:'negritas'},
                    {key: 'ajuste', label: 'Ajuste', sortable: true, class:'negritas'},
                    {key: 'metros_totales', label: 'M.Reales', sortable: true, class:'negritas'},
                    {key: 'tipo_servicio', label: 'Tipo servicio', sortable: true, class:'negritas'},
                    {key: 'tuberia_manguera', label: 'Tuberia o Manguera', sortable: true},
                    {key: 'tubos', label: 'Tubos', sortable: true},
                    {key: 'tipo_manguera', label: 'Tipo manguera', sortable: true},
                    {key: 'suministros', label: 'Suministros', sortable: true},
                    {key: 'color', label: 'color', sortable: true, class:'d-none'},
                    {key: 'elemento', label: 'Elemento', sortable: true},
                    {key: 'elementos', label: '# Ele.', sortable: true},
                    {key: 'resistencias', label: '# Res.', sortable: true},
                    {key: 'intervalo', label: 'Intervalo', sortable: true},
                    {key: 'distancia', label: 'Distancia', sortable: true},
                    {key: 'ubicacion', label: 'Ubicación', sortable: true},
                    {key: 'tiene_laboratorio', label: 'Lab', sortable: true},
                    {key: 'observaciones', label: 'Observaciones', sortable: true},
                    {key: 'solicitud', label: 'Solicitud', sortable: true, class:'d-none'},
                    {key: 'pedidos_activos', label: 'Pedidos', sortable: true},
                    {key: 'estatus', label: 'Estatus', sortable: true},
                    {key: 'fecha_hora_creacion', label: 'Creado', sortable: true},
                    {key: 'fecha_hora_reagendado', label: 'Reagendado', sortable: true},
                    {key: 'edit', label: 'Acción', sortable: true},
                    {key: 'lat', label: 'LAT', sortable: true, class:'d-none'},
                    {key: 'lng', label: 'LNG', sortable: true, class:'d-none'},
                    // {key: 'DIFERENCIA', label: 'DIF', class:'negritas'},
                    ],
            fields_programados:[{key: 'solicitud_id', label: '#Sol', class:"negritas", sortable: true},
                    {key: 'cliente', label: 'Cliente', sortable: true, class:'negritas'},
                    {key: 'fecha_hora_sol', label: 'Fecha solicitud', sortable: true},
                    {key: 'hora', label: 'Hora solicitud', sortable: true},
                    {key: 'residente', label: 'Residente', sortable: true},
                    {key: 'operador_id', label: 'Operador_id', sortable: true, class:"d-none"},
                    {key: 'vendedor', label: 'Vendedor', sortable: true},
                    {key: 'obra', label: 'Obra', sortable: true},
                    {key: 'producto_id', label: 'Producto_id', sortable: true, class:'negritas d-none'},
                    {key: 'clave_producto', label: 'Clave', sortable: true, class:'negritas'},
                    {key: 'tipo_producto', label: 'Tipo Prod.', sortable: true, class:'negritas'},
                    {key: 'metros', label: 'Metros', sortable: true, class:'negritas'},
                    {key: 'ajuste', label: 'Ajuste', sortable: true, class:'negritas'},
                    {key: 'metros_totales', label: 'M.Reales', sortable: true, class:'negritas'},
                    {key: 'tipo_servicio', label: 'Tipo servicio', sortable: true, class:'negritas'},
                    {key: 'tuberia_manguera', label: 'Tuberia o Manguera', sortable: true},
                    {key: 'tubos', label: 'Tubos', sortable: true},
                    {key: 'tipo_manguera', label: 'Tipo manguera', sortable: true},
                    {key: 'suministros', label: 'Suministros', sortable: true},
                    {key: 'color', label: 'color', sortable: true, class:'d-none'},
                    {key: 'elemento', label: 'Elemento', sortable: true},
                    {key: 'elementos', label: '# Ele.', sortable: true},
                    {key: 'resistencias', label: '# Res.', sortable: true},
                    {key: 'intervalo', label: 'Intervalo', sortable: true},
                    {key: 'distancia', label: 'Distancia', sortable: true},
                    {key: 'ubicacion', label: 'Ubicación', sortable: true},
                    {key: 'tiene_laboratorio', label: 'Lab', sortable: true},
                    {key: 'observaciones', label: 'Observaciones', sortable: true},
                    {key: 'solicitud', label: 'Solicitud', sortable: true, class:'d-none'},
                    {key: 'pedidos_activos', label: 'Pedidos', sortable: true},
                    {key: 'estatus', label: 'Estatus', sortable: true},
                    {key: 'fecha_hora_creacion', label: 'Creado', sortable: true},
                    {key: 'fecha_hora_reagendado', label: 'Reagendado', sortable: true},
                    {key: 'edit', label: 'Acción', sortable: true},
                    {key: 'lat', label: 'LAT', sortable: true, class:'d-none'},
                    {key: 'lng', label: 'LNG', sortable: true, class:'d-none'},
                    // {key: 'DIFERENCIA', label: 'DIF', class:'negritas'},
                    ],
            fields_cargadas:[{key: 'solicitud_id', label: '#Sol', class:"negritas", sortable: true},
                    {key: 'cliente', label: 'Cliente', sortable: true, class:'negritas'},
                    {key: 'fecha_hora_sol', label: 'Fecha solicitud', sortable: true},
                    {key: 'hora', label: 'Hora solicitud', sortable: true},
                    {key: 'residente', label: 'Residente', sortable: true},
                    {key: 'operador_id', label: 'Operador_id', sortable: true, class:"d-none"},
                    {key: 'vendedor', label: 'Vendedor', sortable: true},
                    {key: 'obra', label: 'Obra', sortable: true},
                    {key: 'producto_id', label: 'Producto_id', sortable: true, class:'negritas d-none'},
                    {key: 'clave_producto', label: 'Clave', sortable: true, class:'negritas'},
                    {key: 'tipo_producto', label: 'Tipo Prod.', sortable: true, class:'negritas'},
                    {key: 'metros', label: 'Metros', sortable: true, class:'negritas'},
                    {key: 'ajuste', label: 'Ajuste', sortable: true, class:'negritas'},
                    {key: 'metros_totales', label: 'M.Reales', sortable: true, class:'negritas'},
                    {key: 'tipo_servicio', label: 'Tipo servicio', sortable: true, class:'negritas'},
                    {key: 'tuberia_manguera', label: 'Tuberia o Manguera', sortable: true},
                    {key: 'tubos', label: 'Tubos', sortable: true},
                    {key: 'tipo_manguera', label: 'Tipo manguera', sortable: true},
                    {key: 'suministros', label: 'Suministros', sortable: true},
                    {key: 'color', label: 'color', sortable: true, class:'d-none'},
                    {key: 'elemento', label: 'Elemento', sortable: true},
                    {key: 'elementos', label: '# Ele.', sortable: true},
                    {key: 'resistencias', label: '# Res.', sortable: true},
                    {key: 'intervalo', label: 'Intervalo', sortable: true},
                    {key: 'distancia', label: 'Distancia', sortable: true},
                    {key: 'ubicacion', label: 'Ubicación', sortable: true},
                    {key: 'tiene_laboratorio', label: 'Lab', sortable: true},
                    {key: 'observaciones', label: 'Observaciones', sortable: true},
                    {key: 'solicitud', label: 'Solicitud', sortable: true, class:'d-none'},
                    {key: 'pedidos_activos', label: 'Pedidos', sortable: true},
                    {key: 'estatus', label: 'Estatus', sortable: true},
                    {key: 'fecha_hora_creacion', label: 'Creado', sortable: true},
                    {key: 'fecha_hora_reagendado', label: 'Reagendado', sortable: true},
                    {key: 'edit', label: 'Acción', sortable: true},
                    {key: 'lat', label: 'LAT', sortable: true, class:'d-none'},
                    {key: 'lng', label: 'LNG', sortable: true, class:'d-none'},
                    // {key: 'DIFERENCIA', label: 'DIF', class:'negritas'},
                    ],
            fields_despachados:[{key: 'solicitud_id', label: '#Sol', class:"negritas", sortable: true},
                    {key: 'cliente', label: 'Cliente', sortable: true, class:'negritas'},
                    {key: 'fecha_hora_sol', label: 'Fecha solicitud', sortable: true},
                    {key: 'hora', label: 'Hora solicitud', sortable: true},
                    {key: 'residente', label: 'Residente', sortable: true},
                    {key: 'operador_id', label: 'Operador_id', sortable: true, class:"d-none"},
                    {key: 'vendedor', label: 'Vendedor', sortable: true},
                    {key: 'obra', label: 'Obra', sortable: true},
                    {key: 'producto_id', label: 'Producto_id', sortable: true, class:'negritas d-none'},
                    {key: 'clave_producto', label: 'Clave', sortable: true, class:'negritas'},
                    {key: 'tipo_producto', label: 'Tipo Prod.', sortable: true, class:'negritas'},
                    {key: 'metros', label: 'Metros', sortable: true, class:'negritas'},
                    {key: 'ajuste', label: 'Ajuste', sortable: true, class:'negritas'},
                    {key: 'metros_totales', label: 'M.Reales', sortable: true, class:'negritas'},
                    {key: 'tipo_servicio', label: 'Tipo servicio', sortable: true, class:'negritas'},
                    {key: 'tuberia_manguera', label: 'Tuberia o Manguera', sortable: true},
                    {key: 'tubos', label: 'Tubos', sortable: true},
                    {key: 'tipo_manguera', label: 'Tipo manguera', sortable: true},
                    {key: 'suministros', label: 'Suministros', sortable: true},
                    {key: 'color', label: 'color', sortable: true, class:'d-none'},
                    {key: 'elemento', label: 'Elemento', sortable: true},
                    {key: 'elementos', label: '# Ele.', sortable: true},
                    {key: 'resistencias', label: '# Res.', sortable: true},
                    {key: 'intervalo', label: 'Intervalo', sortable: true},
                    {key: 'distancia', label: 'Distancia', sortable: true},
                    {key: 'ubicacion', label: 'Ubicación', sortable: true},
                    {key: 'tiene_laboratorio', label: 'Lab', sortable: true},
                    {key: 'observaciones', label: 'Observaciones', sortable: true},
                    {key: 'solicitud', label: 'Solicitud', sortable: true, class:'d-none'},
                    {key: 'pedidos_activos', label: 'Pedidos', sortable: true},
                    {key: 'estatus', label: 'Estatus', sortable: true},
                    {key: 'fecha_hora_creacion', label: 'Creado', sortable: true},
                    {key: 'fecha_hora_reagendado', label: 'Reagendado', sortable: true},
                    {key: 'edit', label: 'Acción', sortable: true},
                    {key: 'lat', label: 'LAT', sortable: true, class:'d-none'},
                    {key: 'lng', label: 'LNG', sortable: true, class:'d-none'},
                    // {key: 'DIFERENCIA', label: 'DIF', class:'negritas'},
                    ],
            fields_reprogramados:[{key: 'solicitud_id', label: '#Sol', class:"negritas", sortable: true},
                    {key: 'cliente', label: 'Cliente', sortable: true, class:'negritas'},
                    {key: 'fecha_hora_sol', label: 'Fecha solicitud', sortable: true},
                    {key: 'hora', label: 'Hora solicitud', sortable: true},
                    {key: 'residente', label: 'Residente', sortable: true},
                    {key: 'operador_id', label: 'Operador_id', sortable: true, class:"d-none"},
                    {key: 'vendedor', label: 'Vendedor', sortable: true},
                    {key: 'obra', label: 'Obra', sortable: true},
                    {key: 'producto_id', label: 'Producto_id', sortable: true, class:'negritas d-none'},
                    {key: 'clave_producto', label: 'Clave', sortable: true, class:'negritas'},
                    {key: 'tipo_producto', label: 'Tipo Prod.', sortable: true, class:'negritas'},
                    {key: 'metros', label: 'Metros', sortable: true, class:'negritas'},
                    {key: 'ajuste', label: 'Ajuste', sortable: true, class:'negritas'},
                    {key: 'metros_totales', label: 'M.Reales', sortable: true, class:'negritas'},
                    {key: 'tipo_servicio', label: 'Tipo servicio', sortable: true, class:'negritas'},
                    {key: 'tuberia_manguera', label: 'Tuberia o Manguera', sortable: true},
                    {key: 'tubos', label: 'Tubos', sortable: true},
                    {key: 'tipo_manguera', label: 'Tipo manguera', sortable: true},
                    {key: 'suministros', label: 'Suministros', sortable: true},
                    {key: 'color', label: 'color', sortable: true, class:'d-none'},
                    {key: 'elemento', label: 'Elemento', sortable: true},
                    {key: 'elementos', label: '# Ele.', sortable: true},
                    {key: 'resistencias', label: '# Res.', sortable: true},
                    {key: 'intervalo', label: 'Intervalo', sortable: true},
                    {key: 'distancia', label: 'Distancia', sortable: true},
                    {key: 'ubicacion', label: 'Ubicación', sortable: true},
                    {key: 'tiene_laboratorio', label: 'Lab', sortable: true},
                    {key: 'observaciones', label: 'Observaciones', sortable: true},
                    {key: 'solicitud', label: 'Solicitud', sortable: true, class:'d-none'},
                    {key: 'pedidos_activos', label: 'Pedidos', sortable: true},
                    {key: 'estatus', label: 'Estatus', sortable: true},
                    {key: 'fecha_hora_creacion', label: 'Creado', sortable: true},
                    {key: 'fecha_hora_reagendado', label: 'Reagendado', sortable: true},
                    {key: 'edit', label: 'Acción', sortable: true},
                    {key: 'lat', label: 'LAT', sortable: true, class:'d-none'},
                    {key: 'lng', label: 'LNG', sortable: true, class:'d-none'},
                    // {key: 'DIFERENCIA', label: 'DIF', class:'negritas'},
                    ],
            fields_cancelados:[{key: 'solicitud_id', label: '#Sol', class:"negritas", sortable: true},
                    {key: 'cliente', label: 'Cliente', sortable: true, class:'negritas'},
                    {key: 'fecha_hora_sol', label: 'Fecha solicitud', sortable: true},
                    {key: 'hora', label: 'Hora solicitud', sortable: true},
                    {key: 'residente', label: 'Residente', sortable: true},
                    {key: 'operador_id', label: 'Operador_id', sortable: true, class:"d-none"},
                    {key: 'vendedor', label: 'Vendedor', sortable: true},
                    {key: 'obra', label: 'Obra', sortable: true},
                    {key: 'producto_id', label: 'Producto_id', sortable: true, class:'negritas d-none'},
                    {key: 'clave_producto', label: 'Clave', sortable: true, class:'negritas'},
                    {key: 'tipo_producto', label: 'Tipo Prod.', sortable: true, class:'negritas'},
                    {key: 'metros', label: 'Metros', sortable: true, class:'negritas'},
                    {key: 'ajuste', label: 'Ajuste', sortable: true, class:'negritas'},
                    {key: 'metros_totales', label: 'M.Reales', sortable: true, class:'negritas'},
                    {key: 'tipo_servicio', label: 'Tipo servicio', sortable: true, class:'negritas'},
                    {key: 'tuberia_manguera', label: 'Tuberia o Manguera', sortable: true},
                    {key: 'tubos', label: 'Tubos', sortable: true},
                    {key: 'tipo_manguera', label: 'Tipo manguera', sortable: true},
                    {key: 'suministros', label: 'Suministros', sortable: true},
                    {key: 'color', label: 'color', sortable: true, class:'d-none'},
                    {key: 'elemento', label: 'Elemento', sortable: true},
                    {key: 'elementos', label: '# Ele.', sortable: true},
                    {key: 'resistencias', label: '# Res.', sortable: true},
                    {key: 'intervalo', label: 'Intervalo', sortable: true},
                    {key: 'distancia', label: 'Distancia', sortable: true},
                    {key: 'ubicacion', label: 'Ubicación', sortable: true},
                    {key: 'tiene_laboratorio', label: 'Lab', sortable: true},
                    {key: 'observaciones', label: 'Observaciones', sortable: true},
                    {key: 'solicitud', label: 'Solicitud', sortable: true, class:'d-none'},
                    {key: 'pedidos_activos', label: 'Pedidos', sortable: true},
                    {key: 'estatus', label: 'Estatus', sortable: true},
                    {key: 'fecha_hora_creacion', label: 'Creado', sortable: true},
                    {key: 'fecha_hora_reagendado', label: 'Reagendado', sortable: true},
                    {key: 'edit', label: 'Acción', sortable: true},
                    {key: 'lat', label: 'LAT', sortable: true, class:'d-none'},
                    {key: 'lng', label: 'LNG', sortable: true, class:'d-none'},
                    // {key: 'DIFERENCIA', label: 'DIF', class:'negritas'},
                    ],
            fields_pedidos:[
                {key: 'pedido_id',label:'pedido_id',class:"d-none"},
                {key: 'solicitud_id',label:'solicitud_id',class:"d-none"},
                {key: 'folio',label:'Folio', sortable: true,class:"negritas"},
                {key: 'estatus',label:'Estatus', sortable: true,class:"negritas"},
                {key: 'operador_id_creo',label:'Operador', sortable: true, class:"d-none"},
                {key: 'fecha_hora_creacion',label:'Fecha', sortable: true, class:"d-none"},
                {key: 'del',label:'Acción', sortable: true},
            ],
            solicitudes:[],
            pendientes:[],
            programados:[],
            cargadas:[],
            despachados:[],
            reprogramados:[],
            cancelados:[],
            pedidos:[],
            solSelected:{},
            filter:'',
            filter_pendientes:'',
            filter_programados:'',
            filter_cargadas:'',
            filter_despachados:'',
            filter_cancelados:'',
            filter_reprogramados:'',
            folio:null,
            cargando:false,
            ffin:null,
            fini:null,
            usarFechasEnFicheros:false,
            availableProperties: [],
            selectedProperties: [],
            filteredObject: null,
            arregloExportar:[],
        }
    },
    components: {
        Modals,
        Observaciones,
        // Operadores,
    },
    watch: {
        // Monitorea los cambios en 'selectedProperties' y muestra las propiedades seleccionadas dinámicamente
        selectedProperties(newSelected) {
            // console.log('Propiedades seleccionadas:', newSelected);
        }
    },
    computed: {
        ...mapState(['operador','modal','menu']),
        rows() {
            return this.solicitudes.length
        },
        ocultarnuevo(){
            // if(this.tabselected==0 ){
            //     return true
            // }else{
                return false
            // }
        },
       
    },
    created () {
        let f=Date();
         this.ffin= utils.getDateTime(f)
         this.fini=this.ffin
        // console.warn("la ruta: " + this.$route.name)
        this.getSolicitudes()
    },
    methods: {
        ...mapActions(['actupdateMenu','actupdateModal']),
        showmodal(tittle,message){
            this.modal.tittle = tittle
            this.modal.message = message
            this.modal.type = 0
            this.actupdateModal(this.modal)
            // console.log("ahora contiene:  " + JSON.stringify(this.modal))
            this.$refs.mod.showmodal()
        },
        getSolicitudes() {
//             console.log("el filtro de todos.:" + this.usarFechasEnFicheros)
// return 
            this.cargando=true
            let val={
                fini:this.fini,
                ffin:this.ffin,
                usarentodo: this.usarFechasEnFicheros
            }
            this.$store.dispatch("getSolicitudes",val).then((resp)=>{

                // console.warn("La respuesta de Solicitudes: " + JSON.stringify(resp))
                if(resp.estatus == 1){
                    // this.operadores=resp.data
                    this.solicitudes=resp.solicitudes
                    this.pendientes=resp.pendientes
                    this.programados=resp.programados
                    // console.warn("La respuesta de Solicitudes: " + JSON.stringify(resp.programados))
                    this.cargadas=resp.cargando
                    this.reprogramados=resp.reprogramados
                    if(this.usarFechasEnFicheros){
                        this.despachados=resp.despachados
                        this.cancelados=resp.cancelados
                    }else{
                        this.despachados=this.solicitudes.filter(x=>x.estatus=="DESPACHADO")
                        this.cancelados=this.solicitudes.filter(x=>x.estatus=="CANCELADO")
                    }
                    this.cargando=false
                }else{
                    this.showmodal(this.texto, resp.message)
                    this.cargando=false
                }
            }).catch((err)=>{
                this.showmodal(this.texto, err)
                this.cargando=false
            })
        },
        nuevaSolicitud(){
            // this.$router.push({ name: 'solicitudes' });
           //console.warn("la ruta: " + this.$route.name)
           this.solSelected={}
            if (this.$route.name !== 'solicitudes') {
                this.$router.push({ name: 'solicitudes' });
            }
        },

        editarSolicitud(item){
           //console.log("edindo perador")
           this.solSelected=item
           this.$router.push({ name: 'solicitudes', params: item});
        },
        onRowDblClick(item){
            // this.editarSolicitud(item)
            this.solSelected=item
            this.$router.push({ name: 'solicitudes', params: item});
        },
        formatearFecha(fecha,formato=0) {
            //console.log("formateando fecha: " + fecha + "formato: " + formato)
            let fechaformateada=utils.getDateTime(fecha,formato)
           // console.log("formateando fecha: " + fechaformateada)
            return fechaformateada
        },
        formatearFechaHora(fecha,formato){
            let fechaActual = new Date();
            let fechaActualString = fechaActual.toISOString().split('T')[0]; // Obtiene la fecha en formato 'YYYY-MM-DD'
            //console.log("la fecha actual es: " + fechaActualString)
            // Concatenar la fecha actual con la hora de solicitud
            let fechaHoraConcatenada = `${fechaActualString}T${fecha}`;
            //console.log("la fecha y hora concatenada es: " + fechaHoraConcatenada)
            // Crear un nuevo objeto Date con la fecha y hora concatenadas
            let fechaConHora = new Date(fechaHoraConcatenada);
            let fechaformateada=utils.getDateTime(fechaConHora,formato)
            return fechaformateada
        },
        calculaMetros(tipo,datos){
            if(tipo==="todos"){
                let totalMetros = datos.reduce((suma, dato) => {
                    return suma + (dato.metros || 0); // Suma los metros de cada solicitud, o 0 si no tiene metros
                }, 0);
                return totalMetros
            }else if(tipo==="programados"){
                // console.warn("Si entro a programados....")
                let datosprogramados=datos.filter(x=>x.estatus=="PROGRAMADO")
                let totalMetros = datosprogramados.reduce((suma, datoprog) => {
                   //console.log("la suma es: " + suma + " y el dato es: " + datoprog.metros_totales)
                    return suma + (datoprog.metros_totales || 0); // Suma los metros de cada solicitud, o 0 si no tiene metros  
                }, 0);
                return totalMetros
            }else if(tipo==="despachados"){
                // console.warn("Si entro a programados....")
                let datosprogramados=datos.filter(x=>x.estatus=="DESPACHADO")
                let totalMetros = datosprogramados.reduce((suma, datoprog) => {
                   //console.log("la suma es: " + suma + " y el dato es: " + datoprog.metros_totales)
                    return suma + (datoprog.metros_totales || 0); // Suma los metros de cada solicitud, o 0 si no tiene metros  
                }, 0);
                return totalMetros
            }else if(tipo=="cancelados"){
                let datoscancelados=datos.filter(x=>x.estatus=="CANCELADO")
                let totalMetros = datoscancelados.reduce((suma, datocancel) => {
                    return suma + (datocancel.metros || 0); // Suma los metros de cada solicitud, o 0 si no tiene metros
                }, 0);
                return totalMetros
            }else{
                return 0
            }
        },
        openModalObservaciones(item){
            console.log("El item seleccionado: " + JSON.stringify(item))
            this.solSelected=item
            this.abrirModales("modal-observaciones")
        },
        agregarPedido(item){
            console.log("El item seleccionado: " + JSON.stringify(item))
            this.pedidos=item.pedidos_activos
            this.solSelected=item
            this.abrirModales("modal-pedidos")
            // this.$refs.inputFolio.focus();
        },
        setFocusOnInput(){
            this.$refs.inputFolio.focus()
        },
        guardarPedido(fol){
            this.cargando=true
            if(fol!=""){
                //lanzamos el dispatch para guardar un pedido...
                console.log("guardando el folio " + fol)
                let pedido={pedido_id:null,
                            solicitud_id:this.solSelected.solicitud_id,
                            folio:fol,
                            estatus:'ACTIVO',
                            operador_id_creo:this.operador.operador_id,
                            fecha_hora_creacion: utils.getDateTime(new Date(),0),
                }
                this.$store.dispatch("createPedido",pedido).then((resp)=>{
                    console.log("el response de createPedido es: " + JSON.stringify(resp))
                    if(resp.estatus==1){
                        this.pedidos.push(resp.pedido)
                        this.folio=""
                        this.cargando=false
                        this.setFocusOnInput()
                    }else{
                        this.cargando=false
                        this.showmodal(this.texto,resp.message)
                    }
                }).catch((err)=>{
                    this.showmodal(this.texto,err)
                    this.cargando=false
                })
            }
        },
        // eliminarPedido(item){
        //     console.log("El item seleccionado para eliminar: " + JSON.stringify(item))
        //     //lanzamos el dispatch para eliminar un pedido...
        //     if(item){
        //         this.$store.dispatch("deletePedido",item.pedido_id).then((resp)=>{
        //             console.log("el response de deletePedido es: " + JSON.stringify(resp))
        //             if(resp.estatus==1){
        //                 // this.pedidos=this.pedidos.filter(x=>x.pedido_id!=item.pedido_id)
        //                 const index = this.pedidos.findIndex(pedido => pedido.pedido_id === item.pedido_id);
        //                 if (index !== -1) {
        //                     this.pedidos.splice(index, 1);
        //                 }
        //             }else{
        //                 this.showmodal(this.texto,resp.message)
        //             }
        //         }).catch((err)=>{
        //             this.showmodal(this.texto,err)
        //         })
        //     }

        // },
       
        ActualizaPedido(item){
            item.estatus="CANCELADO"
            let foliop=item.folio
            item.folio="C"+foliop
            console.log("El item seleccionado para actualizar: " + JSON.stringify(item))
            this.$store.dispatch("updatePedido",item).then((resp)=>{
                console.log("el response de ActualizaPedido es: " + JSON.stringify(resp))
                if(resp.estatus==1){
                    // this.pedidos=this.pedidos.filter(x=>x.pedido_id!=item.pedido_id)
                    const index = this.pedidos.findIndex(pedido => pedido.pedido_id === item.pedido_id);
                    if (index !== -1) {
                        this.pedidos.splice(index, 1);
                    }
                }else{
                    item.folio=foliop
                    this.showmodal(this.texto,resp.message)
                }
            }).catch((err)=>{
                item.folio=foliop
                this.showmodal(this.texto,err)
            })
        },
        cerrarModal(){
            //this.$refs['modal-pedidos'].close()
            //this.obtenerSolicitudes()
        },
        // formatearNumero(numero) {
        //     numero = parseFloat(numero);

        //     if (isNaN(numero)) {
        //         return 'Invalido';
        //     }
        //     let partes = numero.toFixed(2).toString().split('.');
        //     // Agregar comas para separar los miles
        //     partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        //     // Unir las partes entera y decimal con un punto decimal
        //     return partes.join('.');
        // },
        imprimir(){
            this.availableProperties=[]
            this.arregloExportar=[]
            //console.log("EL tabselected: " + this.tabselected)
             if(this.tabselected===0){ 
                this.availableProperties = Object.keys(this.solicitudes[0]);
                this.arregloExportar=this.solicitudes
             }else if(this.tabselected===1){
                this.availableProperties = Object.keys(this.pendientes[0]);
                this.arregloExportar=this.pendientes
             }else if(this.tabselected===2){
                this.availableProperties = Object.keys(this.programados[0]);
                this.arregloExportar=this.programados
            }else if(this.tabselected===3){
                this.availableProperties = Object.keys(this.cargadas[0]);
                this.arregloExportar=this.cargadas
            }else if(this.tabselected===4){
                this.availableProperties = Object.keys(this.reprogramados[0]);
                this.arregloExportar=this.reprogramados
            }else if(this.tabselected===5){
                this.availableProperties = Object.keys(this.despachados[0]);
                this.arregloExportar=this.despachados
               
            }else if(this.tabselected===6){
                this.availableProperties = Object.keys(this.cancelados[0]);
                this.arregloExportar=this.cancelados
                
            }
            if (this.availableProperties.length > 0) {
                this.abrirModales("modal-export")    
            }
        },
        // createFilteredObject() {
        //     // Filtrar el objeto 'pendientes' basado en las propiedades seleccionadas
        //     this.filteredObject = this.selectedProperties.reduce((acc, prop) => {
        //         if (this.arregloExportar[prop] !== undefined) {
        //         acc[prop] = this.arregloExportar[prop];
        //         }
        //         return acc;
        //     }, {});
        //     console.log("Las columnas seleccionadas son: " + JSON.stringify(this.filteredObject))
        // },

        async exportarExcel(){
            // this.createFilteredObject
            this.filteredObjects = this.arregloExportar.map(arreglo => {
                // Crear un nuevo objeto con solo las propiedades seleccionadas
                return this.selectedProperties.reduce((acc, prop) => {
                if (arreglo[prop] !== undefined) {
                    acc[prop] = arreglo[prop];
                }
                return acc;
                }, {});
            });
            console.log("Las columnas seleccionadas son: " + JSON.stringify(this.filteredObjects))
            if (this.filteredObjects.some(obj => obj.hasOwnProperty('pedidos_activos'))) {
                console.log('Hay al menos un objeto con la propiedad "pedidos_activos".');
                this.filteredObjects.forEach(obj => {
                    let concatPedido=""
                    if(obj.pedidos_activos.length>0){
                        obj.pedidos_activos.forEach(pedido => {
                            console.log('El pedido es: ' + pedido.folio);
                            concatPedido=concatPedido+pedido.folio+","
                        });
                        obj.pedidos_activos=concatPedido
                    }
                })
            } else {
                console.log('No hay objetos con la propiedad "pedidos".');
            }
            utils.exportar(this.filteredObjects,'Solicitudes del periodo ' + this.fini +' al ' + this.ffin)
        },
        abrirModales(id){
            console.log("El id del modal a mosrar :" + id )
            this.$refs[id].show()
        },
    },
}
</script>

<style scoped>
 @import "@/assets/css/app.css";

 

</style>