<template>
  
    <!-- Contenedor principal -->
    <b-container fluid>
      <!-- Una fila por cada remisión -->
      <b-row v-for="remision in remisiones" :key="remision.remision_id" class="mb-4">
        <b-col cols="12" >
          <!-- Encabezado con información de la remisión -->
           <b-row class="d-flex">
            <b-col sm="2" class="text-start">
              <div class="d-flex align-items-center mr-3">
                <strong class="fcDarkGray">Folio:</strong>
                <b-card-text class="fcgray ms-2">
                    {{ remision.folio }} 
                </b-card-text>
              </div>
            </b-col>
            <b-col sm="3" class="text-start">
              <div class="d-flex align-items-center mr-3">
                <strong class="fcDarkGray">Operador:</strong>
                <b-card-text class="fcgray ms-2">
                    {{ remision.operador }}
                </b-card-text>
              </div>
            </b-col>
            <b-col sm="7" class="text-start">
              <div class="d-flex align-items-center">
                <strong class="fcDarkGray">Cliente:</strong>
                <b-card-text class="fcgray ms-2">
                    {{ remision.cliente }}
                </b-card-text>
              </div>
            </b-col>
          </b-row>
          <!-- Contenedor de la barra de progreso -->
          <div class="progress-container">
            <div
              v-for="(section, index) in processRemision(remision).sections"
              :key="index"
              class="progress-section"
              :style="{
                width: `${section.percentage}%`,
                backgroundColor: sectionColors[index]
              }"
            >
              <!-- Contenido de cada sección -->
              <div :id="section.remision_id+'-'+section.name" class="section-content">
                <small>{{ formatTime(section.startTime) }}</small>
                <small class="negritas">{{ formatDuration(section.timeDiff) }}</small>
              </div>
              <b-tooltip :target="section.remision_id+'-'+section.name">Fecha: {{ formatearFecha(section.startTime,2) }} <br> {{ section.largename }}</b-tooltip>
            </div>
          </div>
          
          <!-- Tiempo total -->
          <b-card-text class="mt-1 small label-col-green">
            <strong> Tiempo total: </strong>  <strong class="label-col-blue">{{ formatDuration(processRemision(remision).totalTime) }}</strong>
          </b-card-text>
        </b-col>
      </b-row>
    </b-container>
  
</template>

<script>
import * as utils from "@/shared/util"

export default {
  name: 'TimelineProgress',
  // props: {
  //   remisiones: {
  //     type: Array,
  //     required: true
  //   }
  // },
  data() {
    return {
      sectionColors: [
        'rgba(0, 123, 255, 0.5)',  // azul
        'rgba(40, 167, 69, 0.5)',  // verde
        'rgba(255, 193, 7, 0.5)',  // amarillo
        'rgba(253, 126, 20, 0.5)', // naranja
        'rgba(111, 66, 193, 0.5)', // morado
        'rgba(232, 62, 140, 0.5)', // rosa
        'rgba(220, 53, 69, 0.5)'   // rojo
      ]
    }
  },
  computed: {
    remisiones() {
        return this.$store.getters.getMarcaciones; 
    },
  },
  methods: {
    // Convierte string de fecha y hora a objeto Date
    parseDateTime(dateTimeStr) {
      if (!dateTimeStr) return null
      const [date, time] = dateTimeStr.split(' ')
      const [year, month, day] = date.split('-')
      const [hours, minutes, seconds] = time.split(':')
      return new Date(year, month - 1, day, hours, minutes, seconds)
    },

    // Calcula diferencia en minutos entre dos fechas
    getTimeDiff(start, end) {
      if (!start || !end) return 0
      const startDate = this.parseDateTime(start)
      const endDate = this.parseDateTime(end)
      return Math.round((endDate - startDate) / (1000 * 60)) // diferencia en minutos
    },

    // Formatea una fecha para mostrar solo hora y minutos
    formatTime(dateTimeStr) {
      if (!dateTimeStr) return ''
      const date = this.parseDateTime(dateTimeStr)
      return `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`
    },

    // Formatea duración en minutos a formato HH:mm
    formatDuration(minutes) {
      const hours = Math.floor(minutes / 60)
      const mins = minutes % 60
      return `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}`
    },

    // Procesa una remisión para obtener las secciones de la barra de progreso
    processRemision(remision) {
      // Define los puntos de tiempo en orden
      const timePoints = [
        { name: 'asig', time: remision.fecha_hora_asig, largename: 'Asignado' },
        { name: 'sp', time: remision.fecha_hora_sp, largename: 'Salida de Planta' },
        { name: 'lo', time: remision.fecha_hora_lo, largename: 'Llegada a Obra' },
        { name: 'id', time: remision.fecha_hora_id, largename: 'Inicio de Descarga' },
        { name: 'td', time: remision.fecha_hora_td, largename: 'Termino de Descarga' },
        { name: 'so', time: remision.fecha_hora_so, largename: 'Salida de Obra' },
        { name: 'lp', time: remision.fecha_hora_lp, largename: 'Llegada a Planta' }
      ].filter(point => point.time !== null) // Filtra puntos nulos

      // Calcula tiempo total
      const totalTime = this.getTimeDiff(
        timePoints[0].time,
        timePoints[timePoints.length - 1].time
      ) || 1

      // Genera las secciones
      const sections = []
      for (let i = 0; i < timePoints.length - 1; i++) {
        const timeDiff = this.getTimeDiff(timePoints[i].time, timePoints[i + 1].time)
        const percentage = (timeDiff / totalTime) * 100
        sections.push({
          percentage,
          startTime: timePoints[i].time,
          endTime: timePoints[i + 1].time,
          timeDiff,
          name: timePoints[i].name + '-' + timePoints[i + 1].name ,
          largename: timePoints[i].largename + ' - ' + timePoints[i + 1].largename,
          remision_id: remision.remision_id
        })
      }

      return { sections, totalTime }
    },
    formatearFecha(fecha, formato=0) {
      //console.log("formateando fecha: " + fecha + "formato: " + formato)
      let fechaformateada=utils.getDateTime(fecha,formato)
      // console.log("formateando fecha: " + fechaformateada)
      return fechaformateada
    },
  }
}
</script>

<style scoped>
.progress-container {
  height: 60px;
  display: flex;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #dee2e6;
}

.progress-section {
  position: relative;
  border-right: 1px solid #fff;
  min-width: 60px; /* Ancho mínimo para asegurar legibilidad */
}

.progress-section:last-child {
  border-right: none;
}

.section-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2px;
  line-height: 1.2;
  color: #495057;
  text-align: center;
}

/* Mejora de legibilidad para textos */
.section-content small {
  background-color: rgba(255, 255, 255, 0.7);
  padding: 1px 4px;
  border-radius: 2px;
  white-space: nowrap;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .progress-container {
    height: 80px; /* Más alto en móviles para mejor legibilidad */
  }
  
  .section-content small {
    font-size: 10px; /* Texto más pequeño en móviles */
  }
}
</style>