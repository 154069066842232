<template>
    <b-container fluid class="px-4 justify-content-center  height-fixed margin2sidebar font-opensans">
      <b-row>
        <b-col cols="10" sm="11" md="11" lg="11" xl="11">
          <h2><b-icon  icon="bag-check" font-scale="1" ></b-icon> Nuevo Producto</h2>
        </b-col>
        <b-col cols="2" sm="1" md="1" lg="1" xl="1">
          <b-button variant="primary" class="shadow-sm back-bttn bold-text px-3 py-2 mb-3 mt-2" @click="goBack()"><b-icon icon="arrow-left"></b-icon></b-button>
        </b-col>
        <b-col>
          
          <b-form @submit.prevent="submitForm" class="shadow p-4 rounded form-background">
            <!-- Clave -->
            <b-form-group label="Clave:" label-for="clave" class="pt-2 pb-3">
              <b-form-input
                id="clave"
                v-model="producto.clave"
                :maxlength="20"
                required
                placeholder="Clave del producto"
                class="mt-1 shadow-sm"
              ></b-form-input>
            </b-form-group>
  
            <!-- Descripción -->
            <b-form-group label="Descripción:" label-for="descripcion" class="pb-3">
              <b-form-input
                id="descripcion"
                v-model="producto.descripcion"
                :maxlength="150"
                required
                placeholder="Descripción del producto"
                class="mt-1 shadow-sm"
              ></b-form-input>
            </b-form-group>
  
            
            <b-row>
              <!-- Tipo de Producto -->
              <b-col class="cols-6" xs="12" s="6" md="6" lg="4" xl="4">
                <b-form-group label="Tipo de Producto:" label-for="tipoProducto" class="pb-3">
                  <b-form-select
                    id="tipoProducto"
                    v-model="producto.tipo_producto_id"
                    :options="tipoProductoOptions"
                    required
                    class="shadow-sm border rounded custom-border px-2 py-1 mt-1"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <!-- Estatus -->
               <b-col class="cols-6" xs="12" s="6" md="6" lg="8" xl="8">
                <b-form-group label="Estatus:" label-for="estatus" class="pb-3">
                  <b-form-select
                    id="estatus"
                    v-model="producto.estatus"
                    :options="estatusOptions"
                    required
                    :disabled="nuevo"
                    class="shadow-sm border rounded custom-border px-2 py-1 mt-1"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-row>
                <b-col md="4">
                  <label for="">Creado: {{producto.fecha_hora_creacion}}</label>
                </b-col>
                <b-col md="4">
                  <label for="">Modificado:{{producto.fecha_hora_modif}}</label>
                </b-col>
                
                
              </b-row>
    
              
              
            </b-row>
            
            <!-- Botón de Regresar -->
            <b-button variant="primary" class="shadow-sm back-bttn bold-text px-3 py-1 mb-3 mt-2" @click="goBack()">Regresar</b-button>
            <!-- Botón de Guardar -->
            <b-button v-if="!cargando" variant="primary" type="submit" class="shadow-sm save-bttn bold-text px-4 py-1 mb-3 mt-2 mx-3">Guardar</b-button>
            <b-spinner v-else class="text-center" variant="warning" ></b-spinner>
          </b-form>
        </b-col>
      </b-row>
      <Modals ref="mod"></Modals>
    </b-container>
  </template>
  
  <script>
  import Modals from '@/components/Modals.vue'
  import {mapState,mapActions} from 'vuex'
  import * as utils from "@/shared/util"
  export default {
    data() {
      return {
        nuevo:true,
        cargando: false,
        texto: "Producto",
        producto: {
          clave: '',
          descripcion: '',
          tipo_producto_id: null,
          estatus: 'A',
        },
        tipoProductoOptions: [
          { value: null, text: 'Seleccione el tipo de producto' },
          { value: 1, text: 'Tipo 1' },
          { value: 2, text: 'Tipo 2' },
          { value: 3, text: 'Tipo 3' },
        ],
        estatusOptions: [
          { value: 'A', text: 'ACTIVO' },
          { value: 'B', text: 'BAJA' },
        ],
      };
    },
    components: {
        Modals,
        // Operadores,
    },
    computed: {
      ...mapState(['operador','modal','menu']),
      // name() {
      //   return this.data 
      // }
    },
    created() {
      
      let f=Date();
      this.producto.fecha_hora_creacion= utils.getDateTime(f)
      this.producto.fecha_modif=utils.getDateTime(f)
     
      //cargamos los catalogos que involucran a los productos productos
      this.$store.dispatch("getCatalogosProductos").then((resp)=>{
       //console.info("el catalogo de productos:"+ JSON.stringify(resp) )
        if (resp.estatus==1){
            this.tipoProductoOptions=resp.tipos_productos
        }
        
      }).then((error)=>{
        console.error(error)
      })

      if(this.$route.params.producto_id != undefined){
        this.producto=this.$route.params
        this.nuevo=false
      }
     //console.info("Los params enviados son:"+ JSON.stringify(this.$route.params) )
      // this.form.estatus=1
     //console.log("Es nuevo :" + this.nuevo)
    },
    methods: {
      ...mapActions(['actupdateMenu','actupdateModal']),
      showmodal(tittle,message){
          this.modal.tittle = tittle
          this.modal.message = message
          this.modal.type = 0
          this.actupdateModal(this.modal)
         //console.log("ahora contiene:  " + JSON.stringify(this.modal))
          this.$refs.mod.showmodal()
      },
      submitForm() {
        this.cargando=true
        // Aquí puedes manejar la lógica para enviar el formulario
       //console.log("el producto:" + JSON.stringify(this.producto));
        if(this.nuevo){
          this.$store.dispatch("createProducto",this.producto).then((res)=>{
           //console.log("dispatch createProducto trajo: "+JSON.stringify(res))
            if(res.estatus==1){
              this.cargando=false
              this.goBack()
            }else{
              this.cargando=false
              this.showmodal(this.texto, res.message)
            }
          }).catch((error)=>{
            this.cargando=false
            this.showmodal(this.texto, error)
          })
        }else{
          this.$store.dispatch("updateProducto",this.producto).then((res)=>{
           //console.log("dispatch updateProducto trajo: "+JSON.stringify(res))
            if(res.estatus==1){
              this.cargando=false
              this.goBack()
            }else{
              this.cargando=false
              this.showmodal(this.texto, res.message)
            }
          }).catch((error)=>{
            this.cargando=false
            this.showmodal(this.texto, error)
          })
        }
      },
      goBack(){
       
        this.$router.go(-1)
      },
    },
  };
  </script>
  
  <style scoped>
  /* Estilos responsivos adicionales si es necesario */
  h2 {
    margin-top: 20px;
  }
  </style>
  