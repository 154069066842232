// api.js

const state = {
    // State específico del módulo API
  };
  
  const mutations = {
    // Mutations específicas del módulo API
  };
  
  const actions = {
    // Actions específicas del módulo API
    getRemisiones({commit,state},val) {
      return new Promise((resolve, reject) => {
      fetch(this.state.app.config.apiURL+'vista_remisiones',
          {
            method: "POST",
            headers: {
                'Authorization': 'Basic ' + btoa(this.state.operador.operador + ':' + this.state.operador.clave), // Aquí incluye las credenciales codificadas en base64
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                fecha_ini      : val.fini,
                fecha_fin      : val.ffin,
                obligarAsignados : val.incluirRemisionesAsignadas,
            })
          })
          .then((response) => response.json())
          .then((r) => {
              resolve(r);
          })
          .catch((error) => {
              reject(error);
          });
      },
      error=>{
          reject(error);
      })
    },
   
    createRemision({commit,state},val) {
      return new Promise((resolve, reject) => {
      fetch(this.state.app.config.apiURL+'remisiones',
          {
              method: "POST",
                headers: {
                'Authorization': 'Basic ' + btoa(this.state.operador.operador + ':' + this.state.operador.clave), // Aquí incluye las credenciales codificadas en base64
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                remision     : val,
              })
          })
          .then((response) => response.json())
          .then((r) => {
              resolve(r);
          })
          .catch((error) => {
              reject(error);
          });
      },
      error=>{
          reject(error);
      })
    },
    updateRemision({commit,state},val) {
      return new Promise((resolve, reject) => {
      fetch(this.state.app.config.apiURL+'remisiones/'+ val.remision_id,
          {
              method: "PUT",
                headers: {
                'Authorization': 'Basic ' + btoa(this.state.operador.operador + ':' + this.state.operador.clave), // Aquí incluye las credenciales codificadas en base64
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                remision     : val,
              })
          })
          .then((response) => response.json())
          .then((r) => {
              resolve(r);
          })
          .catch((error) => {
              reject(error);
          });
      },
      error=>{
          reject(error);
      })
    },

    deleteRemision({commit,state},pedido_id) {
        return new Promise((resolve, reject) => {
        fetch(this.state.app.config.apiURL+'pedidos/'+ pedido_id,
            {
                method: "DELETE",
                  headers: {
                  'Authorization': 'Basic ' + btoa(this.state.operador.operador + ':' + this.state.operador.clave), // Aquí incluye las credenciales codificadas en base64
                  'Content-Type': 'application/json'
                },
                // body: JSON.stringify({
                //   suministro     : val,
                // })
            })
            .then((response) => response.json())
            .then((r) => {
                resolve(r);
            })
            .catch((error) => {
                reject(error);
            });
        },
        error=>{
            reject(error);
        })
      },
      deleteRemisionesSinProcesar({commit,state}) {
        return new Promise((resolve, reject) => {
        fetch(this.state.app.config.apiURL+'remisiones/',
            {
                method: "DELETE",
                  headers: {
                  'Authorization': 'Basic ' + btoa(this.state.operador.operador + ':' + this.state.operador.clave), // Aquí incluye las credenciales codificadas en base64
                  'Content-Type': 'application/json'
                },
                // body: JSON.stringify({
                //   suministro     : val,
                // })
            })
            .then((response) => response.json())
            .then((r) => {
                resolve(r);
            })
            .catch((error) => {
                reject(error);
            });
        },
        error=>{
            reject(error);
        })
      },
      set_Evento({commit,state},val){
        return new Promise((resolve, reject) => {
        fetch(this.state.app.config.apiURL+'evento',
            {
                method: "POST",
                headers: {
                    'Authorization': 'Basic ' + btoa(this.state.operador.operador + ':' + this.state.operador.clave), // Aquí incluye las credenciales codificadas en base64
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    marcacion : val
                })
            })
        .then((response) => response.json())
            .then((r) => {
                // console.log("ST - 1.8 lo que hay en el r: "+ r);
                console.log("ST - 1.1 lo que hay en el r: " + JSON.stringify(r));
                resolve(r);
            })
            .catch((error) => {
                // alert("1" + error);
                reject(error);
            });
        },
        error=>{
            alert("2" + error);
            reject(error);
        })
      },
    
    
  };
  
  const getters = {
    // Getters específicas del módulo API
  };
  
  export default {
    state,
    mutations,
    actions,
    getters
  };