<template>
    <b-container  class="px-3 justify-content-center  height-fixed margin2sidebar font-opensans">
      <b-row>
        <b-col cols="10" sm="11" md="11" lg="11" xl="11">
          <h2><b-icon icon="box-seam" ></b-icon> Gestión de Suministros</h2>
        </b-col>
        <b-col cols="2" sm="1" md="1" lg="1" xl="1">
          <b-button variant="primary" class="shadow-sm back-bttn bold-text px-3 py-2 mb-3 mt-2" @click="goBack()"><b-icon icon="arrow-left"></b-icon></b-button>
        </b-col>
      </b-row>
  
      <b-form @submit.prevent="guardarSuministro" class="shadow p-4 rounded form-background">
        <!-- Clave del suministro -->
        <b-row>
          <b-col cols="12" md="6">
            <b-form-group label="Clave:" label-for="clave" class="pt-2 pb-3">
              <b-form-input
                id="clave"
                v-model="suministro.clave"
                maxlength="20"
                required
                placeholder="Ingrese la clave del suministro"
                class="mt-1 shadow-sm"
                :style="{'backgroundColor':suministro.color}"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-button @click="abrirModales('modal-colores')" variant="secondary" class="shadow-sm back-bttn bold-text px-3 py-1 mb-2 mt-2">Elegir Color</b-button>
            <!-- <b-row>
              <b-col cols="12" md="4">
                
              </b-col>
              <b-col cols="12" md="4" class="text-start">
                <div class="text-center mt-4" style="width: 80px; height: 30px; border-radius: 5px" :style="{'backgroundColor':suministro.color}">{{suministro.color}}</div>
              </b-col>
            </b-row> -->
          </b-col>
          
          
        </b-row>
        <b-row>
          <!-- Descripción del suministro -->
          <b-col cols="12" md="6">
            <b-form-group label="Descripción:" label-for="descripcion" class="pt-2 pb-3">
              <b-form-input
                id="descripcion"
                v-model="suministro.descripcion"
                maxlength="150"
                required
                placeholder="Descripción del suministro"
                class="mt-1 shadow-sm"
              />
            </b-form-group>
          </b-col>
         
         
        </b-row>
  
        <!-- Estatus del suministro -->
        <b-row>
          <!-- Fecha de creación -->
          <b-col cols="12" md="6">
            <label for="">{{ suministro.fecha_hora_creacion }}</label>
            <!-- <b-form-group label="Fecha de Creación:" label-for="fecha_creacion"  class="pt-2 pb-3">
              <b-form-datepicker
                id="fecha_creacion"
                v-model="suministro.fecha_hora_creacion"
                :disabled="true"
                :state="true"
                class="mt-1 shadow-sm"
              /> 
            </b-form-group> -->
          </b-col>
        </b-row>
  
        <!-- Fecha de modificación -->
        <b-row>
          <b-col cols="12" md="6">
            <label for="">{{ suministro.fecha_hora_modif }}</label>
            <!-- <b-form-group label="Fecha de Modificación:" label-for="fecha_modif" class="pb-3">
              <b-form-datepicker
                id="fecha_modif"
                v-model="suministro.fecha_hora_modif"
                :disabled="true"
                :state="true"
                class="mt-1 shadow-sm"
              />
            </b-form-group> -->
          </b-col>
        </b-row>
  
        <!-- Botones de acción -->
        <b-row>
          <b-col>
            <b-button @click="goBack" variant="secondary" class="shadow-sm back-bttn bold-text px-3 py-1 mb-3 mt-2">Regresar</b-button>
            <b-button type="submit" variant="primary" class="shadow-sm save-bttn bold-text px-4 py-1 mb-3 mt-2 mx-3">Guardar</b-button>
            
          </b-col>
        </b-row>
      </b-form>
      <b-modal ref="modal-colores" hide-header-close scrollable button-size="sm"  title="Elige un color para el suministro" @ok="actualiza_color()">
          <div  class="card shadow p-3 fs-12 text-center"> 
              <sketch-picker v-model="selectedColor"></sketch-picker>
              <div>Color seleccionado: {{ selectedColor.hex }}</div>
              <!-- <b-button @click="saveColor">Guardar Color</b-button> -->
          </div>
          <template #modal-footer="{ ok, cancel }">
              <!-- Emulate built in modal footer ok and cancel button actions -->
              <b-button size="sm" variant="success" @click="ok()">
                  Guardar
              </b-button>
              <b-button size="sm" variant="secondary" @click="cancel()">
                  Cancel
              </b-button>
          </template>
        </b-modal>
      <Modals ref="mod"></Modals>
    </b-container>
  </template>
  
  <script>
  import Modals from '@/components/Modals.vue'
  import {mapState,mapActions} from 'vuex'
  import * as utils from "@/shared/util"
  import { Sketch } from 'vue-color'; 
  export default {
    data() {
      return {
        nuevo: true,
        cargando: false,
        texto: "Suministros",
        suministro: {
          clave: '',
          descripcion: '',
          estatus: 'A',
          fecha_hora_creacion: new Date().toISOString().substring(0, 10),
          fecha_hora_modif: new Date().toISOString().substring(0, 10)
        },
        selectedColor: {
          hex: '#000000'
        },
        opcionesEstatus: [
          { value: 'A', text: 'Activo' },
          { value: 'B', text: 'Baja' }
        ],
      };
    },
    components: {
        Modals,
        'sketch-picker': Sketch
        // Operadores,
    },
    computed: {
      ...mapState(['operador','modal','menu']),
      // name() {
      //   return this.data 
      // }
    },
    created() {
      
      let f=Date();
      this.suministro.fecha_hora_creacion= utils.getDateTime(f)
      this.suministro.fecha_hora_modif=utils.getDateTime(f)
     
      if(this.$route.params.suministro_id != undefined){
        this.suministro=this.$route.params
        this.nuevo=false
      }
      console.info("Los params enviados son:"+ JSON.stringify(this.$route.params) )
      // this.form.estatus=1
      console.log("Es nuevo :" + this.nuevo)
    },
    methods: {
      ...mapActions(['actupdateMenu','actupdateModal']),
      showmodal(tittle,message){
          this.modal.tittle = tittle
          this.modal.message = message
          this.modal.type = 0
          this.actupdateModal(this.modal)
          console.log("ahora contiene:  " + JSON.stringify(this.modal))
          this.$refs.mod.showmodal()
      },
      guardarSuministro() {
        // Lógica para guardar el suministro
        console.log('Suministro guardado:', this.suministro);
        this.cargando=true
        // Aquí puedes manejar la lógica para enviar el formulario
        if(this.nuevo){
          this.$store.dispatch("createSuministro",this.suministro).then((res)=>{
            console.log("dispatch createSuministro trajo: "+JSON.stringify(res))
            if(res.estatus==1){
              this.cargando=false
              this.goBack()
            }else{
              this.cargando=false
              this.showmodal(this.texto, res.message)
            }
          }).catch((error)=>{
            this.cargando=false
            this.showmodal(this.texto, error)
          })
        }else{
          this.$store.dispatch("updateSuministro",this.suministro).then((res)=>{
            console.log("dispatch updateSuministro trajo: "+JSON.stringify(res))
            if(res.estatus==1){
              this.cargando=false
              this.goBack()
            }else{
              this.cargando=false
              this.showmodal(this.texto, res.message)
            }
          }).catch((error)=>{
            this.cargando=false
            this.showmodal(this.texto, error)
          })
        }
      },
      abrirModales(id){
          console.log("El id del modal a mosrar :" + id )
          this.$refs[id].show()
      },
      actualiza_color(){
        if(this.selectedColor.hex=='#000000'){
          this.suministro.color=null
        }else{
          this.suministro.color=this.selectedColor.hex
        }
        console.log("El color seleccionado es: " + this.selectedColor.hex)
      },
      goBack(){
        this.$router.go(-1)
      },
    }
  };
  </script>
  